import {
  Box,
  InputProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import * as postcodesIo from 'node-postcodes.io';
import { useState } from 'react';
import type { UseFormMethods } from 'react-hook-form';

import Input from '../inputs/Input';

const getAutocompletedPostcodes = async (text: string): Promise<string[]> => {
  try {
    const codes = await postcodesIo.autocomplete(text);
    return codes?.result ?? [];
  } catch (e) {
    return [];
  }
};

interface IPostcodeField
  extends InputProps,
    Partial<Pick<UseFormMethods, 'errors' | 'register' | 'setValue'>> {
  name: string;
  color: string;
  placeholder: string;
  id: string;
}

const PostcodeField = ({
  name,
  placeholder,
  color,
  id,
  errors,
  register,
  setValue,
  ...props
}: IPostcodeField) => {
  const [postcodes, setPostcodes] = useState<string[]>([]);

  const handleInputChange = async e => {
    setPostcodes(await getAutocompletedPostcodes(e.target.value));
  };

  const handleSetValue = (value: string) => {
    setPostcodes([]);
    setValue('postcode', value);
  };

  return (
    <FormControl isInvalid={Boolean(errors[name])} mb="3" width="full">
      <FormLabel color={color} fontSize="sm" htmlFor={id} mb="0">
        {placeholder}
      </FormLabel>
      <Input
        onBlur={event => handleSetValue(event.target.value)}
        onChange={handleInputChange}
        {...props}
        color={color}
        id={id}
        marginBottom="0"
        name={name}
        placeholder={placeholder}
        register={register}
        size="md"
        variant="flushed"
      />
      <Box>
        {postcodes.map(postCode => (
          <Box
            key={postCode}
            onMouseDown={event => {
              event.preventDefault();
              handleSetValue(postCode);
            }}
            sx={{
              px: 3,
              py: 1,
              cursor: 'pointer',
              ':hover': {
                bg: '#f2f2f2',
              },
            }}
          >
            {postCode}
          </Box>
        ))}
      </Box>
      <FormErrorMessage minH="1.5rem">{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default PostcodeField;
