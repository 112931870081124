import { IconButton } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';

enum FormatOptions {
  PARAGRAPH = 'paragraph',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'strikethrough',
  LIST_ITEM = 'listItem',
  UNORDERED_LIST = 'unorderedList',
  ORDERED_LIST = 'orderedList',
  QUOTE = 'quote',
  LINK = 'link',
}

const listTypes = [FormatOptions.ORDERED_LIST, FormatOptions.UNORDERED_LIST];

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor, format) => {
  if (isMarkActive(editor, format)) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    }),
  );
  return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = listTypes.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      listTypes.includes(n.type),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive
      ? FormatOptions.PARAGRAPH
      : isList
      ? FormatOptions.LIST_ITEM
      : format,
  };
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isBlockButton = (format: FormatOptions) =>
  [
    FormatOptions.UNORDERED_LIST,
    FormatOptions.ORDERED_LIST,
    FormatOptions.QUOTE,
  ].includes(format);

export interface ToolbarButtonType {
  format: FormatOptions;
  icon: JSX.Element;
}

const ToolbarButton: React.FC<ToolbarButtonType> = ({ format, icon }) => {
  const editor = useSlate();
  const theme = useTheme();

  const isActive = isBlockButton(format) ? isBlockActive : isMarkActive;
  const toggle = isBlockButton(format) ? toggleBlock : toggleMark;

  return (
    <IconButton
      aria-label={`toolbar_button_${format}`}
      bg={isActive(editor, format) && theme.colors.gray[200]}
      icon={icon}
      onMouseDown={event => {
        event.preventDefault();
        toggle(editor, format);
      }}
      size="xs"
      variant="unstyled"
    />
  );
};

export default ToolbarButton;
export { FormatOptions };
