import Button from '../Button';

const TextButton = ({ onClick, children, ...rest }) => (
  <Button
    color="yellow.400"
    onClick={onClick}
    variant="link"
    {...rest}
    _focus={{
      outline: 'none',
    }}
    px="8px"
  >
    {children}
  </Button>
);

export default TextButton;
