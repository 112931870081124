import { Descendant, Text } from 'slate';
import { RenderElementProps, RenderLeafProps } from 'slate-react';

import { FormatOptions } from './ToolbarButton';

import colors from '@/theme/foundations/colors';

const Leaf = ({
  attributes,
  children,
  leaf,
  color,
}: RenderLeafProps & { color: string }) => {
  let modifiedChildren = { ...children };

  if (leaf.bold) {
    modifiedChildren = <strong>{modifiedChildren}</strong>;
  }
  if (leaf.strikethrough) {
    modifiedChildren = <s>{modifiedChildren}</s>;
  }
  if (leaf.italic) {
    modifiedChildren = <em>{modifiedChildren}</em>;
  }
  if (leaf.underline) {
    modifiedChildren = <u>{modifiedChildren}</u>;
  }

  return (
    <span {...attributes} style={{ color }}>
      {modifiedChildren}
    </span>
  );
};

const Element = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case FormatOptions.QUOTE:
      return <blockquote {...attributes}>{children}</blockquote>;
    case FormatOptions.UNORDERED_LIST:
      return <ul {...attributes}>{children}</ul>;
    case FormatOptions.ORDERED_LIST:
      return <ol {...attributes}>{children}</ol>;
    case FormatOptions.LIST_ITEM:
      return (
        <li style={{ marginLeft: '16px' }} {...attributes}>
          {children}
        </li>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const serializeNodeToHTML = (node: Descendant): string => {
  if (Text.isText(node)) {
    let nodeText = node.text;
    if (node.bold) {
      nodeText = `<strong>${nodeText}</strong>`;
    }
    if (node.strikethrough) {
      nodeText = `<s>${nodeText}</s>`;
    }
    if (node.italic) {
      nodeText = `<em>${nodeText}</em>`;
    }
    if (node.underline) {
      nodeText = `<u>${nodeText}</u>`;
    }
    return nodeText;
  }

  const nodeChildren = node.children
    .map(child => serializeNodeToHTML(child))
    .join('');

  switch (node.type) {
    case FormatOptions.QUOTE:
      return `<blockquote style="background-color: ${colors.gray[100]}; border-radius: 5px; margin: 10px; padding: 10px">${nodeChildren}</blockquote>`;
    case FormatOptions.UNORDERED_LIST:
      return `<ul>${nodeChildren}</ul>`;
    case FormatOptions.ORDERED_LIST:
      return `<ol>${nodeChildren}</ol>`;
    case FormatOptions.LIST_ITEM:
      return `<li>${nodeChildren}</li>`;

    default:
      return nodeChildren;
  }
};

const serializeRichTextToHTML = (
  richText: Descendant[],
  omitBreakTag = false,
) => {
  return richText.reduce((accumulator, node, index, array) => {
    const newValue = accumulator + serializeNodeToHTML(node);
    const shouldSkipBreakTag =
      (index === array.length - 1 && newValue.endsWith('<br/>')) ||
      omitBreakTag;

    return newValue + (shouldSkipBreakTag ? '' : '<br/>');
  }, '');
};

const getInitialRichTextValue = (
  defaultValue: string,
  characterLimit?: number,
) => {
  const clippedValue = characterLimit
    ? defaultValue.substring(0, characterLimit)
    : defaultValue;

  return [
    {
      type: FormatOptions.PARAGRAPH,
      children: [
        {
          text: clippedValue,
        },
      ],
    },
  ];
};

export { Element, Leaf, serializeRichTextToHTML, getInitialRichTextValue };
