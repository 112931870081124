import {
  FormControl,
  FormErrorMessage,
  Checkbox as ChakraCheckbox,
  CheckboxProps,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';

interface ICheckboxField
  extends CheckboxProps,
    Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  name: string;
}

const CheckboxField: FC<ICheckboxField> = ({
  name,
  children,
  register,
  errors,
  onChange,
  ...props
}: ICheckboxField) => (
  <FormControl isInvalid={Boolean(errors[name])}>
    <ChakraCheckbox
      {...props}
      borderColor="yellow.400"
      colorScheme="yellow"
      label={{
        alignItems: 'flex-start',
      }}
      name={name}
      onChange={onChange}
      ref={register}
      size="lg"
    >
      {children}
    </ChakraCheckbox>
    <FormErrorMessage minH="1.5rem">{errors[name]?.message}</FormErrorMessage>
  </FormControl>
);

export default CheckboxField;
