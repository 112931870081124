import { Box, keyframes, Spinner } from '@chakra-ui/react';
import React from 'react';

const LoadingSpinner = ({ variant = 'dark' }) => {
  const color = variant === 'light' ? 'gray.50' : 'gray.900';
  return (
    <Box textAlign="center">
      <Spinner color={color} size="xl" />
    </Box>
  );
};

export default LoadingSpinner;

const styles: any = {
  loader: {
    position: 'relative',
    width: 100,
    height: 20,
  },
  duo: {
    height: 20,
    width: 50,
    background: 'hsla(0, 0%, 0%, 0.0)',
    position: 'absolute',
  },
  duo1: {
    left: 0,
  },
  duo2: {
    left: 30,
  },
  dot: {
    width: 20,
    height: 20,
    borderRadius: 10,
    background: '#FFC027',
    position: 'absolute',
  },
  dotA: {
    left: 0,
  },
  dotB: {
    right: 0,
  },
};

const spin = keyframes`
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(180deg) }
`;
const onOff = keyframes`
  0% { opacity: 0; }
  49% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 1; }
`;

export const LoadingDots = () => {
  const spinAnimation = `${spin} infinite 0.8s ease-in-out`;
  const spinAnimationR = `${spin} infinite 0.8s reverse`;
  const onOffAnimation = `${onOff} infinite 0.8s ease-in-out`;
  const onOffAnimationR = `${onOff} infinite 0.8s reverse`;
  return (
    <Box style={styles.loader}>
      <Box animation={spinAnimation} style={{ ...styles.duo, ...styles.duo1 }}>
        <Box
          animation={onOffAnimationR}
          style={{ ...styles.dot, ...styles.dotA }}
        />
        <Box style={{ ...styles.dot, ...styles.dotB }} />
      </Box>
      <Box animation={spinAnimationR} style={{ ...styles.duo, ...styles.duo2 }}>
        <Box style={{ ...styles.dot, ...styles.dotA }} />
        <Box
          animation={onOffAnimation}
          style={{ ...styles.dot, ...styles.dotB }}
        />
      </Box>
    </Box>
  );
};
