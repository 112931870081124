import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
  Upload: any;
};

export type AcceptInviteInput = {
  contactNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  inviteToken: Scalars['String'];
  password: Scalars['String'];
};

export type AcceptInviteResponse = {
  __typename?: 'AcceptInviteResponse';
  customToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type AccessRequestInput = {
  department: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  hashedOrganisationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type AddBenefitInput = {
  details: Scalars['String'];
  groupName: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  organisationId: Scalars['Float'];
  title: Scalars['String'];
};

export type Answer = {
  __typename?: 'Answer';
  assessment?: Maybe<Assessment>;
  assessmentId?: Maybe<Scalars['ID']>;
  id: Scalars['Float'];
  questionId: Scalars['String'];
  rawResponse: Scalars['String'];
  scaledResponse?: Maybe<Scalars['String']>;
  scalingFactor?: Maybe<Scalars['Float']>;
  transformedResponse?: Maybe<Scalars['String']>;
};

export type AnswerBenefitInput = {
  benefitStoryblokId: Scalars['String'];
  groupName: Scalars['String'];
  organisationId: Scalars['Float'];
  response: Scalars['String'];
};

export type AnswerQuestionInput = {
  questionId: Scalars['String'];
  rawResponse: Scalars['String'];
};

export type AnswerQuestionResponse = {
  __typename?: 'AnswerQuestionResponse';
  answer?: Maybe<Answer>;
  error?: Maybe<Scalars['String']>;
};

export type AnswerWithCount = {
  __typename?: 'AnswerWithCount';
  count: Scalars['Float'];
  rawResponse: Scalars['String'];
};

export type AppConfig = {
  __typename?: 'AppConfig';
  appConfig: AppConfigModel;
};

export type AppConfigModel = {
  __typename?: 'AppConfigModel';
  testOrganisationId?: Maybe<Scalars['ID']>;
};

export type Assessment = {
  __typename?: 'Assessment';
  answers: Array<Answer>;
  assessmentCycle: AssessmentCycle;
  assessmentCycleId: Scalars['Float'];
  id: Scalars['Float'];
  organisation: Organisation;
  organisationId: Scalars['Float'];
  report?: Maybe<ReportModel>;
  scorecard?: Maybe<ScorecardModel>;
  scores: Array<Score>;
  status: AssessmentStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Float'];
};

export type AssessmentCycle = {
  __typename?: 'AssessmentCycle';
  assessmentSchema: AssessmentSchema;
  assessments: Array<Assessment>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  employerAnswers: Array<EmployerAnswer>;
  employerScores: Array<EmployerScore>;
  id: Scalars['Float'];
  inviteBody?: Maybe<Scalars['String']>;
  inviteSubject?: Maybe<Scalars['String']>;
  inviteTextMessage?: Maybe<Scalars['String']>;
  organisation: Organisation;
  organisationId: Scalars['Float'];
  participationResults: ParticipationResults;
  quadrantScores: Array<QuadrantScore>;
  reminders: Array<Reminder>;
  reportRequest?: Maybe<ReportRequest>;
  reports?: Maybe<Array<ReportModel>>;
  scorecardRequest?: Maybe<ScorecardRequest>;
  scorecards?: Maybe<Array<ScorecardModel>>;
  startAssessmentNotificationSent?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  wellbeingPriorities: EmployerWellbeingPriorities;
  wellbeingScores: EmployerWellbeingScore;
  wordCloud: Array<WordCloudItem>;
};


export type AssessmentCycleWellbeingPrioritiesArgs = {
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
  rankFilter?: Maybe<RankFilterInput>;
};


export type AssessmentCycleWellbeingScoresArgs = {
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
};

export type AssessmentCycleResponse = {
  __typename?: 'AssessmentCycleResponse';
  assessmentCycle?: Maybe<AssessmentCycle>;
  error?: Maybe<Scalars['String']>;
};

export type AssessmentResponse = {
  __typename?: 'AssessmentResponse';
  assessment?: Maybe<Assessment>;
  error?: Maybe<Scalars['String']>;
};

export type AssessmentSchema = {
  __typename?: 'AssessmentSchema';
  id: Scalars['ID'];
  schema: AssessmentSchemaModel;
  version: Scalars['Float'];
};

export type AssessmentSchemaModel = {
  __typename?: 'AssessmentSchemaModel';
  employeeComputationSchema: Scalars['JSONObject'];
  questionSchema: Array<QuestionSection>;
  questionsRecord: Array<Question>;
  scalingFactorSchema: Scalars['JSONObject'];
};

export type AssessmentSchemaResponse = {
  __typename?: 'AssessmentSchemaResponse';
  assessmentSchema?: Maybe<AssessmentSchema>;
  error?: Maybe<Scalars['String']>;
};

/** Assessment Status */
export enum AssessmentStatus {
  Completed = 'COMPLETED',
  Errored = 'ERRORED',
  Started = 'STARTED'
}

export type AttributeInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Benefit = {
  __typename?: 'Benefit';
  benefitStoryblokId: Scalars['String'];
  computedResponse?: Maybe<Scalars['String']>;
  groupName: Scalars['String'];
  id: Scalars['Float'];
  isCustom?: Maybe<Scalars['Boolean']>;
  organisation: Organisation;
  organisationId: Scalars['ID'];
  response?: Maybe<Scalars['String']>;
};

export type BenefitWithSlug = {
  __typename?: 'BenefitWithSlug';
  details: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ChangeUserOrganisationIdResponse = {
  __typename?: 'ChangeUserOrganisationIdResponse';
  error?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['Float']>;
};

export type CompleteSectionResponse = {
  __typename?: 'CompleteSectionResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateOrganisationAdminInput = {
  contactNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  organisationId: Scalars['String'];
  position: Scalars['String'];
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type CreateOrganisationInput = {
  adminEmail: Scalars['String'];
  adminName: Scalars['String'];
  contactNumber?: Maybe<Scalars['String']>;
  isIntermediaryClientAdmin?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Float']>;
  uid: Scalars['String'];
};

export type CreateTesterResponse = {
  __typename?: 'CreateTesterResponse';
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type CreateTestersResponse = {
  __typename?: 'CreateTestersResponse';
  error?: Maybe<Scalars['String']>;
};


export type DefaultBenefitsResponse = {
  __typename?: 'DefaultBenefitsResponse';
  benefits: Array<BenefitWithSlug>;
  error?: Maybe<Scalars['String']>;
};

export type DemographicAnswer = {
  __typename?: 'DemographicAnswer';
  answersWithCount: Array<AnswerWithCount>;
  error?: Maybe<Scalars['String']>;
  questionId: Scalars['String'];
};

export type EditBenefitInput = {
  details: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  organisationId: Scalars['Float'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type EmployeeReportResponse = {
  __typename?: 'EmployeeReportResponse';
  error?: Maybe<Scalars['String']>;
  report?: Maybe<ReportModel>;
};

export type EmployerAnswer = {
  __typename?: 'EmployerAnswer';
  assessmentCycle: AssessmentCycle;
  assessmentCycleId?: Maybe<Scalars['ID']>;
  computedResponse?: Maybe<Scalars['String']>;
  editedBy?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  organisation: Organisation;
  organisationId?: Maybe<Scalars['ID']>;
  questionId: Scalars['String'];
  questionText?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
};

export type EmployerAnswerQuestionInput = {
  computedResponse: Scalars['String'];
  questionId: Scalars['String'];
  questionText: Scalars['String'];
  response: Scalars['String'];
};

export type EmployerAnswerQuestionResponse = {
  __typename?: 'EmployerAnswerQuestionResponse';
  employerAnswer?: Maybe<EmployerAnswer>;
  error?: Maybe<Scalars['String']>;
};

export type EmployerAnswerSchemaResponse = {
  __typename?: 'EmployerAnswerSchemaResponse';
  employerAnswerSchema?: Maybe<EmployerSchema>;
  error?: Maybe<Scalars['String']>;
};

export type EmployerProfileScore = {
  __typename?: 'EmployerProfileScore';
  name: Scalars['String'];
  scores: Array<ScoreModel>;
};

export type EmployerProfileScoreResponse = {
  __typename?: 'EmployerProfileScoreResponse';
  employerProfileScore?: Maybe<EmployerProfileScore>;
  error?: Maybe<Scalars['String']>;
};

export type EmployerReportInput = {
  assessmentCycleId: Scalars['Float'];
  id: Scalars['String'];
};

export type EmployerReportResponse = {
  __typename?: 'EmployerReportResponse';
  error?: Maybe<Scalars['String']>;
  report?: Maybe<ReportModel>;
};

export type EmployerSchema = {
  __typename?: 'EmployerSchema';
  id: Scalars['ID'];
  schema: EmployerSchemaModel;
  version: Scalars['Float'];
};

export type EmployerSchemaModel = {
  __typename?: 'EmployerSchemaModel';
  questionSchema: Array<QuestionSection>;
  questionsRecord: Array<Question>;
};

export type EmployerScore = {
  __typename?: 'EmployerScore';
  assessmentCycleId?: Maybe<Scalars['ID']>;
  category?: Maybe<ScoreCategory>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  scalingFactor?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type EmployerScorecardInput = {
  assessmentCycleId: Scalars['Float'];
  id: Scalars['String'];
};

export type EmployerScorecardResponse = {
  __typename?: 'EmployerScorecardResponse';
  error?: Maybe<Scalars['String']>;
  scorecard?: Maybe<ScorecardModel>;
};

export type EmployerWellbeingPriorities = {
  __typename?: 'EmployerWellbeingPriorities';
  error?: Maybe<Scalars['String']>;
  facets?: Maybe<Array<ScoreModel>>;
  level?: Maybe<ScoreCategory>;
  name?: Maybe<Scalars['String']>;
  scalingFactor?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type EmployerWellbeingScore = {
  __typename?: 'EmployerWellbeingScore';
  domains?: Maybe<Array<WellbeingScore>>;
  error?: Maybe<Scalars['String']>;
  level?: Maybe<ScoreCategory>;
  name?: Maybe<Scalars['String']>;
  scalingFactor?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse';
  error?: Maybe<Scalars['String']>;
  gcsName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type GcsFile = {
  __typename?: 'GcsFile';
  gcsName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type GenerateEmployeeReportInput = {
  assessmentId: Scalars['Float'];
};

export type GenerateEmployerReportInput = {
  assessmentCycleId: Scalars['Float'];
};

export type GenerateEmployerScorecardInput = {
  assessmentCycleId: Scalars['Float'];
};

export type GetAssessmentsQueryInput = {
  order?: Maybe<SortOrder>;
  orderByColumn?: Maybe<Scalars['String']>;
  pageOffset?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
};

export type GetBenefitInput = {
  organisationId: Scalars['Float'];
  slug: Scalars['String'];
};

export type GetBenefitsByGroupInput = {
  groupName: Scalars['String'];
  organisationId: Scalars['Float'];
};

export type GetBenefitsInput = {
  organisationId: Scalars['Float'];
};

export type GetUsersQueryInput = {
  email: Scalars['String'];
  order?: Maybe<SortOrder>;
  orderByColumn?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['ID']>;
  pageOffset?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
};

/** Group Type */
export enum GroupType {
  MultiSelect = 'MULTI_SELECT'
}

export type InputOption = {
  __typename?: 'InputOption';
  questions?: Maybe<Array<Question>>;
  transformedValue: Scalars['String'];
  value: Scalars['String'];
};

export type IntermediateUser = {
  __typename?: 'IntermediateUser';
  contactNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  organisation?: Maybe<Organisation>;
  organisationId?: Maybe<Scalars['Float']>;
  verificationToken: Scalars['String'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
};


export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginTesterResponse = {
  __typename?: 'LoginTesterResponse';
  customToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptAgreements: User;
  acceptInvite: AcceptInviteResponse;
  addBenefit: UpdateBenefitResponse;
  addBenefitGroup: Array<Benefit>;
  addUnverifiedUser: Scalars['Boolean'];
  answerBenefit: Benefit;
  answerEmployerQuestion: EmployerAnswerQuestionResponse;
  answerQuestion: AnswerQuestionResponse;
  changeOrganisationId: ChangeUserOrganisationIdResponse;
  completeAssessment: AssessmentResponse;
  completeAssessmentCycle: AssessmentCycleResponse;
  completeOrganisationSetup: Organisation;
  completeSection: CompleteSectionResponse;
  completeSetup: User;
  createOrganisation: Organisation;
  createOrganisationAdmin: UserResponse;
  createTester: CreateTesterResponse;
  createTesters: CreateTestersResponse;
  editBenefit: UpdateBenefitResponse;
  employerProfileScore: EmployerProfileScoreResponse;
  forgotPassword: Scalars['Boolean'];
  generateEmployeeReport: EmployeeReportResponse;
  generateEmployerReport: EmployerReportResponse;
  generateEmployerScorecard: EmployerScorecardResponse;
  login: UserResponse;
  loginTester: LoginTesterResponse;
  processReminders: ProcessRemindersResponse;
  productivityAnalysis: ProductivityScoreResponse;
  register: UserResponse;
  removeBenefitGroup: Array<Scalars['String']>;
  renameBenefitGroup: Array<Scalars['String']>;
  reprocessAnswers: Array<AnswerQuestionResponse>;
  requestReport: AssessmentCycleResponse;
  resendOrgAdminInvite: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  runSeed: RunSeedResponse;
  saveAssessmentCycle: AssessmentCycleResponse;
  saveReminder: SaveReminderResponse;
  startAssessment: AssessmentResponse;
  submitOrganisationAnswers: AssessmentCycleResponse;
  updateConfig: UpdateConfigResponse;
  updateEmployees: UpdateEmployeesResponse;
  updateOrganisation: Organisation;
  updateProfile: User;
  updateRolesAndPermissions: UserModel;
  uploadCompanyPicture: OrganisationResponse;
  uploadEmployerPicture: User;
  uploadFileToGCP: FileUploadResponse;
  verifyIntermediateUser: Scalars['Boolean'];
};


export type MutationAcceptInviteArgs = {
  acceptInviteInput: AcceptInviteInput;
};


export type MutationAddBenefitArgs = {
  addBenefitInput: AddBenefitInput;
  benefitImg?: Maybe<Scalars['Upload']>;
};


export type MutationAddBenefitGroupArgs = {
  getBenefitsByGroupInput: GetBenefitsByGroupInput;
};


export type MutationAddUnverifiedUserArgs = {
  newRequest: AccessRequestInput;
};


export type MutationAnswerBenefitArgs = {
  answerBenefitInput: AnswerBenefitInput;
};


export type MutationAnswerEmployerQuestionArgs = {
  answerQuestionInput: EmployerAnswerQuestionInput;
  assessmentCycleId?: Maybe<Scalars['ID']>;
  organisationId: Scalars['ID'];
};


export type MutationAnswerQuestionArgs = {
  answerQuestionInput: AnswerQuestionInput;
  assessmentId: Scalars['ID'];
};


export type MutationChangeOrganisationIdArgs = {
  organisationId: Scalars['ID'];
};


export type MutationCompleteAssessmentArgs = {
  assessmentId: Scalars['ID'];
  recompute?: Maybe<Scalars['Boolean']>;
};


export type MutationCompleteAssessmentCycleArgs = {
  id: Scalars['ID'];
};


export type MutationCompleteSectionArgs = {
  assessmentId: Scalars['ID'];
  sectionId: Scalars['String'];
};


export type MutationCreateOrganisationArgs = {
  createOrganisationInput: CreateOrganisationInput;
};


export type MutationCreateOrganisationAdminArgs = {
  createOrganisationAdminInput: CreateOrganisationAdminInput;
};


export type MutationCreateTesterArgs = {
  rid?: Maybe<Scalars['String']>;
};


export type MutationCreateTestersArgs = {
  num: Scalars['Float'];
};


export type MutationEditBenefitArgs = {
  benefitImg?: Maybe<Scalars['Upload']>;
  editBenefitInput: EditBenefitInput;
};


export type MutationEmployerProfileScoreArgs = {
  assessmentCycleId: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGenerateEmployeeReportArgs = {
  options: GenerateEmployeeReportInput;
};


export type MutationGenerateEmployerReportArgs = {
  options: GenerateEmployerReportInput;
};


export type MutationGenerateEmployerScorecardArgs = {
  options: GenerateEmployerScorecardInput;
};


export type MutationLoginArgs = {
  options: LoginInput;
};


export type MutationLoginTesterArgs = {
  rid: Scalars['String'];
};


export type MutationProductivityAnalysisArgs = {
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilters?: Maybe<Array<OrganisationUnitFilterInput>>;
};


export type MutationRegisterArgs = {
  options: RegisterInput;
};


export type MutationRemoveBenefitGroupArgs = {
  getBenefitsByGroupInput: GetBenefitsByGroupInput;
};


export type MutationRenameBenefitGroupArgs = {
  renameBenefitGroupInput: RenameBenefitGroupInput;
};


export type MutationReprocessAnswersArgs = {
  assessmentId: Scalars['ID'];
};


export type MutationRequestReportArgs = {
  id: Scalars['ID'];
  request: ReportRequestInput;
};


export type MutationResendOrgAdminInviteArgs = {
  organisationId: Scalars['Float'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRunSeedArgs = {
  name: Scalars['String'];
};


export type MutationSaveAssessmentCycleArgs = {
  options: SaveAssessmentCycleInput;
};


export type MutationSaveReminderArgs = {
  options: SaveReminderInput;
};


export type MutationStartAssessmentArgs = {
  options: StartAssessmentInput;
};


export type MutationSubmitOrganisationAnswersArgs = {
  answers: OrganisationAnswersInput;
  assessmentCycleId: Scalars['Float'];
  organisationId: Scalars['Float'];
};


export type MutationUpdateConfigArgs = {
  config: UpdateConfigInput;
};


export type MutationUpdateEmployeesArgs = {
  addEmployeesInput: Array<UpdateEmployeeInput>;
  organisationId: Scalars['ID'];
};


export type MutationUpdateOrganisationArgs = {
  organisationId: Scalars['ID'];
  updateOrganisationInput: UpdateOrganisationInput;
};


export type MutationUpdateProfileArgs = {
  updateProfileInput: UpdateProfileInput;
};


export type MutationUpdateRolesAndPermissionsArgs = {
  options: UpdateRolesAndPermissionsInput;
};


export type MutationUploadCompanyPictureArgs = {
  file: Scalars['Upload'];
  organisationId: Scalars['ID'];
};


export type MutationUploadEmployerPictureArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadFileToGcpArgs = {
  file: Scalars['Upload'];
};


export type MutationVerifyIntermediateUserArgs = {
  token: Scalars['String'];
};

export type OceanAttributes = {
  __typename?: 'OceanAttributes';
  deviation: OceanDeviation;
  deviationValue: Scalars['Float'];
};

export type OceanDataType = {
  __typename?: 'OceanDataType';
  high: Scalars['Float'];
  low: Scalars['Float'];
  scoreId: Scalars['String'];
  symbol: Scalars['String'];
};

/** Ocean Deviation */
export enum OceanDeviation {
  High = 'HIGH',
  Low = 'LOW',
  Middle = 'MIDDLE'
}

export type OceanScore = {
  __typename?: 'OceanScore';
  max?: Maybe<Scalars['Float']>;
  mean?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
};

export type OceanScoresResponse = {
  __typename?: 'OceanScoresResponse';
  error?: Maybe<Scalars['String']>;
  oceanScores?: Maybe<Array<OceanScore>>;
};

export type OceanScoresResponse2 = {
  __typename?: 'OceanScoresResponse2';
  error?: Maybe<Scalars['String']>;
  summedData: Array<OceanDataType>;
  traitsCount: Array<Scalars['String']>;
};

export type Organisation = {
  __typename?: 'Organisation';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  assessmentCycles: Array<AssessmentCycle>;
  companyContactNumber?: Maybe<Scalars['String']>;
  completedSetup?: Maybe<Scalars['Boolean']>;
  contactNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdOrganisations?: Maybe<Array<Organisation>>;
  employeeCount: Scalars['Float'];
  financeEmail?: Maybe<Scalars['String']>;
  financeName?: Maybe<Scalars['String']>;
  hasMFA?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organisationUnits: Array<OrganisationUnit>;
  parentId?: Maybe<Scalars['Float']>;
  postcode?: Maybe<Scalars['String']>;
  typeOfBusiness?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  userTest?: Maybe<UserTest>;
};

export type OrganisationAnswer = {
  computedResponse: Scalars['String'];
  questionId: Scalars['String'];
  questionText: Scalars['String'];
  response: Scalars['String'];
};

export type OrganisationAnswersInput = {
  answers: Array<OrganisationAnswer>;
};

export type OrganisationResponse = {
  __typename?: 'OrganisationResponse';
  error?: Maybe<Scalars['String']>;
  organisation?: Maybe<Organisation>;
};

export type OrganisationUnit = {
  __typename?: 'OrganisationUnit';
  attributeName: Scalars['String'];
  id: Scalars['ID'];
  organisation: Organisation;
  organisationId: Scalars['ID'];
  units: Array<OrganisationUnitModel>;
};

export type OrganisationUnitFilterInput = {
  attributeName: Scalars['String'];
  units: Array<OrganisationUnitModelInput>;
};

export type OrganisationUnitGroup = {
  __typename?: 'OrganisationUnitGroup';
  name?: Maybe<Scalars['String']>;
  units?: Maybe<Array<OrganisationUnitModel>>;
};

export type OrganisationUnitGroupInput = {
  name?: Maybe<Scalars['String']>;
  units?: Maybe<Array<OrganisationUnitModelInput>>;
};

export type OrganisationUnitModel = {
  __typename?: 'OrganisationUnitModel';
  name: Scalars['String'];
};

export type OrganisationUnitModelInput = {
  name: Scalars['String'];
};

export type OrganisationUnitsCount = {
  __typename?: 'OrganisationUnitsCount';
  attributeName?: Maybe<Scalars['String']>;
  completedEmployeeCount?: Maybe<Scalars['Float']>;
  totalEmployeeCount?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
};

export type PagedAssessmentsResponse = {
  __typename?: 'PagedAssessmentsResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<Assessment>;
  totalCount: Scalars['Float'];
};

export type PagedIntermediateUserResponse = {
  __typename?: 'PagedIntermediateUserResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<IntermediateUser>;
  totalCount: Scalars['Float'];
};

export type PagedOrganisationsResponse = {
  __typename?: 'PagedOrganisationsResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<Organisation>;
  totalCount: Scalars['Float'];
};

export type PagedQueryInput = {
  order?: Maybe<SortOrder>;
  orderByColumn?: Maybe<Scalars['String']>;
  pageOffset?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
};

export type PagedUsersResponse = {
  __typename?: 'PagedUsersResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<UserModel>;
  totalCount: Scalars['Float'];
};

export type ParticipationPerOrganisationUnit = {
  __typename?: 'ParticipationPerOrganisationUnit';
  attributeName?: Maybe<Scalars['String']>;
  completedEmployeeCount?: Maybe<Scalars['Float']>;
  startedEmployeeCount?: Maybe<Scalars['Float']>;
  totalEmployeeCount?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
};

export type ParticipationResults = {
  __typename?: 'ParticipationResults';
  completedEmployeeCount?: Maybe<Scalars['Float']>;
  completionRate?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  organisationUnitsCounts?: Maybe<Array<OrganisationUnitsCount>>;
  totalEmployeeCount?: Maybe<Scalars['Float']>;
};

export type Permissions = {
  __typename?: 'Permissions';
  reportViewerPermissions?: Maybe<ReportViewerPermissions>;
};

export type PermissionsInput = {
  reportViewerPermissions?: Maybe<ReportViwerPermissionsInput>;
};

export type ProcessRemindersResponse = {
  __typename?: 'ProcessRemindersResponse';
  error?: Maybe<Scalars['String']>;
  results: Array<ProcessRemindersResult>;
};

export type ProcessRemindersResult = {
  __typename?: 'ProcessRemindersResult';
  error?: Maybe<Scalars['String']>;
  numberProcessed?: Maybe<Scalars['Float']>;
  reminder: Reminder;
};

export type ProductivityScore = {
  __typename?: 'ProductivityScore';
  name: Scalars['String'];
  scores: Array<ScoreModel>;
  units?: Maybe<Array<UnitProductivityScores>>;
};

export type ProductivityScoreResponse = {
  __typename?: 'ProductivityScoreResponse';
  error?: Maybe<Scalars['String']>;
  productivityScore?: Maybe<ProductivityScore>;
};

export type QuadrantScore = {
  __typename?: 'QuadrantScore';
  mean?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
  stdev?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  assessment: AssessmentResponse;
  assessmentCycle: AssessmentCycle;
  assessmentCycles: Array<AssessmentCycle>;
  assessmentSchema: AssessmentSchemaResponse;
  assessments: PagedAssessmentsResponse;
  currentAssessmentCycleId?: Maybe<Scalars['ID']>;
  demographics: Array<DemographicAnswer>;
  dynamicAssessmentSchema: QuestionSection;
  employeeReport: ReportModel;
  employees: PagedUsersResponse;
  employerAnswerSchema: EmployerAnswerSchemaResponse;
  employerAnswers: Array<EmployerAnswer>;
  employerReport: ReportModel;
  employerScorecard?: Maybe<ScorecardModel>;
  getAllOrganisationBenefits: Array<Benefit>;
  getBenefit: UpdateBenefitResponse;
  getBenefitGroupNames: Array<Scalars['String']>;
  getBenefitsByGroup: Array<Benefit>;
  getOrganisationAccessRequestURL: Scalars['String'];
  getPagedIntermediateUsers: PagedIntermediateUserResponse;
  getStoryblokBenefits: DefaultBenefitsResponse;
  getVerifiedIntermediateUsers: Array<IntermediateUser>;
  intermediaryOrganisations: PagedOrganisationsResponse;
  isUserInOrganisationWithMFA: Scalars['Boolean'];
  latestCompletedAssessmentId?: Maybe<Scalars['ID']>;
  oceanScores: OceanScoresResponse;
  oceanScores2: OceanScoresResponse2;
  organisation: OrganisationResponse;
  organisations: PagedOrganisationsResponse;
  participationPerUnits: Array<ParticipationPerOrganisationUnit>;
  self: UserModel;
  user: QueryUserResponse;
  userTests: Array<UserTest>;
  users: PagedUsersResponse;
};


export type QueryAssessmentArgs = {
  id: Scalars['ID'];
};


export type QueryAssessmentCycleArgs = {
  id: Scalars['ID'];
};


export type QueryAssessmentSchemaArgs = {
  id: Scalars['ID'];
};


export type QueryAssessmentsArgs = {
  options: GetAssessmentsQueryInput;
};


export type QueryDemographicsArgs = {
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
};


export type QueryDynamicAssessmentSchemaArgs = {
  getDynamicSectionInput: GetBenefitsByGroupInput;
};


export type QueryEmployeeReportArgs = {
  id: Scalars['String'];
};


export type QueryEmployeesArgs = {
  searchString?: Maybe<Scalars['String']>;
};


export type QueryEmployerAnswerSchemaArgs = {
  id: Scalars['ID'];
};


export type QueryEmployerAnswersArgs = {
  assessmentCycleId?: Maybe<Scalars['ID']>;
};


export type QueryEmployerReportArgs = {
  options: EmployerReportInput;
};


export type QueryEmployerScorecardArgs = {
  options: EmployerScorecardInput;
};


export type QueryGetAllOrganisationBenefitsArgs = {
  getBenefitsInput: GetBenefitsInput;
};


export type QueryGetBenefitArgs = {
  getBenefitInput: GetBenefitInput;
};


export type QueryGetBenefitGroupNamesArgs = {
  getBenefitsInput: GetBenefitsInput;
};


export type QueryGetBenefitsByGroupArgs = {
  getBenefitsByGroupInput: GetBenefitsByGroupInput;
};


export type QueryGetPagedIntermediateUsersArgs = {
  options?: Maybe<PagedQueryInput>;
};


export type QueryGetStoryblokBenefitsArgs = {
  getBenefitsInput: GetBenefitsInput;
};


export type QueryIntermediaryOrganisationsArgs = {
  options?: Maybe<PagedQueryInput>;
};


export type QueryIsUserInOrganisationWithMfaArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryOceanScoresArgs = {
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
};


export type QueryOceanScores2Args = {
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
};


export type QueryOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganisationsArgs = {
  options?: Maybe<PagedQueryInput>;
};


export type QueryParticipationPerUnitsArgs = {
  assessmentCycleId: Scalars['ID'];
  fullCompany?: Maybe<Scalars['Boolean']>;
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
};


export type QueryUserArgs = {
  options: QueryUserInput;
};


export type QueryUsersArgs = {
  options?: Maybe<GetUsersQueryInput>;
};

export type QueryUserInput = {
  id: Scalars['ID'];
};

export type QueryUserResponse = {
  __typename?: 'QueryUserResponse';
  error?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

export type Question = {
  __typename?: 'Question';
  customContentId?: Maybe<Scalars['String']>;
  defaultValueIfUnanswered?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOptional?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<InputOption>>;
  type: QuestionType;
  validation?: Maybe<Scalars['String']>;
};

export type QuestionGroup = {
  __typename?: 'QuestionGroup';
  id: Scalars['String'];
  questions: Array<Question>;
  type?: Maybe<GroupType>;
};

export type QuestionSection = {
  __typename?: 'QuestionSection';
  id: Scalars['String'];
  isDynamicSection?: Maybe<Scalars['Boolean']>;
  isTestQuestions?: Maybe<Scalars['Boolean']>;
  questionGroups: Array<QuestionGroup>;
};

/** Question Type */
export enum QuestionType {
  Boolean = 'BOOLEAN',
  Check = 'CHECK',
  CurrencyInput = 'CURRENCY_INPUT',
  DateInput = 'DATE_INPUT',
  HeightInputMetric = 'HEIGHT_INPUT_METRIC',
  LengthInputMetric = 'LENGTH_INPUT_METRIC',
  MultiSelect = 'MULTI_SELECT',
  NumberSelect = 'NUMBER_SELECT',
  Select = 'SELECT',
  SingleSelect = 'SINGLE_SELECT',
  SingleSelectSlider = 'SINGLE_SELECT_SLIDER',
  TextArea = 'TEXT_AREA',
  TextInput = 'TEXT_INPUT',
  WeightInputMetric = 'WEIGHT_INPUT_METRIC',
  WordCloud = 'WORD_CLOUD',
  YearInPastSelect = 'YEAR_IN_PAST_SELECT',
  YearSelect = 'YEAR_SELECT'
}

export enum RankFilterEnumInput {
  BestPerforming = 'BEST_PERFORMING',
  WorsePerforming = 'WORSE_PERFORMING'
}

export type RankFilterInput = {
  filter: RankFilterEnumInput;
  limit?: Maybe<Scalars['Float']>;
};

export type RegisterInput = {
  contactNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  organisationId: Scalars['String'];
  password: Scalars['String'];
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Reminder = {
  __typename?: 'Reminder';
  assessmentCycle: AssessmentCycle;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creatorId: Scalars['Float'];
  id: Scalars['Float'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  subject?: Maybe<Scalars['String']>;
  textMessage?: Maybe<Scalars['String']>;
};

export type RenameBenefitGroupInput = {
  currentName: Scalars['String'];
  newName: Scalars['String'];
  organisationId: Scalars['Float'];
};

export type ReportModel = {
  __typename?: 'ReportModel';
  createdAt?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  gcsFile?: Maybe<GcsFile>;
  id: Scalars['String'];
  requestedAt?: Maybe<Scalars['String']>;
  status: ReportStatus;
};

export type ReportRequest = {
  __typename?: 'ReportRequest';
  dateRequested: Scalars['DateTime'];
  request: Scalars['String'];
  subject: Scalars['String'];
};

export type ReportRequestInput = {
  dateRequested: Scalars['DateTime'];
  request: Scalars['String'];
  subject: Scalars['String'];
};

/** Current status of the report */
export enum ReportStatus {
  Created = 'CREATED',
  Errored = 'ERRORED',
  Requested = 'REQUESTED'
}

export type ReportViewerPermissions = {
  __typename?: 'ReportViewerPermissions';
  allowedOrganisationUnits?: Maybe<Array<OrganisationUnitGroup>>;
};

export type ReportViwerPermissionsInput = {
  allowedOrganisationUnits?: Maybe<Array<OrganisationUnitGroupInput>>;
};

export type RunSeedResponse = {
  __typename?: 'RunSeedResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SaveAssessmentCycleInput = {
  assessmentCycleId?: Maybe<Scalars['ID']>;
  inviteBody?: Maybe<Scalars['String']>;
  inviteSubject?: Maybe<Scalars['String']>;
  inviteTextMessage?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type SaveReminderInput = {
  assessmentCycleId: Scalars['ID'];
  content: Scalars['String'];
  reminderId?: Maybe<Scalars['ID']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  textMessage: Scalars['String'];
};

export type SaveReminderResponse = {
  __typename?: 'SaveReminderResponse';
  error?: Maybe<Scalars['String']>;
  reminder?: Maybe<Reminder>;
};

export type Score = {
  __typename?: 'Score';
  assessmentCycleId?: Maybe<Scalars['ID']>;
  assessmentId?: Maybe<Scalars['ID']>;
  category?: Maybe<ScoreCategory>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isModifiable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  oceanAttributes?: Maybe<OceanAttributes>;
  rank?: Maybe<Scalars['Float']>;
  scalingFactor?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
  subScores?: Maybe<Array<SubScore>>;
  userId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Float']>;
};

/** Score Category */
export enum ScoreCategory {
  Domain = 'DOMAIN',
  Employer = 'EMPLOYER',
  Facet = 'FACET',
  Intermediary = 'INTERMEDIARY',
  Mental = 'MENTAL',
  Ocean = 'OCEAN',
  Physical = 'PHYSICAL',
  Productivity = 'PRODUCTIVITY',
  Profile = 'PROFILE',
  Toplevel = 'TOPLEVEL'
}

export type ScoreModel = {
  __typename?: 'ScoreModel';
  error?: Maybe<Scalars['String']>;
  level?: Maybe<ScoreCategory>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Float']>;
  scalingFactor?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
};

export type ScorecardModel = {
  __typename?: 'ScorecardModel';
  createdAt?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  gcsFile?: Maybe<GcsFile>;
  id: Scalars['String'];
  requestedAt?: Maybe<Scalars['String']>;
  status: ScorecardStatus;
};

export type ScorecardRequest = {
  __typename?: 'ScorecardRequest';
  dateRequested: Scalars['DateTime'];
  request: Scalars['String'];
  subject: Scalars['String'];
};

/** Current status of the scorecard */
export enum ScorecardStatus {
  Created = 'CREATED',
  Errored = 'ERRORED',
  Requested = 'REQUESTED'
}

/** Sort Order */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartAssessmentInput = {
  assessmentCycleId: Scalars['Float'];
};

export type StoryblokBenefit = {
  __typename?: 'StoryblokBenefit';
  details: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SubScore = {
  __typename?: 'SubScore';
  error?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  parentScoreId?: Maybe<Scalars['ID']>;
  scalingFactor?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type UnitProductivityScores = {
  __typename?: 'UnitProductivityScores';
  name: Scalars['String'];
  parentName?: Maybe<Scalars['ID']>;
  scores?: Maybe<Array<ScoreModel>>;
};

export type UpdateBenefitResponse = {
  __typename?: 'UpdateBenefitResponse';
  benefit?: Maybe<StoryblokBenefit>;
  error?: Maybe<Scalars['String']>;
};

export type UpdateConfigInput = {
  testOrganisationId: Scalars['ID'];
};

export type UpdateConfigResponse = {
  __typename?: 'UpdateConfigResponse';
  appConfig: AppConfig;
  error?: Maybe<Scalars['String']>;
};

export type UpdateEmployeeInput = {
  attributes?: Maybe<Array<AttributeInput>>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isIntermediaryClientAdmin?: Maybe<Scalars['Boolean']>;
};

export type UpdateEmployeesResponse = {
  __typename?: 'UpdateEmployeesResponse';
  error?: Maybe<Scalars['String']>;
  result: UploadEmployeesResponse;
  updatedEmployees: Array<User>;
};

export type UpdateOrganisationInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  companyContactNumber?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  financeEmail?: Maybe<Scalars['String']>;
  financeName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  typeOfBusiness?: Maybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  contactNumber: Scalars['String'];
  firstName: Scalars['String'];
  organisationId: Scalars['String'];
  position: Scalars['String'];
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateRolesAndPermissionsInput = {
  permissions: PermissionsInput;
  roles: Array<UserRole>;
  userId: Scalars['ID'];
};


export type UploadEmployeesResponse = {
  __typename?: 'UploadEmployeesResponse';
  addedEmployeeCount?: Maybe<Scalars['Float']>;
  deletedEmployeeCount?: Maybe<Scalars['Float']>;
  employeeCount?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  modifiedEmployeeCount?: Maybe<Scalars['Float']>;
  organisationUnitCount?: Maybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  acceptedGdprAt?: Maybe<Scalars['DateTime']>;
  assessments: Array<Assessment>;
  attributes?: Maybe<Scalars['JSONObject']>;
  completedSetup?: Maybe<Scalars['Boolean']>;
  contactNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isDeleted: Scalars['Boolean'];
  mainOrganisationId?: Maybe<Scalars['Float']>;
  organisation?: Maybe<Organisation>;
  organisationId?: Maybe<Scalars['Float']>;
  picture?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  rid?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UserModel = {
  __typename?: 'UserModel';
  acceptedGdprAt?: Maybe<Scalars['DateTime']>;
  assessments: Array<Assessment>;
  attributes?: Maybe<Scalars['JSONObject']>;
  completedSetup?: Maybe<Scalars['Boolean']>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  isDeleted: Scalars['Boolean'];
  mainOrganisationId?: Maybe<Scalars['Float']>;
  organisation?: Maybe<Organisation>;
  organisationId?: Maybe<Scalars['Float']>;
  permissions?: Maybe<Permissions>;
  picture?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  rid?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  error?: Maybe<Scalars['String']>;
  idToken?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

/** User Role */
export enum UserRole {
  Admin = 'ADMIN',
  IntermediaryClientAdmin = 'INTERMEDIARY_CLIENT_ADMIN',
  OrgAdmin = 'ORG_ADMIN',
  ReportViewer = 'REPORT_VIEWER',
  Tester = 'TESTER',
  User = 'USER'
}

export type UserTest = {
  __typename?: 'UserTest';
  employeeCsv?: Maybe<GcsFile>;
  id: Scalars['Float'];
  organisation: Organisation;
  participantCount: Scalars['Float'];
  testerCount: PagedUsersResponse;
};


export type UserTestTesterCountArgs = {
  query?: Maybe<PagedQueryInput>;
};

export type WellbeingScore = {
  __typename?: 'WellbeingScore';
  benchmark?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  level?: Maybe<ScoreCategory>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  scalingFactor?: Maybe<Scalars['Float']>;
  scoreId: Scalars['String'];
  subScores?: Maybe<Array<ScoreModel>>;
  value?: Maybe<Scalars['Float']>;
};

export type WordCloudItem = {
  __typename?: 'WordCloudItem';
  text: Scalars['String'];
  value: Scalars['Float'];
};

export type AnswerBenefitVariables = Exact<{
  answerBenefitInput: AnswerBenefitInput;
}>;


export type AnswerBenefit = (
  { __typename?: 'Mutation' }
  & { answerBenefit: (
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'response'>
  ) }
);

export type AddBenefitGroupVariables = Exact<{
  getBenefitsByGroupInput: GetBenefitsByGroupInput;
}>;


export type AddBenefitGroup = (
  { __typename?: 'Mutation' }
  & { addBenefitGroup: Array<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id'>
  )> }
);

export type RemoveBenefitGroupVariables = Exact<{
  getBenefitsByGroupInput: GetBenefitsByGroupInput;
}>;


export type RemoveBenefitGroup = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBenefitGroup'>
);

export type RenameBenefitGroupVariables = Exact<{
  renameBenefitGroupInput: RenameBenefitGroupInput;
}>;


export type RenameBenefitGroup = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'renameBenefitGroup'>
);

export type ChangeOrganisationIdVariables = Exact<{
  organisationId: Scalars['ID'];
}>;


export type ChangeOrganisationId = (
  { __typename?: 'Mutation' }
  & { changeOrganisationId: (
    { __typename?: 'ChangeUserOrganisationIdResponse' }
    & Pick<ChangeUserOrganisationIdResponse, 'organisationId'>
  ) }
);

export type CreateOrganisationAdminVariables = Exact<{
  createOrganisationAdminInput: CreateOrganisationAdminInput;
}>;


export type CreateOrganisationAdmin = (
  { __typename?: 'Mutation' }
  & { createOrganisationAdmin: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'idToken'>
    & { user?: Maybe<(
      { __typename?: 'UserModel' }
      & Pick<UserModel, 'id' | 'firstName' | 'surname' | 'firebaseId'>
    )> }
  ) }
);

export type CreateOrganisationVariables = Exact<{
  createOrganisationInput: CreateOrganisationInput;
}>;


export type CreateOrganisation = (
  { __typename?: 'Mutation' }
  & { createOrganisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name' | 'contactNumber' | 'employeeCount' | 'createdAt'>
    & { assessmentCycles: Array<(
      { __typename?: 'AssessmentCycle' }
      & Pick<AssessmentCycle, 'id' | 'startDate'>
      & { participationResults: (
        { __typename?: 'ParticipationResults' }
        & Pick<ParticipationResults, 'totalEmployeeCount' | 'completedEmployeeCount' | 'completionRate'>
      ) }
    )>, userTest?: Maybe<(
      { __typename?: 'UserTest' }
      & Pick<UserTest, 'id'>
      & { employeeCsv?: Maybe<(
        { __typename?: 'GcsFile' }
        & Pick<GcsFile, 'url'>
      )> }
    )> }
  ) }
);

export type UpdateOrganisationVariables = Exact<{
  organisationId: Scalars['ID'];
  updateOrganisationInput: UpdateOrganisationInput;
}>;


export type UpdateOrganisation = (
  { __typename?: 'Mutation' }
  & { updateOrganisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
  ) }
);

export type AnswerEmployerQuestionVariables = Exact<{
  organisationId: Scalars['ID'];
  answerQuestionInput: EmployerAnswerQuestionInput;
  assessmentCycleId?: Maybe<Scalars['ID']>;
}>;


export type AnswerEmployerQuestion = (
  { __typename?: 'Mutation' }
  & { answerEmployerQuestion: (
    { __typename?: 'EmployerAnswerQuestionResponse' }
    & { employerAnswer?: Maybe<(
      { __typename?: 'EmployerAnswer' }
      & Pick<EmployerAnswer, 'id' | 'questionId' | 'computedResponse' | 'response' | 'questionText' | 'assessmentCycleId'>
    )> }
  ) }
);

export type CompleteOrganisationSetupVariables = Exact<{ [key: string]: never; }>;


export type CompleteOrganisationSetup = (
  { __typename?: 'Mutation' }
  & { completeOrganisationSetup: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'completedSetup'>
  ) }
);

export type ReprocessAnswersVariables = Exact<{
  assessmentId: Scalars['ID'];
}>;


export type ReprocessAnswers = (
  { __typename?: 'Mutation' }
  & { reprocessAnswers: Array<(
    { __typename?: 'AnswerQuestionResponse' }
    & { answer?: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'questionId' | 'rawResponse' | 'transformedResponse' | 'scalingFactor'>
    )> }
  )> }
);

export type ResendOrgAdminInviteVariables = Exact<{
  organisationId: Scalars['Float'];
}>;


export type ResendOrgAdminInvite = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendOrgAdminInvite'>
);

export type UpdateRolesAndPermissionVariables = Exact<{
  options: UpdateRolesAndPermissionsInput;
}>;


export type UpdateRolesAndPermission = (
  { __typename?: 'Mutation' }
  & { updateRolesAndPermissions: (
    { __typename?: 'UserModel' }
    & Pick<UserModel, 'id' | 'roles'>
    & { permissions?: Maybe<(
      { __typename?: 'Permissions' }
      & { reportViewerPermissions?: Maybe<(
        { __typename?: 'ReportViewerPermissions' }
        & { allowedOrganisationUnits?: Maybe<Array<(
          { __typename?: 'OrganisationUnitGroup' }
          & Pick<OrganisationUnitGroup, 'name'>
          & { units?: Maybe<Array<(
            { __typename?: 'OrganisationUnitModel' }
            & Pick<OrganisationUnitModel, 'name'>
          )>> }
        )>> }
      )> }
    )> }
  ) }
);

export type AddBenefitVariables = Exact<{
  addBenefitInput: AddBenefitInput;
  benefitImg?: Maybe<Scalars['Upload']>;
}>;


export type AddBenefit = (
  { __typename?: 'Mutation' }
  & { addBenefit: (
    { __typename?: 'UpdateBenefitResponse' }
    & Pick<UpdateBenefitResponse, 'error'>
    & { benefit?: Maybe<(
      { __typename?: 'StoryblokBenefit' }
      & Pick<StoryblokBenefit, 'title' | 'questionId'>
    )> }
  ) }
);

export type EditBenefitVariables = Exact<{
  editBenefitInput: EditBenefitInput;
  benefitImg?: Maybe<Scalars['Upload']>;
}>;


export type EditBenefit = (
  { __typename?: 'Mutation' }
  & { editBenefit: (
    { __typename?: 'UpdateBenefitResponse' }
    & Pick<UpdateBenefitResponse, 'error'>
    & { benefit?: Maybe<(
      { __typename?: 'StoryblokBenefit' }
      & Pick<StoryblokBenefit, 'title' | 'details' | 'link' | 'image'>
    )> }
  ) }
);

export type UploadCompanyPictureVariables = Exact<{
  organisationId: Scalars['ID'];
  file: Scalars['Upload'];
}>;


export type UploadCompanyPicture = (
  { __typename?: 'Mutation' }
  & { uploadCompanyPicture: (
    { __typename?: 'OrganisationResponse' }
    & { organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'logoUrl' | 'name'>
    )> }
  ) }
);

export type GetOrganisationAccessRequestUrlVariables = Exact<{ [key: string]: never; }>;


export type GetOrganisationAccessRequestUrl = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOrganisationAccessRequestURL'>
);

export type GetPagedIntermediateUsersVariables = Exact<{
  options?: Maybe<PagedQueryInput>;
}>;


export type GetPagedIntermediateUsers = (
  { __typename?: 'Query' }
  & { getPagedIntermediateUsers: (
    { __typename?: 'PagedIntermediateUserResponse' }
    & Pick<PagedIntermediateUserResponse, 'totalCount' | 'pageSize'>
    & { results: Array<(
      { __typename?: 'IntermediateUser' }
      & Pick<IntermediateUser, 'id' | 'firstName' | 'department' | 'contactNumber' | 'emailAddress' | 'verifiedAt'>
    )> }
  ) }
);

export type GetVerifiedIntermediateUsersVariables = Exact<{ [key: string]: never; }>;


export type GetVerifiedIntermediateUsers = (
  { __typename?: 'Query' }
  & { getVerifiedIntermediateUsers: Array<(
    { __typename?: 'IntermediateUser' }
    & Pick<IntermediateUser, 'id' | 'firstName' | 'department' | 'contactNumber' | 'emailAddress' | 'verifiedAt'>
  )> }
);

export type AssessmentSchemaWithComputationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentSchemaWithComputationsQuery = (
  { __typename?: 'Query' }
  & { assessmentSchema: (
    { __typename?: 'AssessmentSchemaResponse' }
    & Pick<AssessmentSchemaResponse, 'error'>
    & { assessmentSchema?: Maybe<(
      { __typename?: 'AssessmentSchema' }
      & { schema: (
        { __typename?: 'AssessmentSchemaModel' }
        & Pick<AssessmentSchemaModel, 'employeeComputationSchema'>
      ) }
    )> }
  ) }
);

export type AssessmentsQueryVariables = Exact<{
  options: GetAssessmentsQueryInput;
}>;


export type AssessmentsQuery = (
  { __typename?: 'Query' }
  & { assessments: (
    { __typename?: 'PagedAssessmentsResponse' }
    & Pick<PagedAssessmentsResponse, 'totalCount'>
    & { results: Array<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'status' | 'updatedAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'firebaseId'>
      ), organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'name'>
      ) }
    )> }
  ) }
);

export type AssessmentWithComputationSchemaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentWithComputationSchemaQuery = (
  { __typename?: 'Query' }
  & { assessment: (
    { __typename?: 'AssessmentResponse' }
    & { assessment?: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'status' | 'organisationId' | 'assessmentCycleId'>
      & { answers: Array<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'id' | 'questionId' | 'rawResponse' | 'transformedResponse' | 'scalingFactor'>
      )>, scores: Array<(
        { __typename?: 'Score' }
        & Pick<Score, 'id' | 'scoreId' | 'name' | 'category' | 'rank' | 'value' | 'score' | 'scalingFactor' | 'isModifiable'>
        & { oceanAttributes?: Maybe<(
          { __typename?: 'OceanAttributes' }
          & Pick<OceanAttributes, 'deviation' | 'deviationValue'>
        )>, subScores?: Maybe<Array<(
          { __typename?: 'SubScore' }
          & Pick<SubScore, 'id' | 'scoreId' | 'value' | 'score' | 'scalingFactor'>
        )>> }
      )>, assessmentCycle: (
        { __typename?: 'AssessmentCycle' }
        & { assessmentSchema: (
          { __typename?: 'AssessmentSchema' }
          & Pick<AssessmentSchema, 'version'>
          & { schema: (
            { __typename?: 'AssessmentSchemaModel' }
            & Pick<AssessmentSchemaModel, 'employeeComputationSchema' | 'scalingFactorSchema'>
          ) }
        ) }
      ) }
    )> }
  ) }
);

export type CurrentAssessmentCycleIdVariables = Exact<{ [key: string]: never; }>;


export type CurrentAssessmentCycleId = (
  { __typename?: 'Query' }
  & Pick<Query, 'currentAssessmentCycleId'>
);

export type GetBenefitVariables = Exact<{
  getBenefitInput: GetBenefitInput;
}>;


export type GetBenefit = (
  { __typename?: 'Query' }
  & { getBenefit: (
    { __typename?: 'UpdateBenefitResponse' }
    & Pick<UpdateBenefitResponse, 'error'>
    & { benefit?: Maybe<(
      { __typename?: 'StoryblokBenefit' }
      & Pick<StoryblokBenefit, 'title' | 'details' | 'link' | 'image'>
    )> }
  ) }
);

export type GetStoryblokBenefitsVariables = Exact<{
  getBenefitsInput: GetBenefitsInput;
}>;


export type GetStoryblokBenefits = (
  { __typename?: 'Query' }
  & { getStoryblokBenefits: (
    { __typename?: 'DefaultBenefitsResponse' }
    & Pick<DefaultBenefitsResponse, 'error'>
    & { benefits: Array<(
      { __typename?: 'BenefitWithSlug' }
      & Pick<BenefitWithSlug, 'slug' | 'title' | 'details' | 'link' | 'image'>
    )> }
  ) }
);

export type GetBenefitsByGroupVariables = Exact<{
  getBenefitsByGroupInput: GetBenefitsByGroupInput;
}>;


export type GetBenefitsByGroup = (
  { __typename?: 'Query' }
  & { getBenefitsByGroup: Array<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'groupName' | 'benefitStoryblokId' | 'isCustom' | 'response' | 'computedResponse'>
  )> }
);

export type GetBenefitGroupNamesVariables = Exact<{
  getBenefitsInput: GetBenefitsInput;
}>;


export type GetBenefitGroupNames = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBenefitGroupNames'>
);

export type GetAllOrganisationBenefitsVariables = Exact<{
  getBenefitsInput: GetBenefitsInput;
}>;


export type GetAllOrganisationBenefits = (
  { __typename?: 'Query' }
  & { getAllOrganisationBenefits: Array<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'benefitStoryblokId' | 'groupName' | 'isCustom' | 'response'>
  )> }
);

export type OrganisationsVariables = Exact<{
  options?: Maybe<PagedQueryInput>;
}>;


export type Organisations = (
  { __typename?: 'Query' }
  & { organisations: (
    { __typename?: 'PagedOrganisationsResponse' }
    & Pick<PagedOrganisationsResponse, 'pageSize' | 'pageOffset' | 'totalCount'>
    & { results: Array<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name' | 'employeeCount' | 'createdAt'>
      & { assessmentCycles: Array<(
        { __typename?: 'AssessmentCycle' }
        & Pick<AssessmentCycle, 'id' | 'organisationId' | 'startDate'>
        & { participationResults: (
          { __typename?: 'ParticipationResults' }
          & Pick<ParticipationResults, 'totalEmployeeCount' | 'completedEmployeeCount' | 'completionRate'>
        ), employerAnswers: Array<(
          { __typename?: 'EmployerAnswer' }
          & Pick<EmployerAnswer, 'questionId' | 'computedResponse'>
        )> }
      )>, userTest?: Maybe<(
        { __typename?: 'UserTest' }
        & Pick<UserTest, 'id'>
        & { employeeCsv?: Maybe<(
          { __typename?: 'GcsFile' }
          & Pick<GcsFile, 'url'>
        )> }
      )> }
    )> }
  ) }
);

export type EmployerAnswerSchemaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmployerAnswerSchemaQuery = (
  { __typename?: 'Query' }
  & { employerAnswerSchema: (
    { __typename?: 'EmployerAnswerSchemaResponse' }
    & Pick<EmployerAnswerSchemaResponse, 'error'>
    & { employerAnswerSchema?: Maybe<(
      { __typename?: 'EmployerSchema' }
      & { schema: (
        { __typename?: 'EmployerSchemaModel' }
        & { questionSchema: Array<(
          { __typename?: 'QuestionSection' }
          & Pick<QuestionSection, 'id' | 'isTestQuestions'>
          & { questionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'type'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'type' | 'validation' | 'isOptional'>
              & { options?: Maybe<Array<(
                { __typename?: 'InputOption' }
                & Pick<InputOption, 'value' | 'transformedValue'>
                & { questions?: Maybe<Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'type' | 'validation' | 'isOptional'>
                  & { options?: Maybe<Array<(
                    { __typename?: 'InputOption' }
                    & Pick<InputOption, 'value' | 'transformedValue'>
                    & { questions?: Maybe<Array<(
                      { __typename?: 'Question' }
                      & Pick<Question, 'id' | 'type' | 'validation' | 'isOptional'>
                      & { options?: Maybe<Array<(
                        { __typename?: 'InputOption' }
                        & Pick<InputOption, 'value' | 'transformedValue'>
                      )>> }
                    )>> }
                  )>> }
                )>> }
              )>> }
            )> }
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type EmployerAnswersQueryVariables = Exact<{
  assessmentCycleId?: Maybe<Scalars['ID']>;
}>;


export type EmployerAnswersQuery = (
  { __typename?: 'Query' }
  & { employerAnswers: Array<(
    { __typename?: 'EmployerAnswer' }
    & Pick<EmployerAnswer, 'assessmentCycleId' | 'questionId' | 'organisationId' | 'computedResponse' | 'response' | 'questionText'>
  )> }
);

export type UserQueryVariables = Exact<{
  options: QueryUserInput;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'QueryUserResponse' }
    & Pick<QueryUserResponse, 'error'>
    & { user?: Maybe<(
      { __typename?: 'UserModel' }
      & Pick<UserModel, 'id' | 'firebaseId' | 'firstName' | 'email' | 'roles'>
      & { permissions?: Maybe<(
        { __typename?: 'Permissions' }
        & { reportViewerPermissions?: Maybe<(
          { __typename?: 'ReportViewerPermissions' }
          & { allowedOrganisationUnits?: Maybe<Array<(
            { __typename?: 'OrganisationUnitGroup' }
            & Pick<OrganisationUnitGroup, 'name'>
            & { units?: Maybe<Array<(
              { __typename?: 'OrganisationUnitModel' }
              & Pick<OrganisationUnitModel, 'name'>
            )>> }
          )>> }
        )> }
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'name' | 'contactNumber' | 'id'>
        & { organisationUnits: Array<(
          { __typename?: 'OrganisationUnit' }
          & Pick<OrganisationUnit, 'attributeName'>
          & { units: Array<(
            { __typename?: 'OrganisationUnitModel' }
            & Pick<OrganisationUnitModel, 'name'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type UserTestsVariables = Exact<{ [key: string]: never; }>;


export type UserTests = (
  { __typename?: 'Query' }
  & { userTests: Array<(
    { __typename?: 'UserTest' }
    & Pick<UserTest, 'id' | 'participantCount'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    ), employeeCsv?: Maybe<(
      { __typename?: 'GcsFile' }
      & Pick<GcsFile, 'url'>
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  options?: Maybe<GetUsersQueryInput>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'PagedUsersResponse' }
    & Pick<PagedUsersResponse, 'totalCount'>
    & { results: Array<(
      { __typename?: 'UserModel' }
      & Pick<UserModel, 'id' | 'firebaseId' | 'firstName' | 'email' | 'organisationId'>
      & { organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'name'>
      )> }
    )> }
  ) }
);

export type SendAccessRequestVariables = Exact<{
  input: AccessRequestInput;
}>;


export type SendAccessRequest = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addUnverifiedUser'>
);

export type VerifyIntermediateUserVariables = Exact<{
  input: Scalars['String'];
}>;


export type VerifyIntermediateUser = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyIntermediateUser'>
);

export type StartAssessmentVariables = Exact<{
  options: StartAssessmentInput;
}>;


export type StartAssessment = (
  { __typename?: 'Mutation' }
  & { startAssessment: (
    { __typename?: 'AssessmentResponse' }
    & Pick<AssessmentResponse, 'error'>
    & { assessment?: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'status'>
    )> }
  ) }
);

export type AnswerQuestionVariables = Exact<{
  assessmentId: Scalars['ID'];
  answerQuestionInput: AnswerQuestionInput;
}>;


export type AnswerQuestion = (
  { __typename?: 'Mutation' }
  & { answerQuestion: (
    { __typename?: 'AnswerQuestionResponse' }
    & Pick<AnswerQuestionResponse, 'error'>
    & { answer?: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'questionId' | 'rawResponse' | 'transformedResponse'>
    )> }
  ) }
);

export type CompleteSectionVariables = Exact<{
  assessmentId: Scalars['ID'];
  sectionId: Scalars['String'];
}>;


export type CompleteSection = (
  { __typename?: 'Mutation' }
  & { completeSection: (
    { __typename?: 'CompleteSectionResponse' }
    & Pick<CompleteSectionResponse, 'success' | 'error'>
  ) }
);

export type CompleteAssessmentVariables = Exact<{
  assessmentId: Scalars['ID'];
  recompute?: Maybe<Scalars['Boolean']>;
}>;


export type CompleteAssessment = (
  { __typename?: 'Mutation' }
  & { completeAssessment: (
    { __typename?: 'AssessmentResponse' }
    & Pick<AssessmentResponse, 'error'>
    & { assessment?: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'status'>
      & { scores: Array<(
        { __typename?: 'Score' }
        & Pick<Score, 'name' | 'category' | 'id' | 'scoreId' | 'rank' | 'isModifiable' | 'value' | 'error'>
        & { oceanAttributes?: Maybe<(
          { __typename?: 'OceanAttributes' }
          & Pick<OceanAttributes, 'deviation'>
        )> }
      )> }
    )> }
  ) }
);

export type GenerateEmployeeReportVariables = Exact<{
  options: GenerateEmployeeReportInput;
}>;


export type GenerateEmployeeReport = (
  { __typename?: 'Mutation' }
  & { generateEmployeeReport: (
    { __typename?: 'EmployeeReportResponse' }
    & Pick<EmployeeReportResponse, 'error'>
    & { report?: Maybe<(
      { __typename?: 'ReportModel' }
      & Pick<ReportModel, 'id' | 'status' | 'error' | 'requestedAt'>
      & { gcsFile?: Maybe<(
        { __typename?: 'GcsFile' }
        & Pick<GcsFile, 'url'>
      )> }
    )> }
  ) }
);

export type SaveAssessmentCycleVariables = Exact<{
  organisationId: Scalars['ID'];
  inviteBody?: Maybe<Scalars['String']>;
  inviteSubject?: Maybe<Scalars['String']>;
  inviteTextMessage?: Maybe<Scalars['String']>;
  assessmentCycleId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
}>;


export type SaveAssessmentCycle = (
  { __typename?: 'Mutation' }
  & { saveAssessmentCycle: (
    { __typename?: 'AssessmentCycleResponse' }
    & Pick<AssessmentCycleResponse, 'error'>
    & { assessmentCycle?: Maybe<(
      { __typename?: 'AssessmentCycle' }
      & Pick<AssessmentCycle, 'id' | 'startDate' | 'completedAt'>
      & { participationResults: (
        { __typename?: 'ParticipationResults' }
        & Pick<ParticipationResults, 'totalEmployeeCount' | 'completedEmployeeCount' | 'completionRate'>
      ), reminders: Array<(
        { __typename?: 'Reminder' }
        & Pick<Reminder, 'id' | 'createdAt' | 'creatorId' | 'sentAt' | 'content' | 'scheduledAt'>
      )> }
    )> }
  ) }
);

export type SaveReminderVariables = Exact<{
  saveReminderInput: SaveReminderInput;
}>;


export type SaveReminder = (
  { __typename?: 'Mutation' }
  & { saveReminder: (
    { __typename?: 'SaveReminderResponse' }
    & Pick<SaveReminderResponse, 'error'>
    & { reminder?: Maybe<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'id'>
    )> }
  ) }
);

export type CompleteAssessmentCycleVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompleteAssessmentCycle = (
  { __typename?: 'Mutation' }
  & { completeAssessmentCycle: (
    { __typename?: 'AssessmentCycleResponse' }
    & Pick<AssessmentCycleResponse, 'error'>
    & { assessmentCycle?: Maybe<(
      { __typename?: 'AssessmentCycle' }
      & Pick<AssessmentCycle, 'id' | 'startDate' | 'completedAt'>
    )> }
  ) }
);

export type RequestReportVariables = Exact<{
  id: Scalars['ID'];
  request: ReportRequestInput;
}>;


export type RequestReport = (
  { __typename?: 'Mutation' }
  & { requestReport: (
    { __typename?: 'AssessmentCycleResponse' }
    & Pick<AssessmentCycleResponse, 'error'>
    & { assessmentCycle?: Maybe<(
      { __typename?: 'AssessmentCycle' }
      & Pick<AssessmentCycle, 'id'>
      & { reportRequest?: Maybe<(
        { __typename?: 'ReportRequest' }
        & Pick<ReportRequest, 'dateRequested' | 'subject' | 'request'>
      )> }
    )> }
  ) }
);

export type SubmitOrganisationAnswersVariables = Exact<{
  assessmentCycleId: Scalars['Float'];
  organisationId: Scalars['Float'];
  answers: OrganisationAnswersInput;
}>;


export type SubmitOrganisationAnswers = (
  { __typename?: 'Mutation' }
  & { submitOrganisationAnswers: (
    { __typename?: 'AssessmentCycleResponse' }
    & Pick<AssessmentCycleResponse, 'error'>
    & { assessmentCycle?: Maybe<(
      { __typename?: 'AssessmentCycle' }
      & Pick<AssessmentCycle, 'id'>
      & { employerAnswers: Array<(
        { __typename?: 'EmployerAnswer' }
        & Pick<EmployerAnswer, 'questionId' | 'questionText' | 'response' | 'computedResponse'>
      )> }
    )> }
  ) }
);

export type GenerateEmployerReportVariables = Exact<{
  options: GenerateEmployerReportInput;
}>;


export type GenerateEmployerReport = (
  { __typename?: 'Mutation' }
  & { generateEmployerReport: (
    { __typename?: 'EmployerReportResponse' }
    & Pick<EmployerReportResponse, 'error'>
    & { report?: Maybe<(
      { __typename?: 'ReportModel' }
      & Pick<ReportModel, 'id' | 'requestedAt' | 'status' | 'error'>
      & { gcsFile?: Maybe<(
        { __typename?: 'GcsFile' }
        & Pick<GcsFile, 'url'>
      )> }
    )> }
  ) }
);

export type GenerateEmployerScorecardVariables = Exact<{
  options: GenerateEmployerScorecardInput;
}>;


export type GenerateEmployerScorecard = (
  { __typename?: 'Mutation' }
  & { generateEmployerScorecard: (
    { __typename?: 'EmployerScorecardResponse' }
    & Pick<EmployerScorecardResponse, 'error'>
    & { scorecard?: Maybe<(
      { __typename?: 'ScorecardModel' }
      & Pick<ScorecardModel, 'id' | 'requestedAt' | 'status' | 'error'>
      & { gcsFile?: Maybe<(
        { __typename?: 'GcsFile' }
        & Pick<GcsFile, 'url'>
      )> }
    )> }
  ) }
);

export type LoginUserVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUser = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'error' | 'idToken'>
    & { user?: Maybe<(
      { __typename?: 'UserModel' }
      & Pick<UserModel, 'firebaseId'>
    )> }
  ) }
);

export type RegisterUserVariables = Exact<{
  registerInput: RegisterInput;
}>;


export type RegisterUser = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'error' | 'idToken'>
    & { user?: Maybe<(
      { __typename?: 'UserModel' }
      & Pick<UserModel, 'id' | 'firstName' | 'surname' | 'firebaseId'>
    )> }
  ) }
);

export type AcceptInviteVariables = Exact<{
  email: Scalars['String'];
  contactNumber?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  firstName: Scalars['String'];
  inviteToken: Scalars['String'];
}>;


export type AcceptInvite = (
  { __typename?: 'Mutation' }
  & { acceptInvite: (
    { __typename?: 'AcceptInviteResponse' }
    & Pick<AcceptInviteResponse, 'error' | 'customToken'>
  ) }
);

export type ForgotPasswordVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPassword = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPassword = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LoginTesterVariables = Exact<{
  rid: Scalars['String'];
}>;


export type LoginTester = (
  { __typename?: 'Mutation' }
  & { loginTester: (
    { __typename?: 'LoginTesterResponse' }
    & Pick<LoginTesterResponse, 'error' | 'customToken'>
  ) }
);

export type CompleteSetupVariables = Exact<{ [key: string]: never; }>;


export type CompleteSetup = (
  { __typename?: 'Mutation' }
  & { completeSetup: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'completedSetup'>
  ) }
);

export type EmployerProfileScoreMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmployerProfileScoreMutation = (
  { __typename?: 'Mutation' }
  & { employerProfileScore: (
    { __typename?: 'EmployerProfileScoreResponse' }
    & Pick<EmployerProfileScoreResponse, 'error'>
    & { employerProfileScore?: Maybe<(
      { __typename?: 'EmployerProfileScore' }
      & Pick<EmployerProfileScore, 'name'>
      & { scores: Array<(
        { __typename?: 'ScoreModel' }
        & Pick<ScoreModel, 'scoreId' | 'score' | 'error'>
      )> }
    )> }
  ) }
);

export type ProductivityAnalysisVariables = Exact<{
  id: Scalars['ID'];
  organisationUnitFilters?: Maybe<Array<OrganisationUnitFilterInput>>;
}>;


export type ProductivityAnalysis = (
  { __typename?: 'Mutation' }
  & { productivityAnalysis: (
    { __typename?: 'ProductivityScoreResponse' }
    & Pick<ProductivityScoreResponse, 'error'>
    & { productivityScore?: Maybe<(
      { __typename?: 'ProductivityScore' }
      & Pick<ProductivityScore, 'name'>
      & { scores: Array<(
        { __typename?: 'ScoreModel' }
        & Pick<ScoreModel, 'scoreId' | 'score' | 'scalingFactor' | 'error'>
      )>, units?: Maybe<Array<(
        { __typename?: 'UnitProductivityScores' }
        & Pick<UnitProductivityScores, 'name' | 'parentName'>
        & { scores?: Maybe<Array<(
          { __typename?: 'ScoreModel' }
          & Pick<ScoreModel, 'scoreId' | 'score' | 'scalingFactor' | 'error'>
        )>> }
      )>> }
    )> }
  ) }
);

export type UploadFileToGcpVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileToGcp = (
  { __typename?: 'Mutation' }
  & { uploadFileToGCP: (
    { __typename?: 'FileUploadResponse' }
    & Pick<FileUploadResponse, 'error' | 'id'>
  ) }
);

export type UpdateEmployeesVariables = Exact<{
  organisationId: Scalars['ID'];
  addEmployeesInput: Array<UpdateEmployeeInput>;
}>;


export type UpdateEmployees = (
  { __typename?: 'Mutation' }
  & { updateEmployees: (
    { __typename?: 'UpdateEmployeesResponse' }
    & Pick<UpdateEmployeesResponse, 'error'>
    & { updatedEmployees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'firebaseId' | 'firstName' | 'emailAddress' | 'contactNumber' | 'attributes'>
    )>, result: (
      { __typename?: 'UploadEmployeesResponse' }
      & Pick<UploadEmployeesResponse, 'organisationUnitCount' | 'employeeCount'>
    ) }
  ) }
);

export type UpdateUserProfileVariables = Exact<{
  updateProfileInput: UpdateProfileInput;
}>;


export type UpdateUserProfile = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'completedSetup'>
  ) }
);

export type UploadEmployerPictureVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadEmployerPicture = (
  { __typename?: 'Mutation' }
  & { uploadEmployerPicture: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName'>
  ) }
);

export type AcceptAgreementsVariables = Exact<{ [key: string]: never; }>;


export type AcceptAgreements = (
  { __typename?: 'Mutation' }
  & { acceptAgreements: (
    { __typename?: 'User' }
    & Pick<User, 'acceptedGdprAt'>
  ) }
);

export type AssessmentSchemaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentSchemaQuery = (
  { __typename?: 'Query' }
  & { assessmentSchema: (
    { __typename?: 'AssessmentSchemaResponse' }
    & Pick<AssessmentSchemaResponse, 'error'>
    & { assessmentSchema?: Maybe<(
      { __typename?: 'AssessmentSchema' }
      & { schema: (
        { __typename?: 'AssessmentSchemaModel' }
        & { questionSchema: Array<(
          { __typename?: 'QuestionSection' }
          & Pick<QuestionSection, 'id' | 'isTestQuestions' | 'isDynamicSection'>
          & { questionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'type'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'type' | 'validation' | 'isOptional'>
              & { options?: Maybe<Array<(
                { __typename?: 'InputOption' }
                & Pick<InputOption, 'value' | 'transformedValue'>
                & { questions?: Maybe<Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'type' | 'validation' | 'isOptional'>
                  & { options?: Maybe<Array<(
                    { __typename?: 'InputOption' }
                    & Pick<InputOption, 'value' | 'transformedValue'>
                    & { questions?: Maybe<Array<(
                      { __typename?: 'Question' }
                      & Pick<Question, 'id' | 'type' | 'validation' | 'isOptional'>
                      & { options?: Maybe<Array<(
                        { __typename?: 'InputOption' }
                        & Pick<InputOption, 'value' | 'transformedValue'>
                      )>> }
                    )>> }
                  )>> }
                )>> }
              )>> }
            )> }
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type AssessmentCyclesVariables = Exact<{ [key: string]: never; }>;


export type AssessmentCycles = (
  { __typename?: 'Query' }
  & { assessmentCycles: Array<(
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id' | 'createdAt'>
    & { assessments: Array<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'status'>
    )> }
  )> }
);

export type AssessmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentQuery = (
  { __typename?: 'Query' }
  & { assessment: (
    { __typename?: 'AssessmentResponse' }
    & { assessment?: Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'status' | 'organisationId' | 'assessmentCycleId'>
      & { answers: Array<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'id' | 'questionId' | 'rawResponse' | 'transformedResponse'>
      )>, scores: Array<(
        { __typename?: 'Score' }
        & Pick<Score, 'id' | 'scoreId' | 'name' | 'category' | 'rank' | 'score' | 'isModifiable'>
        & { oceanAttributes?: Maybe<(
          { __typename?: 'OceanAttributes' }
          & Pick<OceanAttributes, 'deviation' | 'deviationValue'>
        )>, subScores?: Maybe<Array<(
          { __typename?: 'SubScore' }
          & Pick<SubScore, 'id' | 'scoreId' | 'score'>
        )>> }
      )>, report?: Maybe<(
        { __typename?: 'ReportModel' }
        & Pick<ReportModel, 'id' | 'status' | 'error' | 'requestedAt'>
        & { gcsFile?: Maybe<(
          { __typename?: 'GcsFile' }
          & Pick<GcsFile, 'url'>
        )> }
      )> }
    )> }
  ) }
);

export type DynamicAssessmentSchemaVariables = Exact<{
  getDynamicSectionInput: GetBenefitsByGroupInput;
}>;


export type DynamicAssessmentSchema = (
  { __typename?: 'Query' }
  & { dynamicAssessmentSchema: (
    { __typename?: 'QuestionSection' }
    & Pick<QuestionSection, 'id' | 'isTestQuestions'>
    & { questionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'type'>
      & { questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'type' | 'validation' | 'isOptional' | 'customContentId'>
        & { options?: Maybe<Array<(
          { __typename?: 'InputOption' }
          & Pick<InputOption, 'value' | 'transformedValue'>
          & { questions?: Maybe<Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id'>
          )>> }
        )>> }
      )> }
    )> }
  ) }
);

export type LatestCompletedAssessmentIdVariables = Exact<{ [key: string]: never; }>;


export type LatestCompletedAssessmentId = (
  { __typename?: 'Query' }
  & Pick<Query, 'latestCompletedAssessmentId'>
);

export type AssesmentCycleWithEmployeeParticipationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssesmentCycleWithEmployeeParticipation = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id' | 'updatedAt'>
    & { participationResults: (
      { __typename?: 'ParticipationResults' }
      & Pick<ParticipationResults, 'totalEmployeeCount' | 'completionRate' | 'completedEmployeeCount'>
      & { organisationUnitsCounts?: Maybe<Array<(
        { __typename?: 'OrganisationUnitsCount' }
        & Pick<OrganisationUnitsCount, 'unit' | 'attributeName' | 'totalEmployeeCount' | 'completedEmployeeCount'>
      )>> }
    ) }
  ) }
);

export type AssessmentCycleWithEmployerAnswersVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentCycleWithEmployerAnswers = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id' | 'updatedAt'>
    & { employerAnswers: Array<(
      { __typename?: 'EmployerAnswer' }
      & Pick<EmployerAnswer, 'questionId' | 'questionText' | 'computedResponse'>
    )> }
  ) }
);

export type AssessmentCycleWithRemindersVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentCycleWithReminders = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id' | 'inviteBody' | 'startDate' | 'inviteSubject' | 'inviteTextMessage'>
    & { reminders: Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'id' | 'scheduledAt' | 'content' | 'subject' | 'textMessage'>
    )> }
  ) }
);

export type AssesmentCycleWithReportRequestVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssesmentCycleWithReportRequest = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id'>
    & { reportRequest?: Maybe<(
      { __typename?: 'ReportRequest' }
      & Pick<ReportRequest, 'dateRequested' | 'subject' | 'request'>
    )> }
  ) }
);

export type AssessmentCycleWithReportsVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentCycleWithReports = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id'>
    & { reports?: Maybe<Array<(
      { __typename?: 'ReportModel' }
      & Pick<ReportModel, 'id' | 'status' | 'error' | 'createdAt' | 'requestedAt'>
      & { gcsFile?: Maybe<(
        { __typename?: 'GcsFile' }
        & Pick<GcsFile, 'url'>
      )> }
    )>> }
  ) }
);

export type AssessmentCycleWithScorecardsVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentCycleWithScorecards = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id'>
    & { scorecards?: Maybe<Array<(
      { __typename?: 'ScorecardModel' }
      & Pick<ScorecardModel, 'id' | 'status' | 'error' | 'createdAt' | 'requestedAt'>
      & { gcsFile?: Maybe<(
        { __typename?: 'GcsFile' }
        & Pick<GcsFile, 'url'>
      )> }
    )>> }
  ) }
);

export type AssessmentCycleWithWellbeingPrioritiesVariables = Exact<{
  id: Scalars['ID'];
  rankFilter?: Maybe<RankFilterInput>;
  organisationUnitFilter: Array<OrganisationUnitFilterInput>;
}>;


export type AssessmentCycleWithWellbeingPriorities = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id'>
    & { wellbeingPriorities: (
      { __typename?: 'EmployerWellbeingPriorities' }
      & Pick<EmployerWellbeingPriorities, 'scoreId' | 'name' | 'value' | 'level' | 'scalingFactor'>
      & { facets?: Maybe<Array<(
        { __typename?: 'ScoreModel' }
        & Pick<ScoreModel, 'scoreId' | 'score' | 'scalingFactor' | 'level' | 'name' | 'rank'>
      )>> }
    ) }
  ) }
);

export type AssessmentCycleWithWellbeingScoresVariables = Exact<{
  id: Scalars['ID'];
  organisationUnitFilter: Array<OrganisationUnitFilterInput>;
}>;


export type AssessmentCycleWithWellbeingScores = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id'>
    & { wellbeingScores: (
      { __typename?: 'EmployerWellbeingScore' }
      & Pick<EmployerWellbeingScore, 'name' | 'value' | 'scoreId' | 'scalingFactor'>
      & { domains?: Maybe<Array<(
        { __typename?: 'WellbeingScore' }
        & Pick<WellbeingScore, 'scoreId' | 'level' | 'name' | 'value' | 'min' | 'max' | 'scalingFactor'>
        & { subScores?: Maybe<Array<(
          { __typename?: 'ScoreModel' }
          & Pick<ScoreModel, 'scoreId' | 'score' | 'scalingFactor' | 'level' | 'name' | 'rank' | 'min' | 'max'>
        )>> }
      )>> }
    ) }
  ) }
);

export type AssessmentCycleWithWordCloudVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentCycleWithWordCloud = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id'>
    & { wordCloud: Array<(
      { __typename?: 'WordCloudItem' }
      & Pick<WordCloudItem, 'text' | 'value'>
    )> }
  ) }
);

export type AllAssessmentCyclesVariables = Exact<{ [key: string]: never; }>;


export type AllAssessmentCycles = (
  { __typename?: 'Query' }
  & { assessmentCycles: Array<(
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id' | 'startDate' | 'completedAt'>
    & { participationResults: (
      { __typename?: 'ParticipationResults' }
      & Pick<ParticipationResults, 'totalEmployeeCount' | 'completedEmployeeCount' | 'completionRate'>
    ), reminders: Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'id' | 'createdAt' | 'creatorId' | 'sentAt' | 'content' | 'scheduledAt'>
    )> }
  )> }
);

export type AssessmentCyclesWithDateVariables = Exact<{ [key: string]: never; }>;


export type AssessmentCyclesWithDate = (
  { __typename?: 'Query' }
  & { assessmentCycles: Array<(
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id' | 'createdAt' | 'startDate' | 'completedAt'>
    & { assessments: Array<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id' | 'status'>
    )> }
  )> }
);

export type AssessmentCyclesWithQuadrantScoresVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssessmentCyclesWithQuadrantScores = (
  { __typename?: 'Query' }
  & { assessmentCycle: (
    { __typename?: 'AssessmentCycle' }
    & Pick<AssessmentCycle, 'id'>
    & { quadrantScores: Array<(
      { __typename?: 'QuadrantScore' }
      & Pick<QuadrantScore, 'scoreId' | 'mean' | 'stdev'>
    )> }
  ) }
);

export type DemographicsDataVariables = Exact<{
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
}>;


export type DemographicsData = (
  { __typename?: 'Query' }
  & { demographics: Array<(
    { __typename?: 'DemographicAnswer' }
    & Pick<DemographicAnswer, 'questionId'>
    & { answersWithCount: Array<(
      { __typename?: 'AnswerWithCount' }
      & Pick<AnswerWithCount, 'rawResponse' | 'count'>
    )> }
  )> }
);

export type OrganisationEmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationEmployeesQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'PagedUsersResponse' }
    & { results: Array<(
      { __typename?: 'UserModel' }
      & Pick<UserModel, 'firebaseId' | 'firstName' | 'emailAddress' | 'contactNumber' | 'attributes'>
    )> }
  ) }
);

export type OceanScoresVariables = Exact<{
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
}>;


export type OceanScores = (
  { __typename?: 'Query' }
  & { oceanScores: (
    { __typename?: 'OceanScoresResponse' }
    & { oceanScores?: Maybe<Array<(
      { __typename?: 'OceanScore' }
      & Pick<OceanScore, 'scoreId' | 'mean' | 'min' | 'max'>
    )>> }
  ) }
);

export type OceanScores2Variables = Exact<{
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
}>;


export type OceanScores2 = (
  { __typename?: 'Query' }
  & { oceanScores2: (
    { __typename?: 'OceanScoresResponse2' }
    & Pick<OceanScoresResponse2, 'traitsCount'>
    & { summedData: Array<(
      { __typename?: 'OceanDataType' }
      & Pick<OceanDataType, 'scoreId' | 'symbol' | 'high' | 'low'>
    )> }
  ) }
);

export type OrganisationQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type OrganisationQuery = (
  { __typename?: 'Query' }
  & { organisation: (
    { __typename?: 'OrganisationResponse' }
    & { organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & { assessmentCycles: Array<(
        { __typename?: 'AssessmentCycle' }
        & Pick<AssessmentCycle, 'id' | 'organisationId'>
      )>, organisationUnits: Array<(
        { __typename?: 'OrganisationUnit' }
        & Pick<OrganisationUnit, 'attributeName'>
        & { units: Array<(
          { __typename?: 'OrganisationUnitModel' }
          & Pick<OrganisationUnitModel, 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type ParticipationPerUnitsVariables = Exact<{
  assessmentCycleId: Scalars['ID'];
  organisationUnitFilter?: Maybe<Array<OrganisationUnitFilterInput>>;
  fullCompany?: Maybe<Scalars['Boolean']>;
}>;


export type ParticipationPerUnits = (
  { __typename?: 'Query' }
  & { participationPerUnits: Array<(
    { __typename?: 'ParticipationPerOrganisationUnit' }
    & Pick<ParticipationPerOrganisationUnit, 'attributeName' | 'unit' | 'totalEmployeeCount' | 'completedEmployeeCount' | 'startedEmployeeCount'>
  )> }
);

export type EmployeeReportVariables = Exact<{
  id: Scalars['String'];
}>;


export type EmployeeReport = (
  { __typename?: 'Query' }
  & { employeeReport: (
    { __typename?: 'ReportModel' }
    & Pick<ReportModel, 'downloadUrl'>
  ) }
);

export type EmployerReportVariables = Exact<{
  options: EmployerReportInput;
}>;


export type EmployerReport = (
  { __typename?: 'Query' }
  & { employerReport: (
    { __typename?: 'ReportModel' }
    & Pick<ReportModel, 'downloadUrl'>
  ) }
);

export type EmployerScorecardVariables = Exact<{
  options: EmployerScorecardInput;
}>;


export type EmployerScorecard = (
  { __typename?: 'Query' }
  & { employerScorecard?: Maybe<(
    { __typename?: 'ScorecardModel' }
    & Pick<ScorecardModel, 'downloadUrl'>
  )> }
);

export type SelfVariables = Exact<{ [key: string]: never; }>;


export type Self = (
  { __typename?: 'Query' }
  & { self: (
    { __typename?: 'UserModel' }
    & Pick<UserModel, 'id' | 'firstName' | 'surname' | 'title' | 'contactNumber' | 'email' | 'firebaseId' | 'picture' | 'completedSetup' | 'organisationId' | 'acceptedGdprAt' | 'attributes'>
    & { organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name' | 'contactNumber' | 'uid' | 'companyContactNumber' | 'financeName' | 'financeEmail' | 'typeOfBusiness' | 'addressLine1' | 'logoUrl' | 'addressLine2' | 'addressLine3' | 'postcode' | 'completedSetup' | 'hasMFA'>
    )> }
  ) }
);

export type IsUserInOrganisationWithMfaVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type IsUserInOrganisationWithMfa = (
  { __typename?: 'Query' }
  & Pick<Query, 'isUserInOrganisationWithMFA'>
);


export const AnswerBenefitDocument = gql`
    mutation AnswerBenefit($answerBenefitInput: AnswerBenefitInput!) {
  answerBenefit(answerBenefitInput: $answerBenefitInput) {
    id
    response
  }
}
    `;
export type AnswerBenefitMutationFn = Apollo.MutationFunction<AnswerBenefit, AnswerBenefitVariables>;

/**
 * __useAnswerBenefit__
 *
 * To run a mutation, you first call `useAnswerBenefit` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerBenefit` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerBenefit, { data, loading, error }] = useAnswerBenefit({
 *   variables: {
 *      answerBenefitInput: // value for 'answerBenefitInput'
 *   },
 * });
 */
export function useAnswerBenefit(baseOptions?: Apollo.MutationHookOptions<AnswerBenefit, AnswerBenefitVariables>) {
        return Apollo.useMutation<AnswerBenefit, AnswerBenefitVariables>(AnswerBenefitDocument, baseOptions);
      }
export type AnswerBenefitHookResult = ReturnType<typeof useAnswerBenefit>;
export type AnswerBenefitMutationResult = Apollo.MutationResult<AnswerBenefit>;
export type AnswerBenefitMutationOptions = Apollo.BaseMutationOptions<AnswerBenefit, AnswerBenefitVariables>;
export const AddBenefitGroupDocument = gql`
    mutation AddBenefitGroup($getBenefitsByGroupInput: GetBenefitsByGroupInput!) {
  addBenefitGroup(getBenefitsByGroupInput: $getBenefitsByGroupInput) {
    id
  }
}
    `;
export type AddBenefitGroupMutationFn = Apollo.MutationFunction<AddBenefitGroup, AddBenefitGroupVariables>;

/**
 * __useAddBenefitGroup__
 *
 * To run a mutation, you first call `useAddBenefitGroup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBenefitGroup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBenefitGroup, { data, loading, error }] = useAddBenefitGroup({
 *   variables: {
 *      getBenefitsByGroupInput: // value for 'getBenefitsByGroupInput'
 *   },
 * });
 */
export function useAddBenefitGroup(baseOptions?: Apollo.MutationHookOptions<AddBenefitGroup, AddBenefitGroupVariables>) {
        return Apollo.useMutation<AddBenefitGroup, AddBenefitGroupVariables>(AddBenefitGroupDocument, baseOptions);
      }
export type AddBenefitGroupHookResult = ReturnType<typeof useAddBenefitGroup>;
export type AddBenefitGroupMutationResult = Apollo.MutationResult<AddBenefitGroup>;
export type AddBenefitGroupMutationOptions = Apollo.BaseMutationOptions<AddBenefitGroup, AddBenefitGroupVariables>;
export const RemoveBenefitGroupDocument = gql`
    mutation RemoveBenefitGroup($getBenefitsByGroupInput: GetBenefitsByGroupInput!) {
  removeBenefitGroup(getBenefitsByGroupInput: $getBenefitsByGroupInput)
}
    `;
export type RemoveBenefitGroupMutationFn = Apollo.MutationFunction<RemoveBenefitGroup, RemoveBenefitGroupVariables>;

/**
 * __useRemoveBenefitGroup__
 *
 * To run a mutation, you first call `useRemoveBenefitGroup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBenefitGroup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBenefitGroup, { data, loading, error }] = useRemoveBenefitGroup({
 *   variables: {
 *      getBenefitsByGroupInput: // value for 'getBenefitsByGroupInput'
 *   },
 * });
 */
export function useRemoveBenefitGroup(baseOptions?: Apollo.MutationHookOptions<RemoveBenefitGroup, RemoveBenefitGroupVariables>) {
        return Apollo.useMutation<RemoveBenefitGroup, RemoveBenefitGroupVariables>(RemoveBenefitGroupDocument, baseOptions);
      }
export type RemoveBenefitGroupHookResult = ReturnType<typeof useRemoveBenefitGroup>;
export type RemoveBenefitGroupMutationResult = Apollo.MutationResult<RemoveBenefitGroup>;
export type RemoveBenefitGroupMutationOptions = Apollo.BaseMutationOptions<RemoveBenefitGroup, RemoveBenefitGroupVariables>;
export const RenameBenefitGroupDocument = gql`
    mutation RenameBenefitGroup($renameBenefitGroupInput: RenameBenefitGroupInput!) {
  renameBenefitGroup(renameBenefitGroupInput: $renameBenefitGroupInput)
}
    `;
export type RenameBenefitGroupMutationFn = Apollo.MutationFunction<RenameBenefitGroup, RenameBenefitGroupVariables>;

/**
 * __useRenameBenefitGroup__
 *
 * To run a mutation, you first call `useRenameBenefitGroup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameBenefitGroup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameBenefitGroup, { data, loading, error }] = useRenameBenefitGroup({
 *   variables: {
 *      renameBenefitGroupInput: // value for 'renameBenefitGroupInput'
 *   },
 * });
 */
export function useRenameBenefitGroup(baseOptions?: Apollo.MutationHookOptions<RenameBenefitGroup, RenameBenefitGroupVariables>) {
        return Apollo.useMutation<RenameBenefitGroup, RenameBenefitGroupVariables>(RenameBenefitGroupDocument, baseOptions);
      }
export type RenameBenefitGroupHookResult = ReturnType<typeof useRenameBenefitGroup>;
export type RenameBenefitGroupMutationResult = Apollo.MutationResult<RenameBenefitGroup>;
export type RenameBenefitGroupMutationOptions = Apollo.BaseMutationOptions<RenameBenefitGroup, RenameBenefitGroupVariables>;
export const ChangeOrganisationIdDocument = gql`
    mutation changeOrganisationId($organisationId: ID!) {
  changeOrganisationId(organisationId: $organisationId) {
    organisationId
  }
}
    `;
export type ChangeOrganisationIdMutationFn = Apollo.MutationFunction<ChangeOrganisationId, ChangeOrganisationIdVariables>;

/**
 * __useChangeOrganisationId__
 *
 * To run a mutation, you first call `useChangeOrganisationId` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrganisationId` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrganisationId, { data, loading, error }] = useChangeOrganisationId({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useChangeOrganisationId(baseOptions?: Apollo.MutationHookOptions<ChangeOrganisationId, ChangeOrganisationIdVariables>) {
        return Apollo.useMutation<ChangeOrganisationId, ChangeOrganisationIdVariables>(ChangeOrganisationIdDocument, baseOptions);
      }
export type ChangeOrganisationIdHookResult = ReturnType<typeof useChangeOrganisationId>;
export type ChangeOrganisationIdMutationResult = Apollo.MutationResult<ChangeOrganisationId>;
export type ChangeOrganisationIdMutationOptions = Apollo.BaseMutationOptions<ChangeOrganisationId, ChangeOrganisationIdVariables>;
export const CreateOrganisationAdminDocument = gql`
    mutation createOrganisationAdmin($createOrganisationAdminInput: CreateOrganisationAdminInput!) {
  createOrganisationAdmin(
    createOrganisationAdminInput: $createOrganisationAdminInput
  ) {
    idToken
    user {
      id
      firstName
      surname
      firebaseId
    }
  }
}
    `;
export type CreateOrganisationAdminMutationFn = Apollo.MutationFunction<CreateOrganisationAdmin, CreateOrganisationAdminVariables>;

/**
 * __useCreateOrganisationAdmin__
 *
 * To run a mutation, you first call `useCreateOrganisationAdmin` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationAdmin` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationAdmin, { data, loading, error }] = useCreateOrganisationAdmin({
 *   variables: {
 *      createOrganisationAdminInput: // value for 'createOrganisationAdminInput'
 *   },
 * });
 */
export function useCreateOrganisationAdmin(baseOptions?: Apollo.MutationHookOptions<CreateOrganisationAdmin, CreateOrganisationAdminVariables>) {
        return Apollo.useMutation<CreateOrganisationAdmin, CreateOrganisationAdminVariables>(CreateOrganisationAdminDocument, baseOptions);
      }
export type CreateOrganisationAdminHookResult = ReturnType<typeof useCreateOrganisationAdmin>;
export type CreateOrganisationAdminMutationResult = Apollo.MutationResult<CreateOrganisationAdmin>;
export type CreateOrganisationAdminMutationOptions = Apollo.BaseMutationOptions<CreateOrganisationAdmin, CreateOrganisationAdminVariables>;
export const CreateOrganisationDocument = gql`
    mutation createOrganisation($createOrganisationInput: CreateOrganisationInput!) {
  createOrganisation(createOrganisationInput: $createOrganisationInput) {
    id
    name
    contactNumber
    assessmentCycles {
      id
      startDate
      participationResults {
        totalEmployeeCount
        completedEmployeeCount
        completionRate
      }
    }
    employeeCount
    createdAt
    userTest {
      id
      employeeCsv {
        url
      }
    }
  }
}
    `;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<CreateOrganisation, CreateOrganisationVariables>;

/**
 * __useCreateOrganisation__
 *
 * To run a mutation, you first call `useCreateOrganisation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisation, { data, loading, error }] = useCreateOrganisation({
 *   variables: {
 *      createOrganisationInput: // value for 'createOrganisationInput'
 *   },
 * });
 */
export function useCreateOrganisation(baseOptions?: Apollo.MutationHookOptions<CreateOrganisation, CreateOrganisationVariables>) {
        return Apollo.useMutation<CreateOrganisation, CreateOrganisationVariables>(CreateOrganisationDocument, baseOptions);
      }
export type CreateOrganisationHookResult = ReturnType<typeof useCreateOrganisation>;
export type CreateOrganisationMutationResult = Apollo.MutationResult<CreateOrganisation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateOrganisation, CreateOrganisationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation updateOrganisation($organisationId: ID!, $updateOrganisationInput: UpdateOrganisationInput!) {
  updateOrganisation(
    organisationId: $organisationId
    updateOrganisationInput: $updateOrganisationInput
  ) {
    id
  }
}
    `;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<UpdateOrganisation, UpdateOrganisationVariables>;

/**
 * __useUpdateOrganisation__
 *
 * To run a mutation, you first call `useUpdateOrganisation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisation, { data, loading, error }] = useUpdateOrganisation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      updateOrganisationInput: // value for 'updateOrganisationInput'
 *   },
 * });
 */
export function useUpdateOrganisation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisation, UpdateOrganisationVariables>) {
        return Apollo.useMutation<UpdateOrganisation, UpdateOrganisationVariables>(UpdateOrganisationDocument, baseOptions);
      }
export type UpdateOrganisationHookResult = ReturnType<typeof useUpdateOrganisation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisation, UpdateOrganisationVariables>;
export const AnswerEmployerQuestionDocument = gql`
    mutation answerEmployerQuestion($organisationId: ID!, $answerQuestionInput: EmployerAnswerQuestionInput!, $assessmentCycleId: ID) {
  answerEmployerQuestion(
    organisationId: $organisationId
    answerQuestionInput: $answerQuestionInput
    assessmentCycleId: $assessmentCycleId
  ) {
    employerAnswer {
      id
      questionId
      computedResponse
      response
      questionText
      assessmentCycleId
    }
  }
}
    `;
export type AnswerEmployerQuestionMutationFn = Apollo.MutationFunction<AnswerEmployerQuestion, AnswerEmployerQuestionVariables>;

/**
 * __useAnswerEmployerQuestion__
 *
 * To run a mutation, you first call `useAnswerEmployerQuestion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerEmployerQuestion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerEmployerQuestion, { data, loading, error }] = useAnswerEmployerQuestion({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      answerQuestionInput: // value for 'answerQuestionInput'
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *   },
 * });
 */
export function useAnswerEmployerQuestion(baseOptions?: Apollo.MutationHookOptions<AnswerEmployerQuestion, AnswerEmployerQuestionVariables>) {
        return Apollo.useMutation<AnswerEmployerQuestion, AnswerEmployerQuestionVariables>(AnswerEmployerQuestionDocument, baseOptions);
      }
export type AnswerEmployerQuestionHookResult = ReturnType<typeof useAnswerEmployerQuestion>;
export type AnswerEmployerQuestionMutationResult = Apollo.MutationResult<AnswerEmployerQuestion>;
export type AnswerEmployerQuestionMutationOptions = Apollo.BaseMutationOptions<AnswerEmployerQuestion, AnswerEmployerQuestionVariables>;
export const CompleteOrganisationSetupDocument = gql`
    mutation completeOrganisationSetup {
  completeOrganisationSetup {
    completedSetup
  }
}
    `;
export type CompleteOrganisationSetupMutationFn = Apollo.MutationFunction<CompleteOrganisationSetup, CompleteOrganisationSetupVariables>;

/**
 * __useCompleteOrganisationSetup__
 *
 * To run a mutation, you first call `useCompleteOrganisationSetup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOrganisationSetup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOrganisationSetup, { data, loading, error }] = useCompleteOrganisationSetup({
 *   variables: {
 *   },
 * });
 */
export function useCompleteOrganisationSetup(baseOptions?: Apollo.MutationHookOptions<CompleteOrganisationSetup, CompleteOrganisationSetupVariables>) {
        return Apollo.useMutation<CompleteOrganisationSetup, CompleteOrganisationSetupVariables>(CompleteOrganisationSetupDocument, baseOptions);
      }
export type CompleteOrganisationSetupHookResult = ReturnType<typeof useCompleteOrganisationSetup>;
export type CompleteOrganisationSetupMutationResult = Apollo.MutationResult<CompleteOrganisationSetup>;
export type CompleteOrganisationSetupMutationOptions = Apollo.BaseMutationOptions<CompleteOrganisationSetup, CompleteOrganisationSetupVariables>;
export const ReprocessAnswersDocument = gql`
    mutation reprocessAnswers($assessmentId: ID!) {
  reprocessAnswers(assessmentId: $assessmentId) {
    answer {
      id
      questionId
      rawResponse
      transformedResponse
      scalingFactor
    }
  }
}
    `;
export type ReprocessAnswersMutationFn = Apollo.MutationFunction<ReprocessAnswers, ReprocessAnswersVariables>;

/**
 * __useReprocessAnswers__
 *
 * To run a mutation, you first call `useReprocessAnswers` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReprocessAnswers` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reprocessAnswers, { data, loading, error }] = useReprocessAnswers({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useReprocessAnswers(baseOptions?: Apollo.MutationHookOptions<ReprocessAnswers, ReprocessAnswersVariables>) {
        return Apollo.useMutation<ReprocessAnswers, ReprocessAnswersVariables>(ReprocessAnswersDocument, baseOptions);
      }
export type ReprocessAnswersHookResult = ReturnType<typeof useReprocessAnswers>;
export type ReprocessAnswersMutationResult = Apollo.MutationResult<ReprocessAnswers>;
export type ReprocessAnswersMutationOptions = Apollo.BaseMutationOptions<ReprocessAnswers, ReprocessAnswersVariables>;
export const ResendOrgAdminInviteDocument = gql`
    mutation resendOrgAdminInvite($organisationId: Float!) {
  resendOrgAdminInvite(organisationId: $organisationId)
}
    `;
export type ResendOrgAdminInviteMutationFn = Apollo.MutationFunction<ResendOrgAdminInvite, ResendOrgAdminInviteVariables>;

/**
 * __useResendOrgAdminInvite__
 *
 * To run a mutation, you first call `useResendOrgAdminInvite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOrgAdminInvite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOrgAdminInvite, { data, loading, error }] = useResendOrgAdminInvite({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useResendOrgAdminInvite(baseOptions?: Apollo.MutationHookOptions<ResendOrgAdminInvite, ResendOrgAdminInviteVariables>) {
        return Apollo.useMutation<ResendOrgAdminInvite, ResendOrgAdminInviteVariables>(ResendOrgAdminInviteDocument, baseOptions);
      }
export type ResendOrgAdminInviteHookResult = ReturnType<typeof useResendOrgAdminInvite>;
export type ResendOrgAdminInviteMutationResult = Apollo.MutationResult<ResendOrgAdminInvite>;
export type ResendOrgAdminInviteMutationOptions = Apollo.BaseMutationOptions<ResendOrgAdminInvite, ResendOrgAdminInviteVariables>;
export const UpdateRolesAndPermissionDocument = gql`
    mutation updateRolesAndPermission($options: UpdateRolesAndPermissionsInput!) {
  updateRolesAndPermissions(options: $options) {
    id
    roles
    permissions {
      reportViewerPermissions {
        allowedOrganisationUnits {
          name
          units {
            name
          }
        }
      }
    }
  }
}
    `;
export type UpdateRolesAndPermissionMutationFn = Apollo.MutationFunction<UpdateRolesAndPermission, UpdateRolesAndPermissionVariables>;

/**
 * __useUpdateRolesAndPermission__
 *
 * To run a mutation, you first call `useUpdateRolesAndPermission` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolesAndPermission` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolesAndPermission, { data, loading, error }] = useUpdateRolesAndPermission({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateRolesAndPermission(baseOptions?: Apollo.MutationHookOptions<UpdateRolesAndPermission, UpdateRolesAndPermissionVariables>) {
        return Apollo.useMutation<UpdateRolesAndPermission, UpdateRolesAndPermissionVariables>(UpdateRolesAndPermissionDocument, baseOptions);
      }
export type UpdateRolesAndPermissionHookResult = ReturnType<typeof useUpdateRolesAndPermission>;
export type UpdateRolesAndPermissionMutationResult = Apollo.MutationResult<UpdateRolesAndPermission>;
export type UpdateRolesAndPermissionMutationOptions = Apollo.BaseMutationOptions<UpdateRolesAndPermission, UpdateRolesAndPermissionVariables>;
export const AddBenefitDocument = gql`
    mutation addBenefit($addBenefitInput: AddBenefitInput!, $benefitImg: Upload) {
  addBenefit(addBenefitInput: $addBenefitInput, benefitImg: $benefitImg) {
    error
    benefit {
      title
      questionId
    }
  }
}
    `;
export type AddBenefitMutationFn = Apollo.MutationFunction<AddBenefit, AddBenefitVariables>;

/**
 * __useAddBenefit__
 *
 * To run a mutation, you first call `useAddBenefit` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBenefit` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBenefit, { data, loading, error }] = useAddBenefit({
 *   variables: {
 *      addBenefitInput: // value for 'addBenefitInput'
 *      benefitImg: // value for 'benefitImg'
 *   },
 * });
 */
export function useAddBenefit(baseOptions?: Apollo.MutationHookOptions<AddBenefit, AddBenefitVariables>) {
        return Apollo.useMutation<AddBenefit, AddBenefitVariables>(AddBenefitDocument, baseOptions);
      }
export type AddBenefitHookResult = ReturnType<typeof useAddBenefit>;
export type AddBenefitMutationResult = Apollo.MutationResult<AddBenefit>;
export type AddBenefitMutationOptions = Apollo.BaseMutationOptions<AddBenefit, AddBenefitVariables>;
export const EditBenefitDocument = gql`
    mutation editBenefit($editBenefitInput: EditBenefitInput!, $benefitImg: Upload) {
  editBenefit(editBenefitInput: $editBenefitInput, benefitImg: $benefitImg) {
    error
    benefit {
      title
      details
      link
      image
    }
  }
}
    `;
export type EditBenefitMutationFn = Apollo.MutationFunction<EditBenefit, EditBenefitVariables>;

/**
 * __useEditBenefit__
 *
 * To run a mutation, you first call `useEditBenefit` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBenefit` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBenefit, { data, loading, error }] = useEditBenefit({
 *   variables: {
 *      editBenefitInput: // value for 'editBenefitInput'
 *      benefitImg: // value for 'benefitImg'
 *   },
 * });
 */
export function useEditBenefit(baseOptions?: Apollo.MutationHookOptions<EditBenefit, EditBenefitVariables>) {
        return Apollo.useMutation<EditBenefit, EditBenefitVariables>(EditBenefitDocument, baseOptions);
      }
export type EditBenefitHookResult = ReturnType<typeof useEditBenefit>;
export type EditBenefitMutationResult = Apollo.MutationResult<EditBenefit>;
export type EditBenefitMutationOptions = Apollo.BaseMutationOptions<EditBenefit, EditBenefitVariables>;
export const UploadCompanyPictureDocument = gql`
    mutation uploadCompanyPicture($organisationId: ID!, $file: Upload!) {
  uploadCompanyPicture(organisationId: $organisationId, file: $file) {
    organisation {
      id
      logoUrl
      name
    }
  }
}
    `;
export type UploadCompanyPictureMutationFn = Apollo.MutationFunction<UploadCompanyPicture, UploadCompanyPictureVariables>;

/**
 * __useUploadCompanyPicture__
 *
 * To run a mutation, you first call `useUploadCompanyPicture` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyPicture` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyPicture, { data, loading, error }] = useUploadCompanyPicture({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCompanyPicture(baseOptions?: Apollo.MutationHookOptions<UploadCompanyPicture, UploadCompanyPictureVariables>) {
        return Apollo.useMutation<UploadCompanyPicture, UploadCompanyPictureVariables>(UploadCompanyPictureDocument, baseOptions);
      }
export type UploadCompanyPictureHookResult = ReturnType<typeof useUploadCompanyPicture>;
export type UploadCompanyPictureMutationResult = Apollo.MutationResult<UploadCompanyPicture>;
export type UploadCompanyPictureMutationOptions = Apollo.BaseMutationOptions<UploadCompanyPicture, UploadCompanyPictureVariables>;
export const GetOrganisationAccessRequestUrlDocument = gql`
    query GetOrganisationAccessRequestURL {
  getOrganisationAccessRequestURL
}
    `;

/**
 * __useGetOrganisationAccessRequestUrl__
 *
 * To run a query within a React component, call `useGetOrganisationAccessRequestUrl` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationAccessRequestUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationAccessRequestUrl({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationAccessRequestUrl(baseOptions?: Apollo.QueryHookOptions<GetOrganisationAccessRequestUrl, GetOrganisationAccessRequestUrlVariables>) {
        return Apollo.useQuery<GetOrganisationAccessRequestUrl, GetOrganisationAccessRequestUrlVariables>(GetOrganisationAccessRequestUrlDocument, baseOptions);
      }
export function useGetOrganisationAccessRequestUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationAccessRequestUrl, GetOrganisationAccessRequestUrlVariables>) {
          return Apollo.useLazyQuery<GetOrganisationAccessRequestUrl, GetOrganisationAccessRequestUrlVariables>(GetOrganisationAccessRequestUrlDocument, baseOptions);
        }
export type GetOrganisationAccessRequestUrlHookResult = ReturnType<typeof useGetOrganisationAccessRequestUrl>;
export type GetOrganisationAccessRequestUrlLazyQueryHookResult = ReturnType<typeof useGetOrganisationAccessRequestUrlLazyQuery>;
export type GetOrganisationAccessRequestUrlQueryResult = Apollo.QueryResult<GetOrganisationAccessRequestUrl, GetOrganisationAccessRequestUrlVariables>;
export const GetPagedIntermediateUsersDocument = gql`
    query getPagedIntermediateUsers($options: PagedQueryInput) {
  getPagedIntermediateUsers(options: $options) {
    totalCount
    pageSize
    results {
      id
      firstName
      department
      contactNumber
      emailAddress
      verifiedAt
    }
  }
}
    `;

/**
 * __useGetPagedIntermediateUsers__
 *
 * To run a query within a React component, call `useGetPagedIntermediateUsers` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedIntermediateUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedIntermediateUsers({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetPagedIntermediateUsers(baseOptions?: Apollo.QueryHookOptions<GetPagedIntermediateUsers, GetPagedIntermediateUsersVariables>) {
        return Apollo.useQuery<GetPagedIntermediateUsers, GetPagedIntermediateUsersVariables>(GetPagedIntermediateUsersDocument, baseOptions);
      }
export function useGetPagedIntermediateUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedIntermediateUsers, GetPagedIntermediateUsersVariables>) {
          return Apollo.useLazyQuery<GetPagedIntermediateUsers, GetPagedIntermediateUsersVariables>(GetPagedIntermediateUsersDocument, baseOptions);
        }
export type GetPagedIntermediateUsersHookResult = ReturnType<typeof useGetPagedIntermediateUsers>;
export type GetPagedIntermediateUsersLazyQueryHookResult = ReturnType<typeof useGetPagedIntermediateUsersLazyQuery>;
export type GetPagedIntermediateUsersQueryResult = Apollo.QueryResult<GetPagedIntermediateUsers, GetPagedIntermediateUsersVariables>;
export const GetVerifiedIntermediateUsersDocument = gql`
    query getVerifiedIntermediateUsers {
  getVerifiedIntermediateUsers {
    id
    firstName
    department
    contactNumber
    emailAddress
    verifiedAt
  }
}
    `;

/**
 * __useGetVerifiedIntermediateUsers__
 *
 * To run a query within a React component, call `useGetVerifiedIntermediateUsers` and pass it any options that fit your needs.
 * When your component renders, `useGetVerifiedIntermediateUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVerifiedIntermediateUsers({
 *   variables: {
 *   },
 * });
 */
export function useGetVerifiedIntermediateUsers(baseOptions?: Apollo.QueryHookOptions<GetVerifiedIntermediateUsers, GetVerifiedIntermediateUsersVariables>) {
        return Apollo.useQuery<GetVerifiedIntermediateUsers, GetVerifiedIntermediateUsersVariables>(GetVerifiedIntermediateUsersDocument, baseOptions);
      }
export function useGetVerifiedIntermediateUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVerifiedIntermediateUsers, GetVerifiedIntermediateUsersVariables>) {
          return Apollo.useLazyQuery<GetVerifiedIntermediateUsers, GetVerifiedIntermediateUsersVariables>(GetVerifiedIntermediateUsersDocument, baseOptions);
        }
export type GetVerifiedIntermediateUsersHookResult = ReturnType<typeof useGetVerifiedIntermediateUsers>;
export type GetVerifiedIntermediateUsersLazyQueryHookResult = ReturnType<typeof useGetVerifiedIntermediateUsersLazyQuery>;
export type GetVerifiedIntermediateUsersQueryResult = Apollo.QueryResult<GetVerifiedIntermediateUsers, GetVerifiedIntermediateUsersVariables>;
export const AssessmentSchemaWithComputationsQueryDocument = gql`
    query assessmentSchemaWithComputationsQuery($id: ID!) {
  assessmentSchema(id: $id) {
    error
    assessmentSchema {
      schema {
        employeeComputationSchema
      }
    }
  }
}
    `;

/**
 * __useAssessmentSchemaWithComputationsQuery__
 *
 * To run a query within a React component, call `useAssessmentSchemaWithComputationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentSchemaWithComputationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentSchemaWithComputationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentSchemaWithComputationsQuery(baseOptions: Apollo.QueryHookOptions<AssessmentSchemaWithComputationsQuery, AssessmentSchemaWithComputationsQueryVariables>) {
        return Apollo.useQuery<AssessmentSchemaWithComputationsQuery, AssessmentSchemaWithComputationsQueryVariables>(AssessmentSchemaWithComputationsQueryDocument, baseOptions);
      }
export function useAssessmentSchemaWithComputationsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentSchemaWithComputationsQuery, AssessmentSchemaWithComputationsQueryVariables>) {
          return Apollo.useLazyQuery<AssessmentSchemaWithComputationsQuery, AssessmentSchemaWithComputationsQueryVariables>(AssessmentSchemaWithComputationsQueryDocument, baseOptions);
        }
export type AssessmentSchemaWithComputationsQueryHookResult = ReturnType<typeof useAssessmentSchemaWithComputationsQuery>;
export type AssessmentSchemaWithComputationsQueryLazyQueryHookResult = ReturnType<typeof useAssessmentSchemaWithComputationsQueryLazyQuery>;
export type AssessmentSchemaWithComputationsQueryQueryResult = Apollo.QueryResult<AssessmentSchemaWithComputationsQuery, AssessmentSchemaWithComputationsQueryVariables>;
export const AssessmentsQueryDocument = gql`
    query AssessmentsQuery($options: GetAssessmentsQueryInput!) {
  assessments(options: $options) {
    results {
      id
      user {
        firebaseId
      }
      organisation {
        name
      }
      status
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useAssessmentsQuery__
 *
 * To run a query within a React component, call `useAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAssessmentsQuery(baseOptions: Apollo.QueryHookOptions<AssessmentsQuery, AssessmentsQueryVariables>) {
        return Apollo.useQuery<AssessmentsQuery, AssessmentsQueryVariables>(AssessmentsQueryDocument, baseOptions);
      }
export function useAssessmentsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentsQuery, AssessmentsQueryVariables>) {
          return Apollo.useLazyQuery<AssessmentsQuery, AssessmentsQueryVariables>(AssessmentsQueryDocument, baseOptions);
        }
export type AssessmentsQueryHookResult = ReturnType<typeof useAssessmentsQuery>;
export type AssessmentsQueryLazyQueryHookResult = ReturnType<typeof useAssessmentsQueryLazyQuery>;
export type AssessmentsQueryQueryResult = Apollo.QueryResult<AssessmentsQuery, AssessmentsQueryVariables>;
export const AssessmentWithComputationSchemaQueryDocument = gql`
    query AssessmentWithComputationSchemaQuery($id: ID!) {
  assessment(id: $id) {
    assessment {
      id
      status
      organisationId
      answers {
        id
        questionId
        rawResponse
        transformedResponse
        scalingFactor
      }
      scores {
        id
        scoreId
        name
        category
        rank
        value
        score
        scalingFactor
        isModifiable
        oceanAttributes {
          deviation
          deviationValue
        }
        subScores {
          id
          scoreId
          value
          score
          scalingFactor
        }
      }
      assessmentCycleId
      assessmentCycle {
        assessmentSchema {
          version
          schema {
            employeeComputationSchema
            scalingFactorSchema
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAssessmentWithComputationSchemaQuery__
 *
 * To run a query within a React component, call `useAssessmentWithComputationSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentWithComputationSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentWithComputationSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentWithComputationSchemaQuery(baseOptions: Apollo.QueryHookOptions<AssessmentWithComputationSchemaQuery, AssessmentWithComputationSchemaQueryVariables>) {
        return Apollo.useQuery<AssessmentWithComputationSchemaQuery, AssessmentWithComputationSchemaQueryVariables>(AssessmentWithComputationSchemaQueryDocument, baseOptions);
      }
export function useAssessmentWithComputationSchemaQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentWithComputationSchemaQuery, AssessmentWithComputationSchemaQueryVariables>) {
          return Apollo.useLazyQuery<AssessmentWithComputationSchemaQuery, AssessmentWithComputationSchemaQueryVariables>(AssessmentWithComputationSchemaQueryDocument, baseOptions);
        }
export type AssessmentWithComputationSchemaQueryHookResult = ReturnType<typeof useAssessmentWithComputationSchemaQuery>;
export type AssessmentWithComputationSchemaQueryLazyQueryHookResult = ReturnType<typeof useAssessmentWithComputationSchemaQueryLazyQuery>;
export type AssessmentWithComputationSchemaQueryQueryResult = Apollo.QueryResult<AssessmentWithComputationSchemaQuery, AssessmentWithComputationSchemaQueryVariables>;
export const CurrentAssessmentCycleIdDocument = gql`
    query CurrentAssessmentCycleId {
  currentAssessmentCycleId
}
    `;

/**
 * __useCurrentAssessmentCycleId__
 *
 * To run a query within a React component, call `useCurrentAssessmentCycleId` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAssessmentCycleId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAssessmentCycleId({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAssessmentCycleId(baseOptions?: Apollo.QueryHookOptions<CurrentAssessmentCycleId, CurrentAssessmentCycleIdVariables>) {
        return Apollo.useQuery<CurrentAssessmentCycleId, CurrentAssessmentCycleIdVariables>(CurrentAssessmentCycleIdDocument, baseOptions);
      }
export function useCurrentAssessmentCycleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAssessmentCycleId, CurrentAssessmentCycleIdVariables>) {
          return Apollo.useLazyQuery<CurrentAssessmentCycleId, CurrentAssessmentCycleIdVariables>(CurrentAssessmentCycleIdDocument, baseOptions);
        }
export type CurrentAssessmentCycleIdHookResult = ReturnType<typeof useCurrentAssessmentCycleId>;
export type CurrentAssessmentCycleIdLazyQueryHookResult = ReturnType<typeof useCurrentAssessmentCycleIdLazyQuery>;
export type CurrentAssessmentCycleIdQueryResult = Apollo.QueryResult<CurrentAssessmentCycleId, CurrentAssessmentCycleIdVariables>;
export const GetBenefitDocument = gql`
    query getBenefit($getBenefitInput: GetBenefitInput!) {
  getBenefit(getBenefitInput: $getBenefitInput) {
    error
    benefit {
      title
      details
      link
      image
    }
  }
}
    `;

/**
 * __useGetBenefit__
 *
 * To run a query within a React component, call `useGetBenefit` and pass it any options that fit your needs.
 * When your component renders, `useGetBenefit` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBenefit({
 *   variables: {
 *      getBenefitInput: // value for 'getBenefitInput'
 *   },
 * });
 */
export function useGetBenefit(baseOptions: Apollo.QueryHookOptions<GetBenefit, GetBenefitVariables>) {
        return Apollo.useQuery<GetBenefit, GetBenefitVariables>(GetBenefitDocument, baseOptions);
      }
export function useGetBenefitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBenefit, GetBenefitVariables>) {
          return Apollo.useLazyQuery<GetBenefit, GetBenefitVariables>(GetBenefitDocument, baseOptions);
        }
export type GetBenefitHookResult = ReturnType<typeof useGetBenefit>;
export type GetBenefitLazyQueryHookResult = ReturnType<typeof useGetBenefitLazyQuery>;
export type GetBenefitQueryResult = Apollo.QueryResult<GetBenefit, GetBenefitVariables>;
export const GetStoryblokBenefitsDocument = gql`
    query getStoryblokBenefits($getBenefitsInput: GetBenefitsInput!) {
  getStoryblokBenefits(getBenefitsInput: $getBenefitsInput) {
    error
    benefits {
      slug
      title
      details
      link
      image
    }
  }
}
    `;

/**
 * __useGetStoryblokBenefits__
 *
 * To run a query within a React component, call `useGetStoryblokBenefits` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryblokBenefits` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryblokBenefits({
 *   variables: {
 *      getBenefitsInput: // value for 'getBenefitsInput'
 *   },
 * });
 */
export function useGetStoryblokBenefits(baseOptions: Apollo.QueryHookOptions<GetStoryblokBenefits, GetStoryblokBenefitsVariables>) {
        return Apollo.useQuery<GetStoryblokBenefits, GetStoryblokBenefitsVariables>(GetStoryblokBenefitsDocument, baseOptions);
      }
export function useGetStoryblokBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoryblokBenefits, GetStoryblokBenefitsVariables>) {
          return Apollo.useLazyQuery<GetStoryblokBenefits, GetStoryblokBenefitsVariables>(GetStoryblokBenefitsDocument, baseOptions);
        }
export type GetStoryblokBenefitsHookResult = ReturnType<typeof useGetStoryblokBenefits>;
export type GetStoryblokBenefitsLazyQueryHookResult = ReturnType<typeof useGetStoryblokBenefitsLazyQuery>;
export type GetStoryblokBenefitsQueryResult = Apollo.QueryResult<GetStoryblokBenefits, GetStoryblokBenefitsVariables>;
export const GetBenefitsByGroupDocument = gql`
    query getBenefitsByGroup($getBenefitsByGroupInput: GetBenefitsByGroupInput!) {
  getBenefitsByGroup(getBenefitsByGroupInput: $getBenefitsByGroupInput) {
    id
    groupName
    benefitStoryblokId
    isCustom
    response
    computedResponse
  }
}
    `;

/**
 * __useGetBenefitsByGroup__
 *
 * To run a query within a React component, call `useGetBenefitsByGroup` and pass it any options that fit your needs.
 * When your component renders, `useGetBenefitsByGroup` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBenefitsByGroup({
 *   variables: {
 *      getBenefitsByGroupInput: // value for 'getBenefitsByGroupInput'
 *   },
 * });
 */
export function useGetBenefitsByGroup(baseOptions: Apollo.QueryHookOptions<GetBenefitsByGroup, GetBenefitsByGroupVariables>) {
        return Apollo.useQuery<GetBenefitsByGroup, GetBenefitsByGroupVariables>(GetBenefitsByGroupDocument, baseOptions);
      }
export function useGetBenefitsByGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBenefitsByGroup, GetBenefitsByGroupVariables>) {
          return Apollo.useLazyQuery<GetBenefitsByGroup, GetBenefitsByGroupVariables>(GetBenefitsByGroupDocument, baseOptions);
        }
export type GetBenefitsByGroupHookResult = ReturnType<typeof useGetBenefitsByGroup>;
export type GetBenefitsByGroupLazyQueryHookResult = ReturnType<typeof useGetBenefitsByGroupLazyQuery>;
export type GetBenefitsByGroupQueryResult = Apollo.QueryResult<GetBenefitsByGroup, GetBenefitsByGroupVariables>;
export const GetBenefitGroupNamesDocument = gql`
    query getBenefitGroupNames($getBenefitsInput: GetBenefitsInput!) {
  getBenefitGroupNames(getBenefitsInput: $getBenefitsInput)
}
    `;

/**
 * __useGetBenefitGroupNames__
 *
 * To run a query within a React component, call `useGetBenefitGroupNames` and pass it any options that fit your needs.
 * When your component renders, `useGetBenefitGroupNames` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBenefitGroupNames({
 *   variables: {
 *      getBenefitsInput: // value for 'getBenefitsInput'
 *   },
 * });
 */
export function useGetBenefitGroupNames(baseOptions: Apollo.QueryHookOptions<GetBenefitGroupNames, GetBenefitGroupNamesVariables>) {
        return Apollo.useQuery<GetBenefitGroupNames, GetBenefitGroupNamesVariables>(GetBenefitGroupNamesDocument, baseOptions);
      }
export function useGetBenefitGroupNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBenefitGroupNames, GetBenefitGroupNamesVariables>) {
          return Apollo.useLazyQuery<GetBenefitGroupNames, GetBenefitGroupNamesVariables>(GetBenefitGroupNamesDocument, baseOptions);
        }
export type GetBenefitGroupNamesHookResult = ReturnType<typeof useGetBenefitGroupNames>;
export type GetBenefitGroupNamesLazyQueryHookResult = ReturnType<typeof useGetBenefitGroupNamesLazyQuery>;
export type GetBenefitGroupNamesQueryResult = Apollo.QueryResult<GetBenefitGroupNames, GetBenefitGroupNamesVariables>;
export const GetAllOrganisationBenefitsDocument = gql`
    query getAllOrganisationBenefits($getBenefitsInput: GetBenefitsInput!) {
  getAllOrganisationBenefits(getBenefitsInput: $getBenefitsInput) {
    id
    benefitStoryblokId
    groupName
    isCustom
    response
  }
}
    `;

/**
 * __useGetAllOrganisationBenefits__
 *
 * To run a query within a React component, call `useGetAllOrganisationBenefits` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganisationBenefits` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganisationBenefits({
 *   variables: {
 *      getBenefitsInput: // value for 'getBenefitsInput'
 *   },
 * });
 */
export function useGetAllOrganisationBenefits(baseOptions: Apollo.QueryHookOptions<GetAllOrganisationBenefits, GetAllOrganisationBenefitsVariables>) {
        return Apollo.useQuery<GetAllOrganisationBenefits, GetAllOrganisationBenefitsVariables>(GetAllOrganisationBenefitsDocument, baseOptions);
      }
export function useGetAllOrganisationBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrganisationBenefits, GetAllOrganisationBenefitsVariables>) {
          return Apollo.useLazyQuery<GetAllOrganisationBenefits, GetAllOrganisationBenefitsVariables>(GetAllOrganisationBenefitsDocument, baseOptions);
        }
export type GetAllOrganisationBenefitsHookResult = ReturnType<typeof useGetAllOrganisationBenefits>;
export type GetAllOrganisationBenefitsLazyQueryHookResult = ReturnType<typeof useGetAllOrganisationBenefitsLazyQuery>;
export type GetAllOrganisationBenefitsQueryResult = Apollo.QueryResult<GetAllOrganisationBenefits, GetAllOrganisationBenefitsVariables>;
export const OrganisationsDocument = gql`
    query Organisations($options: PagedQueryInput) {
  organisations(options: $options) {
    pageSize
    pageOffset
    totalCount
    results {
      id
      name
      assessmentCycles {
        id
        organisationId
        startDate
        participationResults {
          totalEmployeeCount
          completedEmployeeCount
          completionRate
        }
        employerAnswers {
          questionId
          computedResponse
        }
      }
      employeeCount
      createdAt
      userTest {
        id
        employeeCsv {
          url
        }
      }
    }
  }
}
    `;

/**
 * __useOrganisations__
 *
 * To run a query within a React component, call `useOrganisations` and pass it any options that fit your needs.
 * When your component renders, `useOrganisations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisations({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useOrganisations(baseOptions?: Apollo.QueryHookOptions<Organisations, OrganisationsVariables>) {
        return Apollo.useQuery<Organisations, OrganisationsVariables>(OrganisationsDocument, baseOptions);
      }
export function useOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Organisations, OrganisationsVariables>) {
          return Apollo.useLazyQuery<Organisations, OrganisationsVariables>(OrganisationsDocument, baseOptions);
        }
export type OrganisationsHookResult = ReturnType<typeof useOrganisations>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = Apollo.QueryResult<Organisations, OrganisationsVariables>;
export const EmployerAnswerSchemaQueryDocument = gql`
    query employerAnswerSchemaQuery($id: ID!) {
  employerAnswerSchema(id: $id) {
    error
    employerAnswerSchema {
      schema {
        questionSchema {
          id
          isTestQuestions
          questionGroups {
            id
            type
            questions {
              id
              type
              validation
              isOptional
              options {
                value
                transformedValue
                questions {
                  id
                  type
                  validation
                  isOptional
                  options {
                    value
                    transformedValue
                    questions {
                      id
                      type
                      validation
                      isOptional
                      options {
                        value
                        transformedValue
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEmployerAnswerSchemaQuery__
 *
 * To run a query within a React component, call `useEmployerAnswerSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerAnswerSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerAnswerSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployerAnswerSchemaQuery(baseOptions: Apollo.QueryHookOptions<EmployerAnswerSchemaQuery, EmployerAnswerSchemaQueryVariables>) {
        return Apollo.useQuery<EmployerAnswerSchemaQuery, EmployerAnswerSchemaQueryVariables>(EmployerAnswerSchemaQueryDocument, baseOptions);
      }
export function useEmployerAnswerSchemaQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployerAnswerSchemaQuery, EmployerAnswerSchemaQueryVariables>) {
          return Apollo.useLazyQuery<EmployerAnswerSchemaQuery, EmployerAnswerSchemaQueryVariables>(EmployerAnswerSchemaQueryDocument, baseOptions);
        }
export type EmployerAnswerSchemaQueryHookResult = ReturnType<typeof useEmployerAnswerSchemaQuery>;
export type EmployerAnswerSchemaQueryLazyQueryHookResult = ReturnType<typeof useEmployerAnswerSchemaQueryLazyQuery>;
export type EmployerAnswerSchemaQueryQueryResult = Apollo.QueryResult<EmployerAnswerSchemaQuery, EmployerAnswerSchemaQueryVariables>;
export const EmployerAnswersQueryDocument = gql`
    query employerAnswersQuery($assessmentCycleId: ID) {
  employerAnswers(assessmentCycleId: $assessmentCycleId) {
    assessmentCycleId
    questionId
    organisationId
    computedResponse
    response
    questionText
  }
}
    `;

/**
 * __useEmployerAnswersQuery__
 *
 * To run a query within a React component, call `useEmployerAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerAnswersQuery({
 *   variables: {
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *   },
 * });
 */
export function useEmployerAnswersQuery(baseOptions?: Apollo.QueryHookOptions<EmployerAnswersQuery, EmployerAnswersQueryVariables>) {
        return Apollo.useQuery<EmployerAnswersQuery, EmployerAnswersQueryVariables>(EmployerAnswersQueryDocument, baseOptions);
      }
export function useEmployerAnswersQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployerAnswersQuery, EmployerAnswersQueryVariables>) {
          return Apollo.useLazyQuery<EmployerAnswersQuery, EmployerAnswersQueryVariables>(EmployerAnswersQueryDocument, baseOptions);
        }
export type EmployerAnswersQueryHookResult = ReturnType<typeof useEmployerAnswersQuery>;
export type EmployerAnswersQueryLazyQueryHookResult = ReturnType<typeof useEmployerAnswersQueryLazyQuery>;
export type EmployerAnswersQueryQueryResult = Apollo.QueryResult<EmployerAnswersQuery, EmployerAnswersQueryVariables>;
export const UserQueryDocument = gql`
    query UserQuery($options: QueryUserInput!) {
  user(options: $options) {
    error
    user {
      id
      firebaseId
      firstName
      email
      roles
      permissions {
        reportViewerPermissions {
          allowedOrganisationUnits {
            name
            units {
              name
            }
          }
        }
      }
      organisation {
        name
        contactNumber
        id
        organisationUnits {
          attributeName
          units {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserQueryDocument, baseOptions);
      }
export function useUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserQueryDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserQueryLazyQueryHookResult = ReturnType<typeof useUserQueryLazyQuery>;
export type UserQueryQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserTestsDocument = gql`
    query UserTests {
  userTests {
    id
    organisation {
      id
      name
    }
    participantCount
    employeeCsv {
      url
    }
  }
}
    `;

/**
 * __useUserTests__
 *
 * To run a query within a React component, call `useUserTests` and pass it any options that fit your needs.
 * When your component renders, `useUserTests` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTests({
 *   variables: {
 *   },
 * });
 */
export function useUserTests(baseOptions?: Apollo.QueryHookOptions<UserTests, UserTestsVariables>) {
        return Apollo.useQuery<UserTests, UserTestsVariables>(UserTestsDocument, baseOptions);
      }
export function useUserTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTests, UserTestsVariables>) {
          return Apollo.useLazyQuery<UserTests, UserTestsVariables>(UserTestsDocument, baseOptions);
        }
export type UserTestsHookResult = ReturnType<typeof useUserTests>;
export type UserTestsLazyQueryHookResult = ReturnType<typeof useUserTestsLazyQuery>;
export type UserTestsQueryResult = Apollo.QueryResult<UserTests, UserTestsVariables>;
export const UsersQueryDocument = gql`
    query UsersQuery($options: GetUsersQueryInput) {
  users(options: $options) {
    results {
      id
      firebaseId
      firstName
      email
      organisationId
      organisation {
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersQueryDocument, baseOptions);
      }
export function useUsersQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersQueryDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersQueryLazyQueryHookResult = ReturnType<typeof useUsersQueryLazyQuery>;
export type UsersQueryQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const SendAccessRequestDocument = gql`
    mutation sendAccessRequest($input: AccessRequestInput!) {
  addUnverifiedUser(newRequest: $input)
}
    `;
export type SendAccessRequestMutationFn = Apollo.MutationFunction<SendAccessRequest, SendAccessRequestVariables>;

/**
 * __useSendAccessRequest__
 *
 * To run a mutation, you first call `useSendAccessRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAccessRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAccessRequest, { data, loading, error }] = useSendAccessRequest({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAccessRequest(baseOptions?: Apollo.MutationHookOptions<SendAccessRequest, SendAccessRequestVariables>) {
        return Apollo.useMutation<SendAccessRequest, SendAccessRequestVariables>(SendAccessRequestDocument, baseOptions);
      }
export type SendAccessRequestHookResult = ReturnType<typeof useSendAccessRequest>;
export type SendAccessRequestMutationResult = Apollo.MutationResult<SendAccessRequest>;
export type SendAccessRequestMutationOptions = Apollo.BaseMutationOptions<SendAccessRequest, SendAccessRequestVariables>;
export const VerifyIntermediateUserDocument = gql`
    mutation verifyIntermediateUser($input: String!) {
  verifyIntermediateUser(token: $input)
}
    `;
export type VerifyIntermediateUserMutationFn = Apollo.MutationFunction<VerifyIntermediateUser, VerifyIntermediateUserVariables>;

/**
 * __useVerifyIntermediateUser__
 *
 * To run a mutation, you first call `useVerifyIntermediateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyIntermediateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyIntermediateUser, { data, loading, error }] = useVerifyIntermediateUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyIntermediateUser(baseOptions?: Apollo.MutationHookOptions<VerifyIntermediateUser, VerifyIntermediateUserVariables>) {
        return Apollo.useMutation<VerifyIntermediateUser, VerifyIntermediateUserVariables>(VerifyIntermediateUserDocument, baseOptions);
      }
export type VerifyIntermediateUserHookResult = ReturnType<typeof useVerifyIntermediateUser>;
export type VerifyIntermediateUserMutationResult = Apollo.MutationResult<VerifyIntermediateUser>;
export type VerifyIntermediateUserMutationOptions = Apollo.BaseMutationOptions<VerifyIntermediateUser, VerifyIntermediateUserVariables>;
export const StartAssessmentDocument = gql`
    mutation startAssessment($options: StartAssessmentInput!) {
  startAssessment(options: $options) {
    error
    assessment {
      id
      status
    }
  }
}
    `;
export type StartAssessmentMutationFn = Apollo.MutationFunction<StartAssessment, StartAssessmentVariables>;

/**
 * __useStartAssessment__
 *
 * To run a mutation, you first call `useStartAssessment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAssessment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAssessment, { data, loading, error }] = useStartAssessment({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useStartAssessment(baseOptions?: Apollo.MutationHookOptions<StartAssessment, StartAssessmentVariables>) {
        return Apollo.useMutation<StartAssessment, StartAssessmentVariables>(StartAssessmentDocument, baseOptions);
      }
export type StartAssessmentHookResult = ReturnType<typeof useStartAssessment>;
export type StartAssessmentMutationResult = Apollo.MutationResult<StartAssessment>;
export type StartAssessmentMutationOptions = Apollo.BaseMutationOptions<StartAssessment, StartAssessmentVariables>;
export const AnswerQuestionDocument = gql`
    mutation answerQuestion($assessmentId: ID!, $answerQuestionInput: AnswerQuestionInput!) {
  answerQuestion(
    assessmentId: $assessmentId
    answerQuestionInput: $answerQuestionInput
  ) {
    answer {
      id
      questionId
      rawResponse
      transformedResponse
    }
    error
  }
}
    `;
export type AnswerQuestionMutationFn = Apollo.MutationFunction<AnswerQuestion, AnswerQuestionVariables>;

/**
 * __useAnswerQuestion__
 *
 * To run a mutation, you first call `useAnswerQuestion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerQuestion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerQuestion, { data, loading, error }] = useAnswerQuestion({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      answerQuestionInput: // value for 'answerQuestionInput'
 *   },
 * });
 */
export function useAnswerQuestion(baseOptions?: Apollo.MutationHookOptions<AnswerQuestion, AnswerQuestionVariables>) {
        return Apollo.useMutation<AnswerQuestion, AnswerQuestionVariables>(AnswerQuestionDocument, baseOptions);
      }
export type AnswerQuestionHookResult = ReturnType<typeof useAnswerQuestion>;
export type AnswerQuestionMutationResult = Apollo.MutationResult<AnswerQuestion>;
export type AnswerQuestionMutationOptions = Apollo.BaseMutationOptions<AnswerQuestion, AnswerQuestionVariables>;
export const CompleteSectionDocument = gql`
    mutation completeSection($assessmentId: ID!, $sectionId: String!) {
  completeSection(assessmentId: $assessmentId, sectionId: $sectionId) {
    success
    error
  }
}
    `;
export type CompleteSectionMutationFn = Apollo.MutationFunction<CompleteSection, CompleteSectionVariables>;

/**
 * __useCompleteSection__
 *
 * To run a mutation, you first call `useCompleteSection` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSection` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSection, { data, loading, error }] = useCompleteSection({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useCompleteSection(baseOptions?: Apollo.MutationHookOptions<CompleteSection, CompleteSectionVariables>) {
        return Apollo.useMutation<CompleteSection, CompleteSectionVariables>(CompleteSectionDocument, baseOptions);
      }
export type CompleteSectionHookResult = ReturnType<typeof useCompleteSection>;
export type CompleteSectionMutationResult = Apollo.MutationResult<CompleteSection>;
export type CompleteSectionMutationOptions = Apollo.BaseMutationOptions<CompleteSection, CompleteSectionVariables>;
export const CompleteAssessmentDocument = gql`
    mutation completeAssessment($assessmentId: ID!, $recompute: Boolean) {
  completeAssessment(assessmentId: $assessmentId, recompute: $recompute) {
    error
    assessment {
      id
      status
      scores {
        name
        category
        id
        scoreId
        rank
        isModifiable
        oceanAttributes {
          deviation
        }
        value
        error
      }
    }
  }
}
    `;
export type CompleteAssessmentMutationFn = Apollo.MutationFunction<CompleteAssessment, CompleteAssessmentVariables>;

/**
 * __useCompleteAssessment__
 *
 * To run a mutation, you first call `useCompleteAssessment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssessment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssessment, { data, loading, error }] = useCompleteAssessment({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      recompute: // value for 'recompute'
 *   },
 * });
 */
export function useCompleteAssessment(baseOptions?: Apollo.MutationHookOptions<CompleteAssessment, CompleteAssessmentVariables>) {
        return Apollo.useMutation<CompleteAssessment, CompleteAssessmentVariables>(CompleteAssessmentDocument, baseOptions);
      }
export type CompleteAssessmentHookResult = ReturnType<typeof useCompleteAssessment>;
export type CompleteAssessmentMutationResult = Apollo.MutationResult<CompleteAssessment>;
export type CompleteAssessmentMutationOptions = Apollo.BaseMutationOptions<CompleteAssessment, CompleteAssessmentVariables>;
export const GenerateEmployeeReportDocument = gql`
    mutation GenerateEmployeeReport($options: GenerateEmployeeReportInput!) {
  generateEmployeeReport(options: $options) {
    error
    report {
      id
      status
      error
      requestedAt
      gcsFile {
        url
      }
    }
  }
}
    `;
export type GenerateEmployeeReportMutationFn = Apollo.MutationFunction<GenerateEmployeeReport, GenerateEmployeeReportVariables>;

/**
 * __useGenerateEmployeeReport__
 *
 * To run a mutation, you first call `useGenerateEmployeeReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmployeeReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmployeeReport, { data, loading, error }] = useGenerateEmployeeReport({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGenerateEmployeeReport(baseOptions?: Apollo.MutationHookOptions<GenerateEmployeeReport, GenerateEmployeeReportVariables>) {
        return Apollo.useMutation<GenerateEmployeeReport, GenerateEmployeeReportVariables>(GenerateEmployeeReportDocument, baseOptions);
      }
export type GenerateEmployeeReportHookResult = ReturnType<typeof useGenerateEmployeeReport>;
export type GenerateEmployeeReportMutationResult = Apollo.MutationResult<GenerateEmployeeReport>;
export type GenerateEmployeeReportMutationOptions = Apollo.BaseMutationOptions<GenerateEmployeeReport, GenerateEmployeeReportVariables>;
export const SaveAssessmentCycleDocument = gql`
    mutation saveAssessmentCycle($organisationId: ID!, $inviteBody: String, $inviteSubject: String, $inviteTextMessage: String, $assessmentCycleId: ID, $startDate: DateTime) {
  saveAssessmentCycle(
    options: {organisationId: $organisationId, inviteBody: $inviteBody, inviteSubject: $inviteSubject, inviteTextMessage: $inviteTextMessage, assessmentCycleId: $assessmentCycleId, startDate: $startDate}
  ) {
    assessmentCycle {
      id
      startDate
      completedAt
      participationResults {
        totalEmployeeCount
        completedEmployeeCount
        completionRate
      }
      reminders {
        id
        createdAt
        creatorId
        sentAt
        content
        scheduledAt
      }
    }
    error
  }
}
    `;
export type SaveAssessmentCycleMutationFn = Apollo.MutationFunction<SaveAssessmentCycle, SaveAssessmentCycleVariables>;

/**
 * __useSaveAssessmentCycle__
 *
 * To run a mutation, you first call `useSaveAssessmentCycle` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssessmentCycle` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssessmentCycle, { data, loading, error }] = useSaveAssessmentCycle({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      inviteBody: // value for 'inviteBody'
 *      inviteSubject: // value for 'inviteSubject'
 *      inviteTextMessage: // value for 'inviteTextMessage'
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useSaveAssessmentCycle(baseOptions?: Apollo.MutationHookOptions<SaveAssessmentCycle, SaveAssessmentCycleVariables>) {
        return Apollo.useMutation<SaveAssessmentCycle, SaveAssessmentCycleVariables>(SaveAssessmentCycleDocument, baseOptions);
      }
export type SaveAssessmentCycleHookResult = ReturnType<typeof useSaveAssessmentCycle>;
export type SaveAssessmentCycleMutationResult = Apollo.MutationResult<SaveAssessmentCycle>;
export type SaveAssessmentCycleMutationOptions = Apollo.BaseMutationOptions<SaveAssessmentCycle, SaveAssessmentCycleVariables>;
export const SaveReminderDocument = gql`
    mutation saveReminder($saveReminderInput: SaveReminderInput!) {
  saveReminder(options: $saveReminderInput) {
    reminder {
      id
    }
    error
  }
}
    `;
export type SaveReminderMutationFn = Apollo.MutationFunction<SaveReminder, SaveReminderVariables>;

/**
 * __useSaveReminder__
 *
 * To run a mutation, you first call `useSaveReminder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveReminder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveReminder, { data, loading, error }] = useSaveReminder({
 *   variables: {
 *      saveReminderInput: // value for 'saveReminderInput'
 *   },
 * });
 */
export function useSaveReminder(baseOptions?: Apollo.MutationHookOptions<SaveReminder, SaveReminderVariables>) {
        return Apollo.useMutation<SaveReminder, SaveReminderVariables>(SaveReminderDocument, baseOptions);
      }
export type SaveReminderHookResult = ReturnType<typeof useSaveReminder>;
export type SaveReminderMutationResult = Apollo.MutationResult<SaveReminder>;
export type SaveReminderMutationOptions = Apollo.BaseMutationOptions<SaveReminder, SaveReminderVariables>;
export const CompleteAssessmentCycleDocument = gql`
    mutation completeAssessmentCycle($id: ID!) {
  completeAssessmentCycle(id: $id) {
    assessmentCycle {
      id
      startDate
      completedAt
    }
    error
  }
}
    `;
export type CompleteAssessmentCycleMutationFn = Apollo.MutationFunction<CompleteAssessmentCycle, CompleteAssessmentCycleVariables>;

/**
 * __useCompleteAssessmentCycle__
 *
 * To run a mutation, you first call `useCompleteAssessmentCycle` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssessmentCycle` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssessmentCycle, { data, loading, error }] = useCompleteAssessmentCycle({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteAssessmentCycle(baseOptions?: Apollo.MutationHookOptions<CompleteAssessmentCycle, CompleteAssessmentCycleVariables>) {
        return Apollo.useMutation<CompleteAssessmentCycle, CompleteAssessmentCycleVariables>(CompleteAssessmentCycleDocument, baseOptions);
      }
export type CompleteAssessmentCycleHookResult = ReturnType<typeof useCompleteAssessmentCycle>;
export type CompleteAssessmentCycleMutationResult = Apollo.MutationResult<CompleteAssessmentCycle>;
export type CompleteAssessmentCycleMutationOptions = Apollo.BaseMutationOptions<CompleteAssessmentCycle, CompleteAssessmentCycleVariables>;
export const RequestReportDocument = gql`
    mutation requestReport($id: ID!, $request: ReportRequestInput!) {
  requestReport(id: $id, request: $request) {
    assessmentCycle {
      id
      reportRequest {
        dateRequested
        subject
        request
      }
    }
    error
  }
}
    `;
export type RequestReportMutationFn = Apollo.MutationFunction<RequestReport, RequestReportVariables>;

/**
 * __useRequestReport__
 *
 * To run a mutation, you first call `useRequestReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestReport, { data, loading, error }] = useRequestReport({
 *   variables: {
 *      id: // value for 'id'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRequestReport(baseOptions?: Apollo.MutationHookOptions<RequestReport, RequestReportVariables>) {
        return Apollo.useMutation<RequestReport, RequestReportVariables>(RequestReportDocument, baseOptions);
      }
export type RequestReportHookResult = ReturnType<typeof useRequestReport>;
export type RequestReportMutationResult = Apollo.MutationResult<RequestReport>;
export type RequestReportMutationOptions = Apollo.BaseMutationOptions<RequestReport, RequestReportVariables>;
export const SubmitOrganisationAnswersDocument = gql`
    mutation submitOrganisationAnswers($assessmentCycleId: Float!, $organisationId: Float!, $answers: OrganisationAnswersInput!) {
  submitOrganisationAnswers(
    assessmentCycleId: $assessmentCycleId
    organisationId: $organisationId
    answers: $answers
  ) {
    error
    assessmentCycle {
      id
      employerAnswers {
        questionId
        questionText
        response
        computedResponse
      }
    }
  }
}
    `;
export type SubmitOrganisationAnswersMutationFn = Apollo.MutationFunction<SubmitOrganisationAnswers, SubmitOrganisationAnswersVariables>;

/**
 * __useSubmitOrganisationAnswers__
 *
 * To run a mutation, you first call `useSubmitOrganisationAnswers` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOrganisationAnswers` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOrganisationAnswers, { data, loading, error }] = useSubmitOrganisationAnswers({
 *   variables: {
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *      organisationId: // value for 'organisationId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useSubmitOrganisationAnswers(baseOptions?: Apollo.MutationHookOptions<SubmitOrganisationAnswers, SubmitOrganisationAnswersVariables>) {
        return Apollo.useMutation<SubmitOrganisationAnswers, SubmitOrganisationAnswersVariables>(SubmitOrganisationAnswersDocument, baseOptions);
      }
export type SubmitOrganisationAnswersHookResult = ReturnType<typeof useSubmitOrganisationAnswers>;
export type SubmitOrganisationAnswersMutationResult = Apollo.MutationResult<SubmitOrganisationAnswers>;
export type SubmitOrganisationAnswersMutationOptions = Apollo.BaseMutationOptions<SubmitOrganisationAnswers, SubmitOrganisationAnswersVariables>;
export const GenerateEmployerReportDocument = gql`
    mutation GenerateEmployerReport($options: GenerateEmployerReportInput!) {
  generateEmployerReport(options: $options) {
    error
    report {
      id
      requestedAt
      status
      error
      gcsFile {
        url
      }
    }
  }
}
    `;
export type GenerateEmployerReportMutationFn = Apollo.MutationFunction<GenerateEmployerReport, GenerateEmployerReportVariables>;

/**
 * __useGenerateEmployerReport__
 *
 * To run a mutation, you first call `useGenerateEmployerReport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmployerReport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmployerReport, { data, loading, error }] = useGenerateEmployerReport({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGenerateEmployerReport(baseOptions?: Apollo.MutationHookOptions<GenerateEmployerReport, GenerateEmployerReportVariables>) {
        return Apollo.useMutation<GenerateEmployerReport, GenerateEmployerReportVariables>(GenerateEmployerReportDocument, baseOptions);
      }
export type GenerateEmployerReportHookResult = ReturnType<typeof useGenerateEmployerReport>;
export type GenerateEmployerReportMutationResult = Apollo.MutationResult<GenerateEmployerReport>;
export type GenerateEmployerReportMutationOptions = Apollo.BaseMutationOptions<GenerateEmployerReport, GenerateEmployerReportVariables>;
export const GenerateEmployerScorecardDocument = gql`
    mutation GenerateEmployerScorecard($options: GenerateEmployerScorecardInput!) {
  generateEmployerScorecard(options: $options) {
    error
    scorecard {
      id
      requestedAt
      status
      error
      gcsFile {
        url
      }
    }
  }
}
    `;
export type GenerateEmployerScorecardMutationFn = Apollo.MutationFunction<GenerateEmployerScorecard, GenerateEmployerScorecardVariables>;

/**
 * __useGenerateEmployerScorecard__
 *
 * To run a mutation, you first call `useGenerateEmployerScorecard` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmployerScorecard` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmployerScorecard, { data, loading, error }] = useGenerateEmployerScorecard({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGenerateEmployerScorecard(baseOptions?: Apollo.MutationHookOptions<GenerateEmployerScorecard, GenerateEmployerScorecardVariables>) {
        return Apollo.useMutation<GenerateEmployerScorecard, GenerateEmployerScorecardVariables>(GenerateEmployerScorecardDocument, baseOptions);
      }
export type GenerateEmployerScorecardHookResult = ReturnType<typeof useGenerateEmployerScorecard>;
export type GenerateEmployerScorecardMutationResult = Apollo.MutationResult<GenerateEmployerScorecard>;
export type GenerateEmployerScorecardMutationOptions = Apollo.BaseMutationOptions<GenerateEmployerScorecard, GenerateEmployerScorecardVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String!, $password: String!) {
  login(options: {email: $email, password: $password}) {
    error
    idToken
    user {
      firebaseId
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUser, LoginUserVariables>;

/**
 * __useLoginUser__
 *
 * To run a mutation, you first call `useLoginUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUser, { data, loading, error }] = useLoginUser({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUser(baseOptions?: Apollo.MutationHookOptions<LoginUser, LoginUserVariables>) {
        return Apollo.useMutation<LoginUser, LoginUserVariables>(LoginUserDocument, baseOptions);
      }
export type LoginUserHookResult = ReturnType<typeof useLoginUser>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUser>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUser, LoginUserVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($registerInput: RegisterInput!) {
  register(options: $registerInput) {
    error
    idToken
    user {
      id
      firstName
      surname
      firebaseId
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUser, RegisterUserVariables>;

/**
 * __useRegisterUser__
 *
 * To run a mutation, you first call `useRegisterUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUser, { data, loading, error }] = useRegisterUser({
 *   variables: {
 *      registerInput: // value for 'registerInput'
 *   },
 * });
 */
export function useRegisterUser(baseOptions?: Apollo.MutationHookOptions<RegisterUser, RegisterUserVariables>) {
        return Apollo.useMutation<RegisterUser, RegisterUserVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserHookResult = ReturnType<typeof useRegisterUser>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUser>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUser, RegisterUserVariables>;
export const AcceptInviteDocument = gql`
    mutation AcceptInvite($email: String!, $contactNumber: String, $password: String!, $firstName: String!, $inviteToken: String!) {
  acceptInvite(
    acceptInviteInput: {email: $email, contactNumber: $contactNumber, password: $password, firstName: $firstName, inviteToken: $inviteToken}
  ) {
    error
    customToken
  }
}
    `;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInvite, AcceptInviteVariables>;

/**
 * __useAcceptInvite__
 *
 * To run a mutation, you first call `useAcceptInvite` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvite` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvite, { data, loading, error }] = useAcceptInvite({
 *   variables: {
 *      email: // value for 'email'
 *      contactNumber: // value for 'contactNumber'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useAcceptInvite(baseOptions?: Apollo.MutationHookOptions<AcceptInvite, AcceptInviteVariables>) {
        return Apollo.useMutation<AcceptInvite, AcceptInviteVariables>(AcceptInviteDocument, baseOptions);
      }
export type AcceptInviteHookResult = ReturnType<typeof useAcceptInvite>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInvite>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInvite, AcceptInviteVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPassword, ForgotPasswordVariables>;

/**
 * __useForgotPassword__
 *
 * To run a mutation, you first call `useForgotPassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPassword, { data, loading, error }] = useForgotPassword({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPassword(baseOptions?: Apollo.MutationHookOptions<ForgotPassword, ForgotPasswordVariables>) {
        return Apollo.useMutation<ForgotPassword, ForgotPasswordVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordHookResult = ReturnType<typeof useForgotPassword>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPassword>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPassword, ForgotPasswordVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($newPassword: String!, $token: String!) {
  resetPassword(newPassword: $newPassword, token: $token)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPassword, ResetPasswordVariables>;

/**
 * __useResetPassword__
 *
 * To run a mutation, you first call `useResetPassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPassword, { data, loading, error }] = useResetPassword({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPassword(baseOptions?: Apollo.MutationHookOptions<ResetPassword, ResetPasswordVariables>) {
        return Apollo.useMutation<ResetPassword, ResetPasswordVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordHookResult = ReturnType<typeof useResetPassword>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPassword>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPassword, ResetPasswordVariables>;
export const LoginTesterDocument = gql`
    mutation loginTester($rid: String!) {
  loginTester(rid: $rid) {
    error
    customToken
  }
}
    `;
export type LoginTesterMutationFn = Apollo.MutationFunction<LoginTester, LoginTesterVariables>;

/**
 * __useLoginTester__
 *
 * To run a mutation, you first call `useLoginTester` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginTester` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginTester, { data, loading, error }] = useLoginTester({
 *   variables: {
 *      rid: // value for 'rid'
 *   },
 * });
 */
export function useLoginTester(baseOptions?: Apollo.MutationHookOptions<LoginTester, LoginTesterVariables>) {
        return Apollo.useMutation<LoginTester, LoginTesterVariables>(LoginTesterDocument, baseOptions);
      }
export type LoginTesterHookResult = ReturnType<typeof useLoginTester>;
export type LoginTesterMutationResult = Apollo.MutationResult<LoginTester>;
export type LoginTesterMutationOptions = Apollo.BaseMutationOptions<LoginTester, LoginTesterVariables>;
export const CompleteSetupDocument = gql`
    mutation completeSetup {
  completeSetup {
    id
    firstName
    completedSetup
  }
}
    `;
export type CompleteSetupMutationFn = Apollo.MutationFunction<CompleteSetup, CompleteSetupVariables>;

/**
 * __useCompleteSetup__
 *
 * To run a mutation, you first call `useCompleteSetup` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSetup` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSetup, { data, loading, error }] = useCompleteSetup({
 *   variables: {
 *   },
 * });
 */
export function useCompleteSetup(baseOptions?: Apollo.MutationHookOptions<CompleteSetup, CompleteSetupVariables>) {
        return Apollo.useMutation<CompleteSetup, CompleteSetupVariables>(CompleteSetupDocument, baseOptions);
      }
export type CompleteSetupHookResult = ReturnType<typeof useCompleteSetup>;
export type CompleteSetupMutationResult = Apollo.MutationResult<CompleteSetup>;
export type CompleteSetupMutationOptions = Apollo.BaseMutationOptions<CompleteSetup, CompleteSetupVariables>;
export const EmployerProfileScoreMutationDocument = gql`
    mutation employerProfileScoreMutation($id: ID!) {
  employerProfileScore(assessmentCycleId: $id) {
    employerProfileScore {
      name
      scores {
        scoreId
        score
        error
      }
    }
    error
  }
}
    `;
export type EmployerProfileScoreMutationMutationFn = Apollo.MutationFunction<EmployerProfileScoreMutation, EmployerProfileScoreMutationVariables>;

/**
 * __useEmployerProfileScoreMutation__
 *
 * To run a mutation, you first call `useEmployerProfileScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployerProfileScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employerProfileScoreMutation, { data, loading, error }] = useEmployerProfileScoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployerProfileScoreMutation(baseOptions?: Apollo.MutationHookOptions<EmployerProfileScoreMutation, EmployerProfileScoreMutationVariables>) {
        return Apollo.useMutation<EmployerProfileScoreMutation, EmployerProfileScoreMutationVariables>(EmployerProfileScoreMutationDocument, baseOptions);
      }
export type EmployerProfileScoreMutationHookResult = ReturnType<typeof useEmployerProfileScoreMutation>;
export type EmployerProfileScoreMutationMutationResult = Apollo.MutationResult<EmployerProfileScoreMutation>;
export type EmployerProfileScoreMutationMutationOptions = Apollo.BaseMutationOptions<EmployerProfileScoreMutation, EmployerProfileScoreMutationVariables>;
export const ProductivityAnalysisDocument = gql`
    mutation productivityAnalysis($id: ID!, $organisationUnitFilters: [OrganisationUnitFilterInput!]) {
  productivityAnalysis(
    assessmentCycleId: $id
    organisationUnitFilters: $organisationUnitFilters
  ) {
    error
    productivityScore {
      name
      scores {
        scoreId
        score
        scalingFactor
        error
      }
      units {
        name
        parentName
        scores {
          scoreId
          score
          scalingFactor
          error
        }
      }
    }
  }
}
    `;
export type ProductivityAnalysisMutationFn = Apollo.MutationFunction<ProductivityAnalysis, ProductivityAnalysisVariables>;

/**
 * __useProductivityAnalysis__
 *
 * To run a mutation, you first call `useProductivityAnalysis` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductivityAnalysis` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productivityAnalysis, { data, loading, error }] = useProductivityAnalysis({
 *   variables: {
 *      id: // value for 'id'
 *      organisationUnitFilters: // value for 'organisationUnitFilters'
 *   },
 * });
 */
export function useProductivityAnalysis(baseOptions?: Apollo.MutationHookOptions<ProductivityAnalysis, ProductivityAnalysisVariables>) {
        return Apollo.useMutation<ProductivityAnalysis, ProductivityAnalysisVariables>(ProductivityAnalysisDocument, baseOptions);
      }
export type ProductivityAnalysisHookResult = ReturnType<typeof useProductivityAnalysis>;
export type ProductivityAnalysisMutationResult = Apollo.MutationResult<ProductivityAnalysis>;
export type ProductivityAnalysisMutationOptions = Apollo.BaseMutationOptions<ProductivityAnalysis, ProductivityAnalysisVariables>;
export const UploadFileToGcpDocument = gql`
    mutation UploadFileToGCP($file: Upload!) {
  uploadFileToGCP(file: $file) {
    error
    id
  }
}
    `;
export type UploadFileToGcpMutationFn = Apollo.MutationFunction<UploadFileToGcp, UploadFileToGcpVariables>;

/**
 * __useUploadFileToGcp__
 *
 * To run a mutation, you first call `useUploadFileToGcp` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileToGcp` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileToGcp, { data, loading, error }] = useUploadFileToGcp({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileToGcp(baseOptions?: Apollo.MutationHookOptions<UploadFileToGcp, UploadFileToGcpVariables>) {
        return Apollo.useMutation<UploadFileToGcp, UploadFileToGcpVariables>(UploadFileToGcpDocument, baseOptions);
      }
export type UploadFileToGcpHookResult = ReturnType<typeof useUploadFileToGcp>;
export type UploadFileToGcpMutationResult = Apollo.MutationResult<UploadFileToGcp>;
export type UploadFileToGcpMutationOptions = Apollo.BaseMutationOptions<UploadFileToGcp, UploadFileToGcpVariables>;
export const UpdateEmployeesDocument = gql`
    mutation updateEmployees($organisationId: ID!, $addEmployeesInput: [UpdateEmployeeInput!]!) {
  updateEmployees(
    organisationId: $organisationId
    addEmployeesInput: $addEmployeesInput
  ) {
    error
    updatedEmployees {
      firebaseId
      firstName
      emailAddress
      contactNumber
      attributes
    }
    result {
      organisationUnitCount
      employeeCount
    }
  }
}
    `;
export type UpdateEmployeesMutationFn = Apollo.MutationFunction<UpdateEmployees, UpdateEmployeesVariables>;

/**
 * __useUpdateEmployees__
 *
 * To run a mutation, you first call `useUpdateEmployees` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployees` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployees, { data, loading, error }] = useUpdateEmployees({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      addEmployeesInput: // value for 'addEmployeesInput'
 *   },
 * });
 */
export function useUpdateEmployees(baseOptions?: Apollo.MutationHookOptions<UpdateEmployees, UpdateEmployeesVariables>) {
        return Apollo.useMutation<UpdateEmployees, UpdateEmployeesVariables>(UpdateEmployeesDocument, baseOptions);
      }
export type UpdateEmployeesHookResult = ReturnType<typeof useUpdateEmployees>;
export type UpdateEmployeesMutationResult = Apollo.MutationResult<UpdateEmployees>;
export type UpdateEmployeesMutationOptions = Apollo.BaseMutationOptions<UpdateEmployees, UpdateEmployeesVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($updateProfileInput: UpdateProfileInput!) {
  updateProfile(updateProfileInput: $updateProfileInput) {
    id
    firstName
    completedSetup
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfile, UpdateUserProfileVariables>;

/**
 * __useUpdateUserProfile__
 *
 * To run a mutation, you first call `useUpdateUserProfile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfile, { data, loading, error }] = useUpdateUserProfile({
 *   variables: {
 *      updateProfileInput: // value for 'updateProfileInput'
 *   },
 * });
 */
export function useUpdateUserProfile(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfile, UpdateUserProfileVariables>) {
        return Apollo.useMutation<UpdateUserProfile, UpdateUserProfileVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileHookResult = ReturnType<typeof useUpdateUserProfile>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfile>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfile, UpdateUserProfileVariables>;
export const UploadEmployerPictureDocument = gql`
    mutation uploadEmployerPicture($file: Upload!) {
  uploadEmployerPicture(file: $file) {
    id
    firstName
  }
}
    `;
export type UploadEmployerPictureMutationFn = Apollo.MutationFunction<UploadEmployerPicture, UploadEmployerPictureVariables>;

/**
 * __useUploadEmployerPicture__
 *
 * To run a mutation, you first call `useUploadEmployerPicture` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEmployerPicture` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEmployerPicture, { data, loading, error }] = useUploadEmployerPicture({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadEmployerPicture(baseOptions?: Apollo.MutationHookOptions<UploadEmployerPicture, UploadEmployerPictureVariables>) {
        return Apollo.useMutation<UploadEmployerPicture, UploadEmployerPictureVariables>(UploadEmployerPictureDocument, baseOptions);
      }
export type UploadEmployerPictureHookResult = ReturnType<typeof useUploadEmployerPicture>;
export type UploadEmployerPictureMutationResult = Apollo.MutationResult<UploadEmployerPicture>;
export type UploadEmployerPictureMutationOptions = Apollo.BaseMutationOptions<UploadEmployerPicture, UploadEmployerPictureVariables>;
export const AcceptAgreementsDocument = gql`
    mutation acceptAgreements {
  acceptAgreements {
    acceptedGdprAt
  }
}
    `;
export type AcceptAgreementsMutationFn = Apollo.MutationFunction<AcceptAgreements, AcceptAgreementsVariables>;

/**
 * __useAcceptAgreements__
 *
 * To run a mutation, you first call `useAcceptAgreements` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAgreements` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAgreements, { data, loading, error }] = useAcceptAgreements({
 *   variables: {
 *   },
 * });
 */
export function useAcceptAgreements(baseOptions?: Apollo.MutationHookOptions<AcceptAgreements, AcceptAgreementsVariables>) {
        return Apollo.useMutation<AcceptAgreements, AcceptAgreementsVariables>(AcceptAgreementsDocument, baseOptions);
      }
export type AcceptAgreementsHookResult = ReturnType<typeof useAcceptAgreements>;
export type AcceptAgreementsMutationResult = Apollo.MutationResult<AcceptAgreements>;
export type AcceptAgreementsMutationOptions = Apollo.BaseMutationOptions<AcceptAgreements, AcceptAgreementsVariables>;
export const AssessmentSchemaQueryDocument = gql`
    query assessmentSchemaQuery($id: ID!) {
  assessmentSchema(id: $id) {
    error
    assessmentSchema {
      schema {
        questionSchema {
          id
          isTestQuestions
          isDynamicSection
          questionGroups {
            id
            type
            questions {
              id
              type
              validation
              isOptional
              options {
                value
                transformedValue
                questions {
                  id
                  type
                  validation
                  isOptional
                  options {
                    value
                    transformedValue
                    questions {
                      id
                      type
                      validation
                      isOptional
                      options {
                        value
                        transformedValue
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAssessmentSchemaQuery__
 *
 * To run a query within a React component, call `useAssessmentSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentSchemaQuery(baseOptions: Apollo.QueryHookOptions<AssessmentSchemaQuery, AssessmentSchemaQueryVariables>) {
        return Apollo.useQuery<AssessmentSchemaQuery, AssessmentSchemaQueryVariables>(AssessmentSchemaQueryDocument, baseOptions);
      }
export function useAssessmentSchemaQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentSchemaQuery, AssessmentSchemaQueryVariables>) {
          return Apollo.useLazyQuery<AssessmentSchemaQuery, AssessmentSchemaQueryVariables>(AssessmentSchemaQueryDocument, baseOptions);
        }
export type AssessmentSchemaQueryHookResult = ReturnType<typeof useAssessmentSchemaQuery>;
export type AssessmentSchemaQueryLazyQueryHookResult = ReturnType<typeof useAssessmentSchemaQueryLazyQuery>;
export type AssessmentSchemaQueryQueryResult = Apollo.QueryResult<AssessmentSchemaQuery, AssessmentSchemaQueryVariables>;
export const AssessmentCyclesDocument = gql`
    query assessmentCycles {
  assessmentCycles {
    id
    createdAt
    assessments {
      id
      status
    }
  }
}
    `;

/**
 * __useAssessmentCycles__
 *
 * To run a query within a React component, call `useAssessmentCycles` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycles({
 *   variables: {
 *   },
 * });
 */
export function useAssessmentCycles(baseOptions?: Apollo.QueryHookOptions<AssessmentCycles, AssessmentCyclesVariables>) {
        return Apollo.useQuery<AssessmentCycles, AssessmentCyclesVariables>(AssessmentCyclesDocument, baseOptions);
      }
export function useAssessmentCyclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycles, AssessmentCyclesVariables>) {
          return Apollo.useLazyQuery<AssessmentCycles, AssessmentCyclesVariables>(AssessmentCyclesDocument, baseOptions);
        }
export type AssessmentCyclesHookResult = ReturnType<typeof useAssessmentCycles>;
export type AssessmentCyclesLazyQueryHookResult = ReturnType<typeof useAssessmentCyclesLazyQuery>;
export type AssessmentCyclesQueryResult = Apollo.QueryResult<AssessmentCycles, AssessmentCyclesVariables>;
export const AssessmentQueryDocument = gql`
    query assessmentQuery($id: ID!) {
  assessment(id: $id) {
    assessment {
      id
      status
      organisationId
      answers {
        id
        questionId
        rawResponse
        transformedResponse
      }
      scores {
        id
        scoreId
        name
        category
        rank
        score
        isModifiable
        oceanAttributes {
          deviation
          deviationValue
        }
        subScores {
          id
          scoreId
          score
        }
      }
      assessmentCycleId
      report {
        id
        status
        error
        requestedAt
        gcsFile {
          url
        }
      }
    }
  }
}
    `;

/**
 * __useAssessmentQuery__
 *
 * To run a query within a React component, call `useAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentQuery(baseOptions: Apollo.QueryHookOptions<AssessmentQuery, AssessmentQueryVariables>) {
        return Apollo.useQuery<AssessmentQuery, AssessmentQueryVariables>(AssessmentQueryDocument, baseOptions);
      }
export function useAssessmentQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentQuery, AssessmentQueryVariables>) {
          return Apollo.useLazyQuery<AssessmentQuery, AssessmentQueryVariables>(AssessmentQueryDocument, baseOptions);
        }
export type AssessmentQueryHookResult = ReturnType<typeof useAssessmentQuery>;
export type AssessmentQueryLazyQueryHookResult = ReturnType<typeof useAssessmentQueryLazyQuery>;
export type AssessmentQueryQueryResult = Apollo.QueryResult<AssessmentQuery, AssessmentQueryVariables>;
export const DynamicAssessmentSchemaDocument = gql`
    query dynamicAssessmentSchema($getDynamicSectionInput: GetBenefitsByGroupInput!) {
  dynamicAssessmentSchema(getDynamicSectionInput: $getDynamicSectionInput) {
    id
    isTestQuestions
    questionGroups {
      id
      type
      questions {
        id
        type
        validation
        isOptional
        customContentId
        options {
          value
          transformedValue
          questions {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDynamicAssessmentSchema__
 *
 * To run a query within a React component, call `useDynamicAssessmentSchema` and pass it any options that fit your needs.
 * When your component renders, `useDynamicAssessmentSchema` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicAssessmentSchema({
 *   variables: {
 *      getDynamicSectionInput: // value for 'getDynamicSectionInput'
 *   },
 * });
 */
export function useDynamicAssessmentSchema(baseOptions: Apollo.QueryHookOptions<DynamicAssessmentSchema, DynamicAssessmentSchemaVariables>) {
        return Apollo.useQuery<DynamicAssessmentSchema, DynamicAssessmentSchemaVariables>(DynamicAssessmentSchemaDocument, baseOptions);
      }
export function useDynamicAssessmentSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DynamicAssessmentSchema, DynamicAssessmentSchemaVariables>) {
          return Apollo.useLazyQuery<DynamicAssessmentSchema, DynamicAssessmentSchemaVariables>(DynamicAssessmentSchemaDocument, baseOptions);
        }
export type DynamicAssessmentSchemaHookResult = ReturnType<typeof useDynamicAssessmentSchema>;
export type DynamicAssessmentSchemaLazyQueryHookResult = ReturnType<typeof useDynamicAssessmentSchemaLazyQuery>;
export type DynamicAssessmentSchemaQueryResult = Apollo.QueryResult<DynamicAssessmentSchema, DynamicAssessmentSchemaVariables>;
export const LatestCompletedAssessmentIdDocument = gql`
    query latestCompletedAssessmentId {
  latestCompletedAssessmentId
}
    `;

/**
 * __useLatestCompletedAssessmentId__
 *
 * To run a query within a React component, call `useLatestCompletedAssessmentId` and pass it any options that fit your needs.
 * When your component renders, `useLatestCompletedAssessmentId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestCompletedAssessmentId({
 *   variables: {
 *   },
 * });
 */
export function useLatestCompletedAssessmentId(baseOptions?: Apollo.QueryHookOptions<LatestCompletedAssessmentId, LatestCompletedAssessmentIdVariables>) {
        return Apollo.useQuery<LatestCompletedAssessmentId, LatestCompletedAssessmentIdVariables>(LatestCompletedAssessmentIdDocument, baseOptions);
      }
export function useLatestCompletedAssessmentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestCompletedAssessmentId, LatestCompletedAssessmentIdVariables>) {
          return Apollo.useLazyQuery<LatestCompletedAssessmentId, LatestCompletedAssessmentIdVariables>(LatestCompletedAssessmentIdDocument, baseOptions);
        }
export type LatestCompletedAssessmentIdHookResult = ReturnType<typeof useLatestCompletedAssessmentId>;
export type LatestCompletedAssessmentIdLazyQueryHookResult = ReturnType<typeof useLatestCompletedAssessmentIdLazyQuery>;
export type LatestCompletedAssessmentIdQueryResult = Apollo.QueryResult<LatestCompletedAssessmentId, LatestCompletedAssessmentIdVariables>;
export const AssesmentCycleWithEmployeeParticipationDocument = gql`
    query assesmentCycleWithEmployeeParticipation($id: ID!) {
  assessmentCycle(id: $id) {
    id
    updatedAt
    participationResults {
      totalEmployeeCount
      completionRate
      completedEmployeeCount
      organisationUnitsCounts {
        unit
        attributeName
        totalEmployeeCount
        completedEmployeeCount
      }
    }
  }
}
    `;

/**
 * __useAssesmentCycleWithEmployeeParticipation__
 *
 * To run a query within a React component, call `useAssesmentCycleWithEmployeeParticipation` and pass it any options that fit your needs.
 * When your component renders, `useAssesmentCycleWithEmployeeParticipation` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssesmentCycleWithEmployeeParticipation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssesmentCycleWithEmployeeParticipation(baseOptions: Apollo.QueryHookOptions<AssesmentCycleWithEmployeeParticipation, AssesmentCycleWithEmployeeParticipationVariables>) {
        return Apollo.useQuery<AssesmentCycleWithEmployeeParticipation, AssesmentCycleWithEmployeeParticipationVariables>(AssesmentCycleWithEmployeeParticipationDocument, baseOptions);
      }
export function useAssesmentCycleWithEmployeeParticipationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssesmentCycleWithEmployeeParticipation, AssesmentCycleWithEmployeeParticipationVariables>) {
          return Apollo.useLazyQuery<AssesmentCycleWithEmployeeParticipation, AssesmentCycleWithEmployeeParticipationVariables>(AssesmentCycleWithEmployeeParticipationDocument, baseOptions);
        }
export type AssesmentCycleWithEmployeeParticipationHookResult = ReturnType<typeof useAssesmentCycleWithEmployeeParticipation>;
export type AssesmentCycleWithEmployeeParticipationLazyQueryHookResult = ReturnType<typeof useAssesmentCycleWithEmployeeParticipationLazyQuery>;
export type AssesmentCycleWithEmployeeParticipationQueryResult = Apollo.QueryResult<AssesmentCycleWithEmployeeParticipation, AssesmentCycleWithEmployeeParticipationVariables>;
export const AssessmentCycleWithEmployerAnswersDocument = gql`
    query assessmentCycleWithEmployerAnswers($id: ID!) {
  assessmentCycle(id: $id) {
    id
    updatedAt
    employerAnswers {
      questionId
      questionText
      computedResponse
    }
  }
}
    `;

/**
 * __useAssessmentCycleWithEmployerAnswers__
 *
 * To run a query within a React component, call `useAssessmentCycleWithEmployerAnswers` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycleWithEmployerAnswers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycleWithEmployerAnswers({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentCycleWithEmployerAnswers(baseOptions: Apollo.QueryHookOptions<AssessmentCycleWithEmployerAnswers, AssessmentCycleWithEmployerAnswersVariables>) {
        return Apollo.useQuery<AssessmentCycleWithEmployerAnswers, AssessmentCycleWithEmployerAnswersVariables>(AssessmentCycleWithEmployerAnswersDocument, baseOptions);
      }
export function useAssessmentCycleWithEmployerAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycleWithEmployerAnswers, AssessmentCycleWithEmployerAnswersVariables>) {
          return Apollo.useLazyQuery<AssessmentCycleWithEmployerAnswers, AssessmentCycleWithEmployerAnswersVariables>(AssessmentCycleWithEmployerAnswersDocument, baseOptions);
        }
export type AssessmentCycleWithEmployerAnswersHookResult = ReturnType<typeof useAssessmentCycleWithEmployerAnswers>;
export type AssessmentCycleWithEmployerAnswersLazyQueryHookResult = ReturnType<typeof useAssessmentCycleWithEmployerAnswersLazyQuery>;
export type AssessmentCycleWithEmployerAnswersQueryResult = Apollo.QueryResult<AssessmentCycleWithEmployerAnswers, AssessmentCycleWithEmployerAnswersVariables>;
export const AssessmentCycleWithRemindersDocument = gql`
    query assessmentCycleWithReminders($id: ID!) {
  assessmentCycle(id: $id) {
    id
    inviteBody
    startDate
    inviteSubject
    inviteTextMessage
    reminders {
      id
      scheduledAt
      content
      subject
      textMessage
    }
  }
}
    `;

/**
 * __useAssessmentCycleWithReminders__
 *
 * To run a query within a React component, call `useAssessmentCycleWithReminders` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycleWithReminders` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycleWithReminders({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentCycleWithReminders(baseOptions: Apollo.QueryHookOptions<AssessmentCycleWithReminders, AssessmentCycleWithRemindersVariables>) {
        return Apollo.useQuery<AssessmentCycleWithReminders, AssessmentCycleWithRemindersVariables>(AssessmentCycleWithRemindersDocument, baseOptions);
      }
export function useAssessmentCycleWithRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycleWithReminders, AssessmentCycleWithRemindersVariables>) {
          return Apollo.useLazyQuery<AssessmentCycleWithReminders, AssessmentCycleWithRemindersVariables>(AssessmentCycleWithRemindersDocument, baseOptions);
        }
export type AssessmentCycleWithRemindersHookResult = ReturnType<typeof useAssessmentCycleWithReminders>;
export type AssessmentCycleWithRemindersLazyQueryHookResult = ReturnType<typeof useAssessmentCycleWithRemindersLazyQuery>;
export type AssessmentCycleWithRemindersQueryResult = Apollo.QueryResult<AssessmentCycleWithReminders, AssessmentCycleWithRemindersVariables>;
export const AssesmentCycleWithReportRequestDocument = gql`
    query assesmentCycleWithReportRequest($id: ID!) {
  assessmentCycle(id: $id) {
    id
    reportRequest {
      dateRequested
      subject
      request
    }
  }
}
    `;

/**
 * __useAssesmentCycleWithReportRequest__
 *
 * To run a query within a React component, call `useAssesmentCycleWithReportRequest` and pass it any options that fit your needs.
 * When your component renders, `useAssesmentCycleWithReportRequest` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssesmentCycleWithReportRequest({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssesmentCycleWithReportRequest(baseOptions: Apollo.QueryHookOptions<AssesmentCycleWithReportRequest, AssesmentCycleWithReportRequestVariables>) {
        return Apollo.useQuery<AssesmentCycleWithReportRequest, AssesmentCycleWithReportRequestVariables>(AssesmentCycleWithReportRequestDocument, baseOptions);
      }
export function useAssesmentCycleWithReportRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssesmentCycleWithReportRequest, AssesmentCycleWithReportRequestVariables>) {
          return Apollo.useLazyQuery<AssesmentCycleWithReportRequest, AssesmentCycleWithReportRequestVariables>(AssesmentCycleWithReportRequestDocument, baseOptions);
        }
export type AssesmentCycleWithReportRequestHookResult = ReturnType<typeof useAssesmentCycleWithReportRequest>;
export type AssesmentCycleWithReportRequestLazyQueryHookResult = ReturnType<typeof useAssesmentCycleWithReportRequestLazyQuery>;
export type AssesmentCycleWithReportRequestQueryResult = Apollo.QueryResult<AssesmentCycleWithReportRequest, AssesmentCycleWithReportRequestVariables>;
export const AssessmentCycleWithReportsDocument = gql`
    query assessmentCycleWithReports($id: ID!) {
  assessmentCycle(id: $id) {
    id
    reports {
      id
      status
      error
      createdAt
      requestedAt
      gcsFile {
        url
      }
    }
  }
}
    `;

/**
 * __useAssessmentCycleWithReports__
 *
 * To run a query within a React component, call `useAssessmentCycleWithReports` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycleWithReports` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycleWithReports({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentCycleWithReports(baseOptions: Apollo.QueryHookOptions<AssessmentCycleWithReports, AssessmentCycleWithReportsVariables>) {
        return Apollo.useQuery<AssessmentCycleWithReports, AssessmentCycleWithReportsVariables>(AssessmentCycleWithReportsDocument, baseOptions);
      }
export function useAssessmentCycleWithReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycleWithReports, AssessmentCycleWithReportsVariables>) {
          return Apollo.useLazyQuery<AssessmentCycleWithReports, AssessmentCycleWithReportsVariables>(AssessmentCycleWithReportsDocument, baseOptions);
        }
export type AssessmentCycleWithReportsHookResult = ReturnType<typeof useAssessmentCycleWithReports>;
export type AssessmentCycleWithReportsLazyQueryHookResult = ReturnType<typeof useAssessmentCycleWithReportsLazyQuery>;
export type AssessmentCycleWithReportsQueryResult = Apollo.QueryResult<AssessmentCycleWithReports, AssessmentCycleWithReportsVariables>;
export const AssessmentCycleWithScorecardsDocument = gql`
    query assessmentCycleWithScorecards($id: ID!) {
  assessmentCycle(id: $id) {
    id
    scorecards {
      id
      status
      error
      createdAt
      requestedAt
      gcsFile {
        url
      }
    }
  }
}
    `;

/**
 * __useAssessmentCycleWithScorecards__
 *
 * To run a query within a React component, call `useAssessmentCycleWithScorecards` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycleWithScorecards` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycleWithScorecards({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentCycleWithScorecards(baseOptions: Apollo.QueryHookOptions<AssessmentCycleWithScorecards, AssessmentCycleWithScorecardsVariables>) {
        return Apollo.useQuery<AssessmentCycleWithScorecards, AssessmentCycleWithScorecardsVariables>(AssessmentCycleWithScorecardsDocument, baseOptions);
      }
export function useAssessmentCycleWithScorecardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycleWithScorecards, AssessmentCycleWithScorecardsVariables>) {
          return Apollo.useLazyQuery<AssessmentCycleWithScorecards, AssessmentCycleWithScorecardsVariables>(AssessmentCycleWithScorecardsDocument, baseOptions);
        }
export type AssessmentCycleWithScorecardsHookResult = ReturnType<typeof useAssessmentCycleWithScorecards>;
export type AssessmentCycleWithScorecardsLazyQueryHookResult = ReturnType<typeof useAssessmentCycleWithScorecardsLazyQuery>;
export type AssessmentCycleWithScorecardsQueryResult = Apollo.QueryResult<AssessmentCycleWithScorecards, AssessmentCycleWithScorecardsVariables>;
export const AssessmentCycleWithWellbeingPrioritiesDocument = gql`
    query assessmentCycleWithWellbeingPriorities($id: ID!, $rankFilter: RankFilterInput, $organisationUnitFilter: [OrganisationUnitFilterInput!]!) {
  assessmentCycle(id: $id) {
    id
    wellbeingPriorities(
      rankFilter: $rankFilter
      organisationUnitFilter: $organisationUnitFilter
    ) {
      scoreId
      name
      value
      level
      scalingFactor
      facets {
        scoreId
        score
        scalingFactor
        level
        name
        rank
      }
    }
  }
}
    `;

/**
 * __useAssessmentCycleWithWellbeingPriorities__
 *
 * To run a query within a React component, call `useAssessmentCycleWithWellbeingPriorities` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycleWithWellbeingPriorities` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycleWithWellbeingPriorities({
 *   variables: {
 *      id: // value for 'id'
 *      rankFilter: // value for 'rankFilter'
 *      organisationUnitFilter: // value for 'organisationUnitFilter'
 *   },
 * });
 */
export function useAssessmentCycleWithWellbeingPriorities(baseOptions: Apollo.QueryHookOptions<AssessmentCycleWithWellbeingPriorities, AssessmentCycleWithWellbeingPrioritiesVariables>) {
        return Apollo.useQuery<AssessmentCycleWithWellbeingPriorities, AssessmentCycleWithWellbeingPrioritiesVariables>(AssessmentCycleWithWellbeingPrioritiesDocument, baseOptions);
      }
export function useAssessmentCycleWithWellbeingPrioritiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycleWithWellbeingPriorities, AssessmentCycleWithWellbeingPrioritiesVariables>) {
          return Apollo.useLazyQuery<AssessmentCycleWithWellbeingPriorities, AssessmentCycleWithWellbeingPrioritiesVariables>(AssessmentCycleWithWellbeingPrioritiesDocument, baseOptions);
        }
export type AssessmentCycleWithWellbeingPrioritiesHookResult = ReturnType<typeof useAssessmentCycleWithWellbeingPriorities>;
export type AssessmentCycleWithWellbeingPrioritiesLazyQueryHookResult = ReturnType<typeof useAssessmentCycleWithWellbeingPrioritiesLazyQuery>;
export type AssessmentCycleWithWellbeingPrioritiesQueryResult = Apollo.QueryResult<AssessmentCycleWithWellbeingPriorities, AssessmentCycleWithWellbeingPrioritiesVariables>;
export const AssessmentCycleWithWellbeingScoresDocument = gql`
    query assessmentCycleWithWellbeingScores($id: ID!, $organisationUnitFilter: [OrganisationUnitFilterInput!]!) {
  assessmentCycle(id: $id) {
    id
    wellbeingScores(organisationUnitFilter: $organisationUnitFilter) {
      name
      value
      scoreId
      scalingFactor
      domains {
        scoreId
        level
        name
        value
        min
        max
        scalingFactor
        subScores {
          scoreId
          score
          scalingFactor
          level
          name
          rank
          min
          max
        }
      }
    }
  }
}
    `;

/**
 * __useAssessmentCycleWithWellbeingScores__
 *
 * To run a query within a React component, call `useAssessmentCycleWithWellbeingScores` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycleWithWellbeingScores` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycleWithWellbeingScores({
 *   variables: {
 *      id: // value for 'id'
 *      organisationUnitFilter: // value for 'organisationUnitFilter'
 *   },
 * });
 */
export function useAssessmentCycleWithWellbeingScores(baseOptions: Apollo.QueryHookOptions<AssessmentCycleWithWellbeingScores, AssessmentCycleWithWellbeingScoresVariables>) {
        return Apollo.useQuery<AssessmentCycleWithWellbeingScores, AssessmentCycleWithWellbeingScoresVariables>(AssessmentCycleWithWellbeingScoresDocument, baseOptions);
      }
export function useAssessmentCycleWithWellbeingScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycleWithWellbeingScores, AssessmentCycleWithWellbeingScoresVariables>) {
          return Apollo.useLazyQuery<AssessmentCycleWithWellbeingScores, AssessmentCycleWithWellbeingScoresVariables>(AssessmentCycleWithWellbeingScoresDocument, baseOptions);
        }
export type AssessmentCycleWithWellbeingScoresHookResult = ReturnType<typeof useAssessmentCycleWithWellbeingScores>;
export type AssessmentCycleWithWellbeingScoresLazyQueryHookResult = ReturnType<typeof useAssessmentCycleWithWellbeingScoresLazyQuery>;
export type AssessmentCycleWithWellbeingScoresQueryResult = Apollo.QueryResult<AssessmentCycleWithWellbeingScores, AssessmentCycleWithWellbeingScoresVariables>;
export const AssessmentCycleWithWordCloudDocument = gql`
    query assessmentCycleWithWordCloud($id: ID!) {
  assessmentCycle(id: $id) {
    id
    wordCloud {
      text
      value
    }
  }
}
    `;

/**
 * __useAssessmentCycleWithWordCloud__
 *
 * To run a query within a React component, call `useAssessmentCycleWithWordCloud` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCycleWithWordCloud` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCycleWithWordCloud({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentCycleWithWordCloud(baseOptions: Apollo.QueryHookOptions<AssessmentCycleWithWordCloud, AssessmentCycleWithWordCloudVariables>) {
        return Apollo.useQuery<AssessmentCycleWithWordCloud, AssessmentCycleWithWordCloudVariables>(AssessmentCycleWithWordCloudDocument, baseOptions);
      }
export function useAssessmentCycleWithWordCloudLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCycleWithWordCloud, AssessmentCycleWithWordCloudVariables>) {
          return Apollo.useLazyQuery<AssessmentCycleWithWordCloud, AssessmentCycleWithWordCloudVariables>(AssessmentCycleWithWordCloudDocument, baseOptions);
        }
export type AssessmentCycleWithWordCloudHookResult = ReturnType<typeof useAssessmentCycleWithWordCloud>;
export type AssessmentCycleWithWordCloudLazyQueryHookResult = ReturnType<typeof useAssessmentCycleWithWordCloudLazyQuery>;
export type AssessmentCycleWithWordCloudQueryResult = Apollo.QueryResult<AssessmentCycleWithWordCloud, AssessmentCycleWithWordCloudVariables>;
export const AllAssessmentCyclesDocument = gql`
    query allAssessmentCycles {
  assessmentCycles {
    id
    startDate
    completedAt
    participationResults {
      totalEmployeeCount
      completedEmployeeCount
      completionRate
    }
    reminders {
      id
      createdAt
      creatorId
      sentAt
      content
      scheduledAt
    }
  }
}
    `;

/**
 * __useAllAssessmentCycles__
 *
 * To run a query within a React component, call `useAllAssessmentCycles` and pass it any options that fit your needs.
 * When your component renders, `useAllAssessmentCycles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAssessmentCycles({
 *   variables: {
 *   },
 * });
 */
export function useAllAssessmentCycles(baseOptions?: Apollo.QueryHookOptions<AllAssessmentCycles, AllAssessmentCyclesVariables>) {
        return Apollo.useQuery<AllAssessmentCycles, AllAssessmentCyclesVariables>(AllAssessmentCyclesDocument, baseOptions);
      }
export function useAllAssessmentCyclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAssessmentCycles, AllAssessmentCyclesVariables>) {
          return Apollo.useLazyQuery<AllAssessmentCycles, AllAssessmentCyclesVariables>(AllAssessmentCyclesDocument, baseOptions);
        }
export type AllAssessmentCyclesHookResult = ReturnType<typeof useAllAssessmentCycles>;
export type AllAssessmentCyclesLazyQueryHookResult = ReturnType<typeof useAllAssessmentCyclesLazyQuery>;
export type AllAssessmentCyclesQueryResult = Apollo.QueryResult<AllAssessmentCycles, AllAssessmentCyclesVariables>;
export const AssessmentCyclesWithDateDocument = gql`
    query assessmentCyclesWithDate {
  assessmentCycles {
    id
    createdAt
    startDate
    completedAt
    assessments {
      id
      status
    }
  }
}
    `;

/**
 * __useAssessmentCyclesWithDate__
 *
 * To run a query within a React component, call `useAssessmentCyclesWithDate` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCyclesWithDate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCyclesWithDate({
 *   variables: {
 *   },
 * });
 */
export function useAssessmentCyclesWithDate(baseOptions?: Apollo.QueryHookOptions<AssessmentCyclesWithDate, AssessmentCyclesWithDateVariables>) {
        return Apollo.useQuery<AssessmentCyclesWithDate, AssessmentCyclesWithDateVariables>(AssessmentCyclesWithDateDocument, baseOptions);
      }
export function useAssessmentCyclesWithDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCyclesWithDate, AssessmentCyclesWithDateVariables>) {
          return Apollo.useLazyQuery<AssessmentCyclesWithDate, AssessmentCyclesWithDateVariables>(AssessmentCyclesWithDateDocument, baseOptions);
        }
export type AssessmentCyclesWithDateHookResult = ReturnType<typeof useAssessmentCyclesWithDate>;
export type AssessmentCyclesWithDateLazyQueryHookResult = ReturnType<typeof useAssessmentCyclesWithDateLazyQuery>;
export type AssessmentCyclesWithDateQueryResult = Apollo.QueryResult<AssessmentCyclesWithDate, AssessmentCyclesWithDateVariables>;
export const AssessmentCyclesWithQuadrantScoresDocument = gql`
    query assessmentCyclesWithQuadrantScores($id: ID!) {
  assessmentCycle(id: $id) {
    id
    quadrantScores {
      scoreId
      mean
      stdev
    }
  }
}
    `;

/**
 * __useAssessmentCyclesWithQuadrantScores__
 *
 * To run a query within a React component, call `useAssessmentCyclesWithQuadrantScores` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentCyclesWithQuadrantScores` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentCyclesWithQuadrantScores({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssessmentCyclesWithQuadrantScores(baseOptions: Apollo.QueryHookOptions<AssessmentCyclesWithQuadrantScores, AssessmentCyclesWithQuadrantScoresVariables>) {
        return Apollo.useQuery<AssessmentCyclesWithQuadrantScores, AssessmentCyclesWithQuadrantScoresVariables>(AssessmentCyclesWithQuadrantScoresDocument, baseOptions);
      }
export function useAssessmentCyclesWithQuadrantScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentCyclesWithQuadrantScores, AssessmentCyclesWithQuadrantScoresVariables>) {
          return Apollo.useLazyQuery<AssessmentCyclesWithQuadrantScores, AssessmentCyclesWithQuadrantScoresVariables>(AssessmentCyclesWithQuadrantScoresDocument, baseOptions);
        }
export type AssessmentCyclesWithQuadrantScoresHookResult = ReturnType<typeof useAssessmentCyclesWithQuadrantScores>;
export type AssessmentCyclesWithQuadrantScoresLazyQueryHookResult = ReturnType<typeof useAssessmentCyclesWithQuadrantScoresLazyQuery>;
export type AssessmentCyclesWithQuadrantScoresQueryResult = Apollo.QueryResult<AssessmentCyclesWithQuadrantScores, AssessmentCyclesWithQuadrantScoresVariables>;
export const DemographicsDataDocument = gql`
    query demographicsData($assessmentCycleId: ID!, $organisationUnitFilter: [OrganisationUnitFilterInput!]) {
  demographics(
    assessmentCycleId: $assessmentCycleId
    organisationUnitFilter: $organisationUnitFilter
  ) {
    questionId
    answersWithCount {
      rawResponse
      count
    }
  }
}
    `;

/**
 * __useDemographicsData__
 *
 * To run a query within a React component, call `useDemographicsData` and pass it any options that fit your needs.
 * When your component renders, `useDemographicsData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemographicsData({
 *   variables: {
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *      organisationUnitFilter: // value for 'organisationUnitFilter'
 *   },
 * });
 */
export function useDemographicsData(baseOptions: Apollo.QueryHookOptions<DemographicsData, DemographicsDataVariables>) {
        return Apollo.useQuery<DemographicsData, DemographicsDataVariables>(DemographicsDataDocument, baseOptions);
      }
export function useDemographicsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DemographicsData, DemographicsDataVariables>) {
          return Apollo.useLazyQuery<DemographicsData, DemographicsDataVariables>(DemographicsDataDocument, baseOptions);
        }
export type DemographicsDataHookResult = ReturnType<typeof useDemographicsData>;
export type DemographicsDataLazyQueryHookResult = ReturnType<typeof useDemographicsDataLazyQuery>;
export type DemographicsDataQueryResult = Apollo.QueryResult<DemographicsData, DemographicsDataVariables>;
export const OrganisationEmployeesQueryDocument = gql`
    query OrganisationEmployeesQuery {
  employees {
    results {
      firebaseId
      firstName
      emailAddress
      contactNumber
      attributes
    }
  }
}
    `;

/**
 * __useOrganisationEmployeesQuery__
 *
 * To run a query within a React component, call `useOrganisationEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationEmployeesQuery, OrganisationEmployeesQueryVariables>) {
        return Apollo.useQuery<OrganisationEmployeesQuery, OrganisationEmployeesQueryVariables>(OrganisationEmployeesQueryDocument, baseOptions);
      }
export function useOrganisationEmployeesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationEmployeesQuery, OrganisationEmployeesQueryVariables>) {
          return Apollo.useLazyQuery<OrganisationEmployeesQuery, OrganisationEmployeesQueryVariables>(OrganisationEmployeesQueryDocument, baseOptions);
        }
export type OrganisationEmployeesQueryHookResult = ReturnType<typeof useOrganisationEmployeesQuery>;
export type OrganisationEmployeesQueryLazyQueryHookResult = ReturnType<typeof useOrganisationEmployeesQueryLazyQuery>;
export type OrganisationEmployeesQueryQueryResult = Apollo.QueryResult<OrganisationEmployeesQuery, OrganisationEmployeesQueryVariables>;
export const OceanScoresDocument = gql`
    query oceanScores($assessmentCycleId: ID!, $organisationUnitFilter: [OrganisationUnitFilterInput!]) {
  oceanScores(
    assessmentCycleId: $assessmentCycleId
    organisationUnitFilter: $organisationUnitFilter
  ) {
    oceanScores {
      scoreId
      mean
      min
      max
    }
  }
}
    `;

/**
 * __useOceanScores__
 *
 * To run a query within a React component, call `useOceanScores` and pass it any options that fit your needs.
 * When your component renders, `useOceanScores` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOceanScores({
 *   variables: {
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *      organisationUnitFilter: // value for 'organisationUnitFilter'
 *   },
 * });
 */
export function useOceanScores(baseOptions: Apollo.QueryHookOptions<OceanScores, OceanScoresVariables>) {
        return Apollo.useQuery<OceanScores, OceanScoresVariables>(OceanScoresDocument, baseOptions);
      }
export function useOceanScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OceanScores, OceanScoresVariables>) {
          return Apollo.useLazyQuery<OceanScores, OceanScoresVariables>(OceanScoresDocument, baseOptions);
        }
export type OceanScoresHookResult = ReturnType<typeof useOceanScores>;
export type OceanScoresLazyQueryHookResult = ReturnType<typeof useOceanScoresLazyQuery>;
export type OceanScoresQueryResult = Apollo.QueryResult<OceanScores, OceanScoresVariables>;
export const OceanScores2Document = gql`
    query oceanScores2($assessmentCycleId: ID!, $organisationUnitFilter: [OrganisationUnitFilterInput!]) {
  oceanScores2(
    assessmentCycleId: $assessmentCycleId
    organisationUnitFilter: $organisationUnitFilter
  ) {
    traitsCount
    summedData {
      scoreId
      symbol
      high
      low
    }
  }
}
    `;

/**
 * __useOceanScores2__
 *
 * To run a query within a React component, call `useOceanScores2` and pass it any options that fit your needs.
 * When your component renders, `useOceanScores2` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOceanScores2({
 *   variables: {
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *      organisationUnitFilter: // value for 'organisationUnitFilter'
 *   },
 * });
 */
export function useOceanScores2(baseOptions: Apollo.QueryHookOptions<OceanScores2, OceanScores2Variables>) {
        return Apollo.useQuery<OceanScores2, OceanScores2Variables>(OceanScores2Document, baseOptions);
      }
export function useOceanScores2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OceanScores2, OceanScores2Variables>) {
          return Apollo.useLazyQuery<OceanScores2, OceanScores2Variables>(OceanScores2Document, baseOptions);
        }
export type OceanScores2HookResult = ReturnType<typeof useOceanScores2>;
export type OceanScores2LazyQueryHookResult = ReturnType<typeof useOceanScores2LazyQuery>;
export type OceanScores2QueryResult = Apollo.QueryResult<OceanScores2, OceanScores2Variables>;
export const OrganisationQueryDocument = gql`
    query OrganisationQuery($orgId: ID!) {
  organisation(id: $orgId) {
    organisation {
      assessmentCycles {
        id
        organisationId
      }
      organisationUnits {
        attributeName
        units {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useOrganisationQuery__
 *
 * To run a query within a React component, call `useOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useOrganisationQuery(baseOptions: Apollo.QueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
        return Apollo.useQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationQueryDocument, baseOptions);
      }
export function useOrganisationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
          return Apollo.useLazyQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationQueryDocument, baseOptions);
        }
export type OrganisationQueryHookResult = ReturnType<typeof useOrganisationQuery>;
export type OrganisationQueryLazyQueryHookResult = ReturnType<typeof useOrganisationQueryLazyQuery>;
export type OrganisationQueryQueryResult = Apollo.QueryResult<OrganisationQuery, OrganisationQueryVariables>;
export const ParticipationPerUnitsDocument = gql`
    query participationPerUnits($assessmentCycleId: ID!, $organisationUnitFilter: [OrganisationUnitFilterInput!], $fullCompany: Boolean) {
  participationPerUnits(
    assessmentCycleId: $assessmentCycleId
    organisationUnitFilter: $organisationUnitFilter
    fullCompany: $fullCompany
  ) {
    attributeName
    unit
    totalEmployeeCount
    completedEmployeeCount
    startedEmployeeCount
  }
}
    `;

/**
 * __useParticipationPerUnits__
 *
 * To run a query within a React component, call `useParticipationPerUnits` and pass it any options that fit your needs.
 * When your component renders, `useParticipationPerUnits` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipationPerUnits({
 *   variables: {
 *      assessmentCycleId: // value for 'assessmentCycleId'
 *      organisationUnitFilter: // value for 'organisationUnitFilter'
 *      fullCompany: // value for 'fullCompany'
 *   },
 * });
 */
export function useParticipationPerUnits(baseOptions: Apollo.QueryHookOptions<ParticipationPerUnits, ParticipationPerUnitsVariables>) {
        return Apollo.useQuery<ParticipationPerUnits, ParticipationPerUnitsVariables>(ParticipationPerUnitsDocument, baseOptions);
      }
export function useParticipationPerUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipationPerUnits, ParticipationPerUnitsVariables>) {
          return Apollo.useLazyQuery<ParticipationPerUnits, ParticipationPerUnitsVariables>(ParticipationPerUnitsDocument, baseOptions);
        }
export type ParticipationPerUnitsHookResult = ReturnType<typeof useParticipationPerUnits>;
export type ParticipationPerUnitsLazyQueryHookResult = ReturnType<typeof useParticipationPerUnitsLazyQuery>;
export type ParticipationPerUnitsQueryResult = Apollo.QueryResult<ParticipationPerUnits, ParticipationPerUnitsVariables>;
export const EmployeeReportDocument = gql`
    query employeeReport($id: String!) {
  employeeReport(id: $id) {
    downloadUrl
  }
}
    `;

/**
 * __useEmployeeReport__
 *
 * To run a query within a React component, call `useEmployeeReport` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeReport({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeReport(baseOptions: Apollo.QueryHookOptions<EmployeeReport, EmployeeReportVariables>) {
        return Apollo.useQuery<EmployeeReport, EmployeeReportVariables>(EmployeeReportDocument, baseOptions);
      }
export function useEmployeeReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeReport, EmployeeReportVariables>) {
          return Apollo.useLazyQuery<EmployeeReport, EmployeeReportVariables>(EmployeeReportDocument, baseOptions);
        }
export type EmployeeReportHookResult = ReturnType<typeof useEmployeeReport>;
export type EmployeeReportLazyQueryHookResult = ReturnType<typeof useEmployeeReportLazyQuery>;
export type EmployeeReportQueryResult = Apollo.QueryResult<EmployeeReport, EmployeeReportVariables>;
export const EmployerReportDocument = gql`
    query employerReport($options: EmployerReportInput!) {
  employerReport(options: $options) {
    downloadUrl
  }
}
    `;

/**
 * __useEmployerReport__
 *
 * To run a query within a React component, call `useEmployerReport` and pass it any options that fit your needs.
 * When your component renders, `useEmployerReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerReport({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useEmployerReport(baseOptions: Apollo.QueryHookOptions<EmployerReport, EmployerReportVariables>) {
        return Apollo.useQuery<EmployerReport, EmployerReportVariables>(EmployerReportDocument, baseOptions);
      }
export function useEmployerReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployerReport, EmployerReportVariables>) {
          return Apollo.useLazyQuery<EmployerReport, EmployerReportVariables>(EmployerReportDocument, baseOptions);
        }
export type EmployerReportHookResult = ReturnType<typeof useEmployerReport>;
export type EmployerReportLazyQueryHookResult = ReturnType<typeof useEmployerReportLazyQuery>;
export type EmployerReportQueryResult = Apollo.QueryResult<EmployerReport, EmployerReportVariables>;
export const EmployerScorecardDocument = gql`
    query employerScorecard($options: EmployerScorecardInput!) {
  employerScorecard(options: $options) {
    downloadUrl
  }
}
    `;

/**
 * __useEmployerScorecard__
 *
 * To run a query within a React component, call `useEmployerScorecard` and pass it any options that fit your needs.
 * When your component renders, `useEmployerScorecard` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerScorecard({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useEmployerScorecard(baseOptions: Apollo.QueryHookOptions<EmployerScorecard, EmployerScorecardVariables>) {
        return Apollo.useQuery<EmployerScorecard, EmployerScorecardVariables>(EmployerScorecardDocument, baseOptions);
      }
export function useEmployerScorecardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployerScorecard, EmployerScorecardVariables>) {
          return Apollo.useLazyQuery<EmployerScorecard, EmployerScorecardVariables>(EmployerScorecardDocument, baseOptions);
        }
export type EmployerScorecardHookResult = ReturnType<typeof useEmployerScorecard>;
export type EmployerScorecardLazyQueryHookResult = ReturnType<typeof useEmployerScorecardLazyQuery>;
export type EmployerScorecardQueryResult = Apollo.QueryResult<EmployerScorecard, EmployerScorecardVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    id
    firstName
    surname
    title
    contactNumber
    email
    firebaseId
    picture
    completedSetup
    organisationId
    acceptedGdprAt
    attributes
    organisation {
      id
      name
      contactNumber
      uid
      companyContactNumber
      financeName
      financeEmail
      typeOfBusiness
      addressLine1
      logoUrl
      addressLine2
      addressLine3
      postcode
      completedSetup
      hasMFA
    }
  }
}
    `;

/**
 * __useSelf__
 *
 * To run a query within a React component, call `useSelf` and pass it any options that fit your needs.
 * When your component renders, `useSelf` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelf({
 *   variables: {
 *   },
 * });
 */
export function useSelf(baseOptions?: Apollo.QueryHookOptions<Self, SelfVariables>) {
        return Apollo.useQuery<Self, SelfVariables>(SelfDocument, baseOptions);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Self, SelfVariables>) {
          return Apollo.useLazyQuery<Self, SelfVariables>(SelfDocument, baseOptions);
        }
export type SelfHookResult = ReturnType<typeof useSelf>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<Self, SelfVariables>;
export const IsUserInOrganisationWithMfaDocument = gql`
    query isUserInOrganisationWithMFA($phoneNumber: String!) {
  isUserInOrganisationWithMFA(phoneNumber: $phoneNumber)
}
    `;

/**
 * __useIsUserInOrganisationWithMfa__
 *
 * To run a query within a React component, call `useIsUserInOrganisationWithMfa` and pass it any options that fit your needs.
 * When your component renders, `useIsUserInOrganisationWithMfa` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserInOrganisationWithMfa({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsUserInOrganisationWithMfa(baseOptions: Apollo.QueryHookOptions<IsUserInOrganisationWithMfa, IsUserInOrganisationWithMfaVariables>) {
        return Apollo.useQuery<IsUserInOrganisationWithMfa, IsUserInOrganisationWithMfaVariables>(IsUserInOrganisationWithMfaDocument, baseOptions);
      }
export function useIsUserInOrganisationWithMfaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserInOrganisationWithMfa, IsUserInOrganisationWithMfaVariables>) {
          return Apollo.useLazyQuery<IsUserInOrganisationWithMfa, IsUserInOrganisationWithMfaVariables>(IsUserInOrganisationWithMfaDocument, baseOptions);
        }
export type IsUserInOrganisationWithMfaHookResult = ReturnType<typeof useIsUserInOrganisationWithMfa>;
export type IsUserInOrganisationWithMfaLazyQueryHookResult = ReturnType<typeof useIsUserInOrganisationWithMfaLazyQuery>;
export type IsUserInOrganisationWithMfaQueryResult = Apollo.QueryResult<IsUserInOrganisationWithMfa, IsUserInOrganisationWithMfaVariables>;