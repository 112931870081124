import { Input as ChakraInput, InputProps } from '@chakra-ui/react';
import { FC } from 'react';

const Input: FC<{ register?: React.Ref<any> } & InputProps> = ({
  register,
  colorScheme = 'yellow',
  ...props
}) => {
  const borderBottomColor =
    colorScheme === 'green' ? 'green.500' : 'yellow.400';

  return (
    <ChakraInput
      borderBottom="1px solid"
      borderBottomColor={borderBottomColor}
      borderRadius="0"
      marginBottom="0"
      ref={register}
      size="md"
      {...props}
    />
  );
};

export default Input;
