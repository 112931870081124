import * as postcodesIo from 'node-postcodes.io';
import type { UseFormMethods } from 'react-hook-form';
import * as yup from 'yup';

const prefixRegex = new RegExp('^(\\+44|\\+48)');
const phoneNumberRegexGB = new RegExp('^(^[7]{1}[0-9]{9})$');
const phoneNumberRegexPL = new RegExp('^(^[1-9]{1}[0-9]{8})$');
const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9\W]).+$/);

const setInitialFormValues = (
  fields: string[],
  numberFiled: string,
  setValue: UseFormMethods['setValue'],
  data: Record<string, any>,
) => {
  fields.forEach(field => {
    setValue(field, data[field]);
    if (field === numberFiled) {
      if (data[field] !== null) {
        const splitNumber = data[field].split(prefixRegex).filter(Boolean);

        setValue(
          `${numberFiled}Prefix`,
          splitNumber.length === 2 ? splitNumber[0] : '0',
        );
        setValue(
          `${numberFiled}Number`,
          splitNumber.length === 2 ? splitNumber[1] : '',
        );
      } else {
        setValue(`${numberFiled}Prefix`, '');
        setValue(`${numberFiled}Number`, '');
      }
    }
  });
};

const validatePostcode = async (text: string): Promise<boolean> => {
  try {
    if (text === '') {
      return true;
    }
    const results = await postcodesIo.validate(text);
    return results.result;
  } catch (e) {
    return false;
  }
};

const yupPasswordValidationSchema = yup
  .string()
  .min(8)
  .matches(
    passwordRegex,
    'password must contain at least one lowercase letter, one uppercase letter, one number or symbol',
  )
  .required('Please enter a password');

const yupPasswordRepeatValidationSchema = yup
  .string()
  .oneOf([yup.ref('password'), null], 'Passwords must match')
  .required('Please enter a password confirmation');

const yupPrefixValidationSchema = yup
  .string()
  .required('Prefix number is required');

const getYupNumberValidationSchema = (prefixFieldName: string) =>
  yup
    .string()
    .when(prefixFieldName, {
      is: '+44',
      then: yup.string().matches(phoneNumberRegexGB, {
        message: 'Incorrect format for the phone number',
        excludeEmptyString: true,
      }),
      otherwise: yup.string().matches(phoneNumberRegexPL, {
        message: 'Incorrect format for the phone number',
        excludeEmptyString: true,
      }),
    })
    .required('Phone number is required');

const yupPostcodeValidationSchema = yup
  .string()
  .test('check postcode', 'Invalid postcode', async value =>
    validatePostcode(value),
  );

export {
  setInitialFormValues,
  yupPasswordValidationSchema,
  yupPasswordRepeatValidationSchema,
  yupPrefixValidationSchema,
  getYupNumberValidationSchema,
  yupPostcodeValidationSchema,
};
