import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '576px',
  md: '770px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1200px',
});

export default breakpoints;
