import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: props => ({
    '*': {
      boxSizing: 'border-box',
    },
    html: {
      height: '100%',
      fontFamily: 'body',
      fontSize: 'base',
      backgroundColor: 'bg',
      color: 'text',
    },

    body: {
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      margin: '0px',
    },

    '.locked': {
      margin: '0',
      height: '100%',
      overflow: 'hidden',
    },

    // Utils
    '.disable-scroll': {
      overflowY: 'hidden',
    },

    '.page-break-before': {
      pageBreakBefore: 'always',
    },

    '.page-break-after': {
      pageBreakAfter: 'always',
    },

    '#__next': {
      height: '100%',
    },

    blockquote: {
      backgroundColor: 'gray.100',
      borderRadius: '5px',
      margin: '10px',
      padding: '10px',
    },

    '#scrollwrapper': {
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      height: '100%',

      '::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },

      '::-webkit-scrollbar:vertical': {
        width: '16px',
      },

      '::-webkit-scrollbar:horizontal': {
        height: '16px',
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'yellow.600',
        borderRadius: '8px',
        border: '2px solid',
        borderColor: 'gray.200',
      },

      '::-webkit-scrollbar-track': {
        backgroundColor: 'gray.200',
      },
    },
  }),
};

export default styles;
