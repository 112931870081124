import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  Flex,
  Select,
} from '@chakra-ui/react';
import React from 'react';
import type { UseFormMethods } from 'react-hook-form';

import Input from '../inputs/Input';
const phoneNumberPrefixes = ['+44', '+48'];

interface IPhoneNumberField
  extends InputProps,
    Partial<Pick<UseFormMethods, 'errors' | 'register'>> {
  name: string;
  color: string;
  placeholder: string;
  id: string;
  selectBackgroundColor?: string;
  // TODO: The label prop shouldn't be optional but this requires replacing placeholder in the label section in more components
  label?: string;
}

const PhoneNumberField = ({
  placeholder,
  color,
  id,
  errors,
  register,
  name,
  colorScheme = 'green',
  selectBackgroundColor,
  label,
  isReadOnly = false,
  hidden,
  ...props
}: IPhoneNumberField) => {
  const calculatedColor = colorScheme === 'green' ? 'green.500' : 'yellow.400';

  return (
    <FormControl
      isInvalid={
        Boolean(errors[`${name}Prefix`]) || Boolean(errors[`${name}Number`])
      }
      mb="3"
      width="full"
      hidden={hidden}
    >
      <FormLabel color={color} fontSize="sm" htmlFor={id} mb="0">
        {/* This should display only label prop but it requires fixes in every instance of the component and aligning other components to the same convention */}
        {label ?? placeholder}
      </FormLabel>
      <Flex align="end" {...props} paddingBottom="16px">
        <Select
          color={color}
          cursor="pointer"
          borderColor={calculatedColor}
          focusBorderColor={calculatedColor}
          fontSize="base"
          marginRight="10px"
          name={`${name}Prefix`}
          ref={register}
          size="md"
          variant="flushed"
          w="60px"
        >
          {phoneNumberPrefixes.map(value => (
            <option
              key={value}
              value={value}
              style={{ backgroundColor: selectBackgroundColor }}
            >
              {value}
            </option>
          ))}
        </Select>
        <Input
          color={color}
          borderBottomColor={calculatedColor}
          focusBorderColor={calculatedColor}
          id={id}
          name={`${name}Number`}
          placeholder={placeholder}
          register={register}
          size="md"
          variant="flushed"
          isReadOnly={isReadOnly}
        />
      </Flex>
      <FormErrorMessage minH="1.5rem">
        {errors[`${name}Prefix`]?.message ?? errors[`${name}Number`]?.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default PhoneNumberField;
