import { Heading, Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';

export type TextVariants = 'dark' | 'light';

type Props = TextProps & {
  variant?: TextVariants | null;
};

export const H1: FC<Props> = ({ variant = null, ...rest }) => (
  <Heading
    as="h1"
    color={variant === 'dark' ? 'text.head' : 'white.400'}
    fontSize="xl"
    fontWeight="regular"
    mb="4"
    {...rest}
  />
);
export const H2: FC<Props> = ({ variant = null, ...rest }) => (
  <Heading
    as="h2"
    color={variant === 'dark' ? 'text.head' : 'white.400'}
    fontSize="lg"
    fontWeight="regular"
    {...rest}
  />
);
export const H3: FC<Props> = ({ variant = null, ...rest }) => (
  <Heading
    as="h3"
    color={variant === 'dark' ? 'text.head' : 'white.400'}
    fontSize="base"
    my="md"
    {...rest}
  />
);
export const H4: FC<Props> = ({ variant = null, ...rest }) => (
  <Heading
    as="h4"
    color={variant === 'dark' ? 'text.head' : 'white.400'}
    fontSize="base"
    {...rest}
  />
);

export const H5: FC<Props> = ({ variant = null, ...rest }) => (
  <Heading
    as="h5"
    color={variant === 'dark' ? 'text.head' : 'white.400'}
    fontSize="base"
    {...rest}
  />
);

export const H6: FC<Props> = ({ variant = null, ...rest }) => (
  <Heading
    as="h6"
    color={variant === 'dark' ? 'text.head' : 'white.400'}
    fontSize="base"
    {...rest}
  />
);

export const Body: FC<Props> = ({ variant = null, ...rest }) => (
  <Text
    color={variant === 'light' ? 'white.400' : 'blue.750'}
    mb="3"
    {...rest}
  />
);

export const TextError = props => (
  <Heading as="h4" color="red.400" fontSize="xs" {...props} />
);
