const config = {
  env: process.env.NODE_ENV,
  configEnv: process.env.NEXT_PUBLIC_CONFIG_ENV,
  disableConsoleLogging: process.env.NEXT_PUBLIC_DISABLE_LOGGING !== 'false',
  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID,
  },
  prismic: {
    repo: process.env.NEXT_PUBLIC_PRISMIC_REPO,
    accessToken: process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN,
  },
  prodDomain: process.env.NEXT_PUBLIC_PROD_DOMAIN,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  socialMeta: {
    baseUrl: process.env.NEXT_PUBLIC_SOCIALMETA_BASEURL,
    title: process.env.NEXT_PUBLIC_SOCIALMETA_TITLE,
    description: process.env.NEXT_PUBLIC_SOCIALMETA_DESCRIPTION,
    keywords: process.env.NEXT_PUBLIC_SOCIALMETA_KEYWORDS,
    imageUrl: process.env.NEXT_PUBLIC_SOCIALMETA_IMAGEURL,
    companyName: process.env.NEXT_PUBLIC_SOCIALMETA_COMPANYNAME,
    twitterUser: process.env.NEXT_PUBLIC_SOCIALMETA_TWITTERUSER,
  },
  title: 'Fruitful Insights',
  tileColor: '#ffce00',
  organisationEmployeesLimit: 15,
  storyblokToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  skipAssessmentValidation: process.env.NEXT_PUBLIC_SKIP_ASSESSMENT_VALIDATION,
  testerRedirectUrl: process.env.NEXT_PUBLIC_TESTER_REDIRECT_URL,
  firebaseConfig:
    process.env.NEXT_PUBLIC_FIREBASE_CONFIG &&
    JSON.parse(
      Buffer.from(
        `${process.env.NEXT_PUBLIC_FIREBASE_CONFIG}`,
        'base64',
      ).toString(),
    ),
  testHelpers: process.env.NEXT_PUBLIC_RENDER_TEST_HELPERS === 'true',
};

export default config;
