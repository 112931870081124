/* eslint-disable no-console */
import {
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorError,
  getMultiFactorResolver,
  MultiFactorResolver,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import 'firebase/compat/auth';
import config from '@/config';
import { Permissions, UserRole } from '@/generated/graphql';

export class User {
  info: firebase.User;

  roles?: UserRole[];

  rid?: string;

  permissions?: Permissions;
}

type FirebaseContext = {
  initialized: boolean;
  user: User | null;
  isLoading: boolean;
  getToken: (forceRefresh?: boolean) => Promise<string | null>;
  logout: () => Promise<void>;
  signInWithToken: (
    token: string,
  ) => Promise<firebase.auth.UserCredential | null>;
  login: (
    email: string,
    password: string,
  ) => Promise<firebase.auth.UserCredential | null>;
  loginWithPhoneNumber: (
    phoneNumber: string,
  ) => Promise<firebase.auth.ConfirmationResult>;
  loginWithMFA: (phoneNumber: string) => Promise<string>;
  enrollMFA: (verificationId: string, code: string) => Promise<boolean>;
  verifyUserMFA: (
    error: MultiFactorError,
    selectedIndex: number,
  ) => Promise<
    false | { verificationId: string; resolver: MultiFactorResolver }
  >;
  verifyUserEnrolled: (
    verificationMFA: { verificationId: string; resolver: MultiFactorResolver },
    verificationCode: string,
  ) => Promise<boolean>;
};

const FirebaseContext = React.createContext<FirebaseContext>({
  initialized: false,
  user: null,
  isLoading: true,
  getToken: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
  login: () => Promise.resolve(null),
  loginWithPhoneNumber: () => Promise.resolve(null),
  loginWithMFA: () => Promise.resolve(null),
  enrollMFA: () => Promise.resolve(null),
  signInWithToken: () => Promise.resolve(null),
  verifyUserMFA: () => Promise.resolve(null),
  verifyUserEnrolled: () => Promise.resolve(null),
});

if (config.firebaseConfig && !firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
}

export const parseFirebaseError = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/user-not-found':
      return 'There is no user record corresponding to this identifier. The user may have been deleted.';
    case 'auth/invalid-phone-number':
      return 'Invalid phone number format. Please make sure that the correct number has been entered.';
    case 'auth/unsupported-first-factor':
      return 'MFA is not supported for the given sign in method. If you signed in with the phone number, please sign out and sign in with email/password.';
    default:
      return 'Unknown error ocurred.';
  }
};

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useRef<firebase.User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  const getToken = useCallback(async (forceRefresh?: boolean) => {
    if (user.current) {
      return user.current.getIdToken(forceRefresh);
    }
    return null;
  }, []);

  const logout = useCallback(async () => firebase.auth().signOut(), []);

  const login = useCallback(
    async (email: string, password: string) =>
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(error => {
          console.log('error', error);
          throw error;
        }),
    [],
  );

  const loginWithPhoneNumber = useCallback(async (phoneNumber: string) => {
    try {
      const recaptchaRef = document.getElementById('recaptcha-container');
      const appVerifier = new firebase.auth.RecaptchaVerifier(recaptchaRef);
      const signInResults = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(confirmationResult => {
          return confirmationResult;
        })
        .catch(error => {
          throw new Error(parseFirebaseError(error.code));
        })
        .finally(() => {
          appVerifier.clear();
        });
      return signInResults;
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const loginWithMFA = useCallback(async (phoneNumber: string) => {
    try {
      const auth = getAuth();

      const recaptchaRef = document.getElementById('recaptcha-container');
      const appVerifier = new firebase.auth.RecaptchaVerifier(recaptchaRef);

      return multiFactor(auth.currentUser)
        .getSession()
        .then(async res2 => {
          const phoneInfoOptions = {
            phoneNumber,
            session: res2,
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          return phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            appVerifier,
          );
        })
        .finally(() => appVerifier.clear());
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const enrollMFA = useCallback(
    async (verificationId: string, code: string) => {
      try {
        const auth = getAuth();
        const cred = PhoneAuthProvider.credential(verificationId, code);

        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        await multiFactor(auth.currentUser).enroll(
          multiFactorAssertion,
          'My personal phone number',
        );

        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    [],
  );

  const verifyUserMFA = async (
    error: MultiFactorError,
    selectedIndex: number,
  ): Promise<
    false | { verificationId: string; resolver: MultiFactorResolver }
  > => {
    const auth = getAuth();
    const recaptchaRef = document.getElementById('recaptcha-container');
    const appVerifier = new firebase.auth.RecaptchaVerifier(recaptchaRef);
    const resolver = getMultiFactorResolver(auth, error);

    if (
      resolver.hints[selectedIndex].factorId ===
      PhoneMultiFactorGenerator.FACTOR_ID
    ) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[selectedIndex],
        session: resolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      try {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          appVerifier,
        );
        appVerifier.clear();
        return { verificationId, resolver };
      } catch (e) {
        return false;
      }
    }
  };

  const verifyUserEnrolled = async (
    verificationMFA: { verificationId: string; resolver: MultiFactorResolver },
    verificationCode: string,
  ) => {
    const { verificationId, resolver } = verificationMFA;
    const credentials = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credentials);

    try {
      await resolver.resolveSignIn(multiFactorAssertion);
      return true;
    } catch (e) {
      return false;
    }
  };

  const signInWithToken = useCallback(
    async (token: string) => firebase.auth().signInWithCustomToken(token),
    [],
  );

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async authInfo => {
      user.current = authInfo;

      if (authInfo) {
        const token = await authInfo?.getIdTokenResult();
        setFirebaseUser({
          info: authInfo,
          roles: token.claims?.roles,
          rid: token.claims?.rid,
          permissions: token.claims?.permissions,
        });
      } else {
        setFirebaseUser(null);
      }
      setIsLoading(false);
      return () => unsubscribe();
    });
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        initialized: true,
        user: firebaseUser,
        getToken,
        logout,
        login,
        loginWithPhoneNumber,
        loginWithMFA,
        enrollMFA,
        signInWithToken,
        isLoading,
        verifyUserMFA,
        verifyUserEnrolled,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

function useFirebaseContext() {
  return React.useContext(FirebaseContext);
}

export { FirebaseProvider, useFirebaseContext };
