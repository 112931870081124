import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  InputGroup,
  InputRightElement,
  FormLabel,
  FormControl,
  FormErrorMessage,
  BoxProps,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

import Input from '../inputs/Input';

interface IPasswordField
  extends BoxProps,
    Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  placeholder: string;
  name: string;
  label: string;
  colorScheme: 'green' | 'yellow';
  showPasswordRequirements?: boolean;
}

const PasswordField = ({
  label,
  placeholder,
  color,
  id,
  register,
  name,
  errors,
  showPasswordRequirements = false,
  ...rest
}: IPasswordField) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormControl isInvalid={Boolean(errors[name])} mt="0" p={0}>
      <FormLabel color={color} fontSize="sm" htmlFor={id} mb="0">
        {label}
      </FormLabel>

      <InputGroup
        borderBottomColor="yellow.400"
        mb="sm"
        size="md"
        variant="flushed"
      >
        <Input
          id={id}
          {...rest}
          color={color}
          name={name}
          placeholder={placeholder}
          pr="4.5rem"
          register={register}
          type={showPassword ? 'text' : 'password'}
        />
        <InputRightElement width="4.5rem">
          {showPassword ? (
            <ViewOffIcon
              color="white"
              onClick={toggleShowPassword}
              height={4}
            />
          ) : (
            <ViewIcon color="white" onClick={toggleShowPassword} height={4} />
          )}
        </InputRightElement>
      </InputGroup>
      {showPasswordRequirements && (
        <FormLabel color={color} fontSize="sm" htmlFor={id} mb="0">
          Password must include:
          <UnorderedList>
            <ListItem ml={2}>at least 8 characters</ListItem>
            <ListItem ml={2}>at least 1 uppercase character</ListItem>
            <ListItem ml={2}>at least 1 lowercase character</ListItem>
            <ListItem ml={2}>a number or a special character</ListItem>
          </UnorderedList>
        </FormLabel>
      )}
      <FormErrorMessage minH="1.5rem">{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default PasswordField;
