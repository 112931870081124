import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import {
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineUnderline,
  AiOutlineStrikethrough,
  AiOutlineUnorderedList,
  AiOutlineOrderedList,
} from 'react-icons/ai';
import { MdFormatQuote } from 'react-icons/md';
import { createEditor, Descendant, BaseEditor, Editor } from 'slate';
import {
  Slate,
  Editable,
  withReact,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react';

import RichTextToolbar from './RichTextToolbar';
import MarkButton, { FormatOptions, ToolbarButtonType } from './ToolbarButton';
import {
  Element,
  Leaf,
  serializeRichTextToHTML,
  getInitialRichTextValue,
} from './utils';

type CustomText = {
  text: string;
  bold?: boolean;
  strikethrough?: boolean;
  italic?: boolean;
  underline?: boolean;
};

type CustomElement = {
  type: FormatOptions;
  children: CustomText[];
};

interface CustomTypes {
  Editor: BaseEditor & ReactEditor;
  Element: CustomElement;
  Text: CustomText;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const iconSize = 20;

const toolbarButtons: ToolbarButtonType[] = [
  { format: FormatOptions.BOLD, icon: <AiOutlineBold size={iconSize} /> },
  { format: FormatOptions.ITALIC, icon: <AiOutlineItalic size={iconSize} /> },
  {
    format: FormatOptions.UNDERLINE,
    icon: <AiOutlineUnderline size={iconSize} />,
  },
  {
    format: FormatOptions.STRIKETHROUGH,
    icon: <AiOutlineStrikethrough size={iconSize} />,
  },
  {
    format: FormatOptions.UNORDERED_LIST,
    icon: <AiOutlineUnorderedList size={iconSize} />,
  },
  {
    format: FormatOptions.ORDERED_LIST,
    icon: <AiOutlineOrderedList size={iconSize} />,
  },
  { format: FormatOptions.QUOTE, icon: <MdFormatQuote size={iconSize} /> },
];

const withTextLimit =
  ({ limit }: { limit?: number }) =>
  (editor: CustomTypes['Editor']) => {
    const { insertText } = editor;
    editor.insertText = text => {
      if (Editor.string(editor, []).length < limit || limit === undefined) {
        insertText(text);
      }
    };
    return editor;
  };

interface RichTextType {
  defaultValue: string;
  placeholder: string;
  iconSize?: number;
  color?: string;
  onBlur?: (value: Descendant[]) => void;
  allowFormatting?: boolean;
  setTypedCharactersCount?: (value: number) => void;
  characterLimit?: number;
}

const RichText: React.FC<RichTextType> = ({
  defaultValue,
  placeholder,
  color,
  onBlur,
  allowFormatting = true,
  setTypedCharactersCount,
  characterLimit,
}) => {
  const editor = useMemo(
    () => withTextLimit({ limit: characterLimit })(withReact(createEditor())),
    [characterLimit],
  );
  const [richText, setRichText] = useState<Descendant[] | undefined>(undefined);

  const isFirstRender = useRef(true);

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    [],
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} color={color} />,
    [color],
  );

  useEffect(() => {
    if (isFirstRender.current && defaultValue && characterLimit) {
      onBlur(getInitialRichTextValue(defaultValue, characterLimit));
      isFirstRender.current = false;
    }
  }, [characterLimit, defaultValue, onBlur]);

  useEffect(() => {
    if (defaultValue) {
      setRichText(getInitialRichTextValue(defaultValue, characterLimit));
    }
  }, [defaultValue, characterLimit]);

  useEffect(() => {
    if (!richText) {
      return;
    }

    const serializedRichText = serializeRichTextToHTML(
      richText,
      !allowFormatting,
    );
    setTypedCharactersCount(serializedRichText.length);
  }, [allowFormatting, richText, setTypedCharactersCount]);

  return richText !== undefined ? (
    <Slate editor={editor} onChange={setRichText} value={richText}>
      {allowFormatting && (
        <RichTextToolbar color={color}>
          {toolbarButtons.map(button => (
            <MarkButton
              key={button.format}
              format={button.format}
              icon={button.icon}
            />
          ))}
        </RichTextToolbar>
      )}
      <Editable
        onBlur={() => onBlur(richText)}
        placeholder={placeholder}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
      />
    </Slate>
  ) : (
    <></>
  );
};

export default RichText;
