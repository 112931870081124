import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type MobileProps = {
  children: ReactNode;
};

type MobileContextData = {
  isMobileActive: boolean;
  handleToggle(state: boolean): void;
};

const MobileContext = createContext<MobileContextData>({} as MobileContextData);

export const MobileProvider = ({ children }: MobileProps) => {
  const router = useRouter();
  const [isMobileActive, setIsMobileActive] = useState(false);

  const handleToggle = (state: boolean) => {
    setIsMobileActive(state);
  };

  useEffect(() => {
    setIsMobileActive(false);
  }, [router.asPath]);

  return (
    <MobileContext.Provider value={{ isMobileActive, handleToggle }}>
      {children}
    </MobileContext.Provider>
  );
};

export function useMobileMenu() {
  return useContext(MobileContext);
}
