import React from 'react';

import { useAuth } from './AuthContext';

import { Self } from '@/generated/graphql';

const UserContext = React.createContext<{
  isLoading: boolean;
  user: Self;
} | null>(null);

const UserProvider = (props: any) => {
  const { isLoading, self } = useAuth();
  return <UserContext.Provider value={{ isLoading, user: self }} {...props} />;
};
const useUser = () => React.useContext(UserContext);

export { UserProvider, useUser };
