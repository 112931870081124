import { captureException, captureMessage, Severity } from '@sentry/react';

import config from '@/config';

export const info = message => {
  captureMessage(message, { level: Severity.Info });

  if (!config.disableConsoleLogging) {
    console.info(message);
  }
};
export const warn = message => {
  captureMessage(message, { level: Severity.Error });

  if (!config.disableConsoleLogging) {
    console.warn(message);
  }
};
export const error = (message: string | Error) => {
  if (message instanceof Error) {
    captureException(message);
  } else {
    captureMessage(message, { level: Severity.Error });
  }

  if (!config.disableConsoleLogging) {
    console.error(message);
  }
};

const log = {
  info,
  warn,
  error,
};
export default log;
