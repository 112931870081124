import { MultiFactorError, MultiFactorResolver, User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import {
  useFirebaseContext,
  parseFirebaseError,
} from '@/context/FirebaseContext';
import {
  Permissions,
  RegisterInput,
  useLoginTester,
  useRegisterUser,
  UserModel,
  UserRole,
  useSelfLazyQuery,
} from '@/generated/graphql';

type AuthContext = {
  self?: UserModel | undefined;
  roles?: UserRole[] | undefined;
  permissions?: Permissions;
  rid?: string;
  login: (email: string, password: string) => Promise<true | MultiFactorError>;
  loginWithPhoneNumber: (
    phoneNumber: string,
  ) => Promise<firebase.auth.ConfirmationResult>;
  loginTester: (rid: string) => void;
  isLoading: boolean;
  loginError?: string;
  logout: () => void;
  signInWithToken: (token: string) => void;
  register: (registerInput: RegisterInput) => void;
  refresh: () => void;
  registerError?: string;
  selfError?: string;
  loginTesterError?: string;
  loginWithMFA: () => Promise<string>;
  enrollMFA: (verificationId: string, code: string) => Promise<boolean>;
  verifyUserMFA: (
    error: MultiFactorError,
    selectedIndex: number,
  ) => Promise<
    false | { verificationId: string; resolver: MultiFactorResolver }
  >;
  verifyUserEnrolled: (
    verificationMFA: { verificationId: string; resolver: MultiFactorResolver },
    verificationCode: string,
  ) => Promise<boolean>;
  firebaseUser?: User;
};

export const AuthContext = React.createContext<AuthContext>({
  login: () => null,
  loginWithPhoneNumber: () => null,
  logout: () => null,
  isLoading: true,
  register: () => null,
  loginTester: () => null,
  signInWithToken: () => null,
  refresh: () => null,
  loginWithMFA: () => null,
  enrollMFA: () => null,
  verifyUserMFA: () => null,
  verifyUserEnrolled: () => null,
});

const AuthProvider = ({
  children,
  clearCache,
}: {
  clearCache: () => void;
  children: React.ReactNode;
}) => {
  const {
    user,
    logout: firebaseLogout,
    login: firebaseLogin,
    loginWithPhoneNumber: firebaseLoginWithPhoneNumber,
    isLoading: isLoadingFirebase,
    signInWithToken,
    loginWithMFA: firebaseLoginWithMFA,
    enrollMFA: firebaseEnrollMFA,
    verifyUserMFA,
    verifyUserEnrolled,
  } = useFirebaseContext();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState<string>(undefined);
  const [loginTesterError, setLoginTesterError] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [getSelf, { data, loading: loadingUser, error: selfError, refetch }] =
    useSelfLazyQuery();
  const [isLoadingSuport, setIsLoadingSuport] = useState(true);
  const [registerUserMutation, { loading: isRegistering }] = useRegisterUser();
  const [loginTesterMutation, { loading: isLoggingInTester }] =
    useLoginTester();

  const logout = useCallback(async () => {
    try {
      await firebaseLogout();
      clearCache();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, []);

  const login = useCallback(async (email: string, password: string) => {
    setLoginError(undefined);
    try {
      await firebaseLogin(email, password);
      return true;
    } catch (error) {
      return error as MultiFactorError;
    }
  }, []);

  const loginWithPhoneNumber = useCallback(async (phoneNumber: string) => {
    setLoginError(undefined);
    setIsLoggingIn(true);
    try {
      const result = await firebaseLoginWithPhoneNumber(phoneNumber);
      setIsLoggingIn(false);
      return result;
    } catch (error) {
      setLoginError(error.message);
    }
  }, []);

  const loginWithMFA = useCallback(async () => {
    if (!data?.self?.organisation?.hasMFA) {
      throw new Error("User's organisation does not have MFA feature enabled.");
    }
    setLoginError(undefined);
    try {
      const result = await firebaseLoginWithMFA(data?.self?.contactNumber);
      return result;
    } catch (error) {
      setLoginError(parseFirebaseError(error.code as string));
    }
  }, [data?.self?.organisation?.hasMFA]);

  const enrollMFA = useCallback(
    async (verificationId: string, code: string) => {
      if (!data?.self?.organisation?.hasMFA) {
        throw new Error(
          "User's organisation does not have MFA feature enabled.",
        );
      }
      setLoginError(undefined);
      try {
        const result = await firebaseEnrollMFA(verificationId, code);
        return result;
      } catch (error) {
        setLoginError(error.message);
      }
    },
    [data?.self?.organisation?.hasMFA],
  );

  const loginTester = useCallback(async (rid: string) => {
    setLoginTesterError(undefined);
    try {
      const { data: res } = await loginTesterMutation({
        variables: {
          rid,
        },
      });

      if (res?.loginTester?.error) {
        setLoginTesterError(res.loginTester.error);
      } else {
        const responseToken = res?.loginTester?.customToken;
        await signInWithToken(responseToken);
      }
    } catch (error) {
      setLoginTesterError(error.message);
    }
  }, []);

  const register = useCallback(async (registerInput: RegisterInput) => {
    const {
      surname,
      email,
      firstName,
      password,
      organisationId,
      title,
      contactNumber,
    } = registerInput;
    setRegisterError('');
    try {
      const res = await registerUserMutation({
        variables: {
          registerInput: {
            surname,
            email,
            firstName,
            password,
            organisationId,
            title,
            contactNumber,
          },
        },
      });
      if (res?.data?.register?.error) {
        setRegisterError(res?.data?.register?.error);
      }
      if (res?.data?.register?.user?.firebaseId) {
        await login(email, password);
      }
    } catch (err) {
      setRegisterError(err.message);
    }
  }, []);

  const hasGqlStarted = React.useRef(false);
  useEffect(() => {
    if (user && !isLoadingFirebase) {
      getSelf();
      hasGqlStarted.current = true;
    }
    if (!user && !isLoadingFirebase) {
      localStorage.clear();
      clearCache();
      setIsLoadingSuport(false);
    }
  }, [user, isLoadingFirebase]);

  useEffect(() => {
    if (hasGqlStarted.current === false) {
      return;
    }
    setIsLoadingSuport(loadingUser);
  }, [loadingUser]);

  return (
    <AuthContext.Provider
      value={{
        firebaseUser: user?.info,
        self: data?.self as UserModel,
        roles: user?.roles,
        permissions: user?.permissions,
        rid: user?.rid,
        login,
        loginWithPhoneNumber,
        loginWithMFA,
        enrollMFA,
        verifyUserMFA,
        verifyUserEnrolled,
        loginTester,
        logout,
        loginError,
        loginTesterError,
        selfError: selfError?.message,
        register,
        registerError,
        isLoading:
          isLoadingFirebase ||
          isLoggingIn ||
          isRegistering ||
          isLoadingSuport ||
          isLoggingInTester ||
          loadingUser,
        signInWithToken,
        refresh: refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
export { AuthProvider, useAuth };
