import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';

export const UL = styled.ul`
  list-style: none;
  & > li {
    display: flex;
  }
`;

interface IOLProps {
  color: string;
}

export const OL = styled.ol<IOLProps>`
  list-style: de;
  .bullet {
    display: none;
  }
  & > li:before {
    display: inline-block;
    content: counter(li);
    color: ${({ color }) => color && color};
    width: 1em;
    flex-shrink: 0;
  }
`;

const ListItem = styled.li`
  flex-direction: row;
  position: relative;
  counter-increment: li;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    margin: 0px;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

const Bullet = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  top: 8px;
  position: relative;
  margin-right: 10px;
`;

export const LI: FC<{ variant?: 'yellow' | 'green'; children: ReactNode }> = ({
  children,
  variant = 'yellow',
}) => {
  const color = variant === 'green' ? 'green.500' : 'yellow.400';
  return (
    <ListItem>
      <Box>
        <Bullet backgroundColor={color} className="bullet" variant={variant} />
      </Box>
      <Box>{children}</Box>
    </ListItem>
  );
};
