import { from, ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';
import { useMemo } from 'react';

import log from '@/services/log';

let apolloClient: ApolloClient<any>;

type GetToken = () => Promise<string | null>;
type Logout = () => Promise<void>;

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach(key => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    if (options.onAbortPossible) {
      options.onAbortPossible(() => {
        xhr.abort();
      });
    }

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.getContext().fetchOptions?.useUpload) {
    const omitTypename = (key, value) =>
      key === '__typename' ? undefined : value;

    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
  return forward(operation).map(data => data);
});

const inMemoryCacheOptions = {
  typePolicies: { Answer: { keyFields: ['questionId'] } },
};

function createApolloClient(getToken?: GetToken, logout?: Logout) {
  const authLink = setContext(async (_, { headers }) => {
    if (!getToken) {
      return;
    }
    const token = await getToken();
    return {
      headers: {
        ...headers,
        Authorization: token || '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const isUnauthenticted =
      false &&
      graphQLErrors?.find(x => x.extensions?.code === 'UNAUTHENTICATED');

    if (
      isUnauthenticted ||
      (networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 401)
    ) {
      if (logout) {
        logout();
      }
      return;
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(async ({ message }) => {
        log.error(`[GraphQL error]: Message: ${message}`);
        Sentry.addBreadcrumb({
          message: `[GraphQL error]: Message: ${message}`,
        });
      });
    }
    if (networkError) {
      Sentry.addBreadcrumb({ message: `[Network error]: ${networkError}` });
    }
  });
  const API_URL = process.env.NEXT_PUBLIC_CORE_API;
  const uploadLink = createUploadLink({
    uri: API_URL,
    fetch: customFetch as any,
  });

  const links = from([authLink, errorLink, cleanTypeName, uploadLink]);

  return new ApolloClient({
    link: links,
    cache: new InMemoryCache(inMemoryCacheOptions),
  });
}

export function initializeApollo(
  initialState = {},
  getToken?: GetToken,
  logout?: Logout,
) {
  const ac = apolloClient ?? createApolloClient(getToken, logout);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = ac.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    ac.cache.restore({ ...existingCache, ...initialState });
  }
  if (!apolloClient) {
    apolloClient = ac;
  }
  return ac;
}
export function useApollo(
  initialState = {},
  getToken: GetToken,
  logout: Logout,
) {
  const store = useMemo(
    () => initializeApollo(initialState, getToken, logout),
    [initialState],
  );
  return store;
}
