import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Button as ChakraButton,
  ButtonProps,
  LinkProps,
} from '@chakra-ui/react';
import { FC } from 'react';

const Button: FC<{ htmlFor?: string } & ButtonProps & LinkProps> = ({
  variant,
  type,
  children,
  colorScheme = 'yellow',
  leftIcon,
  ...rest
}) => {
  const variantStyle = variant === 'ghost' ? { color: 'black' } : null;
  return (
    <ChakraButton
      borderRadius="1.25rem"
      color="black"
      colorScheme={colorScheme}
      rightIcon={type === 'submit' && <ChevronRightIcon />}
      leftIcon={leftIcon}
      type={type}
      variant={variant}
      {...variantStyle}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
