import { AnimatePresence } from 'framer-motion';
import { createContext, useContext, useCallback } from 'react';

import { useRouterState } from '@/lib/utils/useRouterState';

const context = createContext<{
  openModal: (modalName: string) => void;
  closeModal: () => void;
  activeModal: null | string;
  isOpen: boolean;
}>({
  openModal: undefined,
  closeModal: undefined,
  activeModal: null,
  isOpen: false,
});

export const Provider = ({ modalParam = 'm', modals, children }) => {
  const [activeModal, setModal] = useRouterState(modalParam);
  const isOpen = !!activeModal;
  const openModal = setModal;
  const closeModal = useCallback(() => {
    setModal();
  }, []);

  return (
    <context.Provider value={{ openModal, closeModal, activeModal, isOpen }}>
      {children}
      <AnimatePresence mode="wait">{modals}</AnimatePresence>
    </context.Provider>
  );
};

export default function useModals() {
  const modalsContext = useContext(context);
  if (!modalsContext) {
    throw new Error('Cannot use Modals context outside of Modals provider');
  }
  return modalsContext;
}
