import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React, { FC } from 'react';

const CircularIconButton: FC<{ isLoading } & IconButtonProps> = ({
  isLoading,
  ...props
}) => (
  <IconButton
    alignItems="center"
    border="2px solid"
    borderColor="green.500"
    borderRadius="50%"
    cursor="pointer"
    height="24px"
    isDisabled={isLoading}
    justifyContent="center"
    minW="initial"
    width="24px"
    {...props}
  />
);

export default CircularIconButton;
