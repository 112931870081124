import * as Sentry from '@sentry/react';

const configure = (dsn: string, env: string) => {
  Sentry.init({
    attachStacktrace: true,
    dsn,
    environment: env,
    tracesSampleRate: 1.0,
    autoSessionTracking: false,

    integrations: [],
  });
};

export default configure;
