import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { Body } from '../text';

export type Props = CheckboxProps & {
  isChecked?: boolean;
  variant?: 'yellow' | 'green';
  name?: string;
  onChange: (value: boolean) => void;
};

const Checkbox = React.forwardRef(
  (
    { children, isChecked, variant = 'yellow', onChange, ...rest }: Props,
    forwardedRef,
  ) => {
    const [value, setValue] = useState(isChecked);

    const handleChange = () => {
      setValue(v => !v);
      if (onChange) {
        onChange(!value);
      }
    };

    useEffect(() => {
      setValue(isChecked);
    }, [isChecked]);

    return (
      <ChakraCheckbox
        colorScheme={variant}
        innerRef={forwardedRef}
        isChecked={value}
        onChange={handleChange}
        size="lg"
        {...rest}
      >
        <Body fontSize="1rem" mb={0}>
          {children}
        </Body>
      </ChakraCheckbox>
    );
  },
);

export default Checkbox;
