const typography = {
  fontWeights: {
    light: 300,
    normal: 500,
    medium: 600,
    bold: 700,
  },

  fonts: {
    body: 'Open Sans, sans-serif;',
    text: 'Open Sans',
    heading: 'inherit',
  },

  fontSizes: {
    sm: '12.8px',
    base: '14px',
    md: '14px',
    lg: '20px',
    xl: '25px',
    xxl: '32px',
    xxxl: '40px',
  },
};

export type Typography = typeof typography;

export default typography;
