import {
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputProps,
} from '@chakra-ui/react';

interface INumberInput extends NumberInputProps {
  fieldPadding?: string;
}

const NumberInput: React.FC<INumberInput> = ({
  fieldPadding = '32px',
  ...props
}) => (
  <ChakraNumberInput
    borderBottomColor="yellow.400"
    {...props}
    marginBottom="0"
    size="md"
    variant="flushed"
  >
    <NumberInputField paddingX={fieldPadding} />
  </ChakraNumberInput>
);

export default NumberInput;
