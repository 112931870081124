import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

interface ITextArea
  extends TextareaProps,
    Partial<Pick<UseFormMethods, 'errors' | 'register'>> {
  name: string;
  color: string;
  placeholder: string;
  id: string;
}

const TextArea = ({
  name,
  placeholder,
  color,
  id,
  errors,
  size = 'md',
  colorScheme = 'yellow',
  defaultValue,
  register,
  ...props
}: ITextArea) => {
  const borderBottom = colorScheme === 'green' ? 'green.500' : 'yellow.400';
  const [inputValue, setInputValue] = useState<TextareaProps['defaultValue']>();

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  return (
    <FormControl isInvalid={Boolean(errors[name])} mb="3" width="full">
      <FormLabel color={color} fontSize="sm" htmlFor={id} mb="0">
        {placeholder}
      </FormLabel>

      <Textarea
        borderBottomColor={borderBottom}
        color={color}
        id={id}
        marginBottom="0"
        name={name}
        onChange={value => setInputValue(value.currentTarget.value)}
        placeholder={placeholder}
        size={size}
        variant="flushed"
        value={inputValue}
        ref={register}
        {...props}
      />

      <FormErrorMessage minH="1.5rem">{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default TextArea;
