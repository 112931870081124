import { Flex, Box } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { ReactNode } from 'react';

interface RichTextToolbarType {
  children: ReactNode[];
  color?: string;
}

const RichTextToolbar: React.FC<RichTextToolbarType> = ({
  children,
  color,
}) => {
  const theme = useTheme();
  return (
    <Box
      border="1px solid"
      borderColor={theme.colors.gray[200]}
      borderRadius="4px"
      my={2}
    >
      <Flex
        alignItems="center"
        direction="row"
        justify="flex-start"
        wrap="wrap"
      >
        {children.map((child, index) => (
          <Box key={index} color={color} m={1}>
            <Flex alignItems="center">{child}</Flex>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default RichTextToolbar;
