import { ApolloProvider } from '@apollo/client';
import * as React from 'react';

import { AuthProvider } from './AuthContext';
import { MobileProvider } from './MobileMenuContext';
import { UserProvider } from './UserContext';

import LoadingOverlay from '@/components/LoadingOverlay';
import { useFirebaseContext } from '@/context/FirebaseContext';
import { useApollo } from '@/graphql/apollo';

const AppProviders = ({ children }: { children: React.ReactNode }) => {
  const { initialized, logout, getToken } = useFirebaseContext();
  const client = useApollo({}, getToken, logout);

  if (!initialized) {
    return <LoadingOverlay message="Loading..." variant="dark" />;
  }

  return (
    <ApolloProvider client={client}>
      <AuthProvider clearCache={() => client.clearStore()}>
        <MobileProvider>
          <UserProvider>{children}</UserProvider>
        </MobileProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};
export default AppProviders;
