import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
} from '@chakra-ui/react';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';

import Input from '../inputs/Input';

interface ITextField
  extends InputProps,
    Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  name: string;
  color: string;
  placeholder: string;
  id: string;
}

const TextField = ({
  name,
  placeholder,
  color,
  id,
  register,
  errors,
  ...props
}: ITextField) => (
  <FormControl isInvalid={Boolean(errors[name])} mb="3" width="full">
    <FormLabel color={color} fontSize="sm" htmlFor={id} mb="0">
      {placeholder}
    </FormLabel>

    <Input
      {...props}
      color={color}
      id={id}
      marginBottom="0"
      name={name}
      placeholder={placeholder}
      register={register}
      size="md"
      variant="flushed"
    />
    <FormErrorMessage minH="1.5rem">{errors[name]?.message}</FormErrorMessage>
  </FormControl>
);

export default TextField;
