import { useRouter } from 'next/router';
import { useCallback } from 'react';

const useRouterState = (
  paramName: string,
  {
    defaultValue = undefined,
    isBlank = newValue => !newValue,
    shallow = true,
  } = {},
) => {
  const { query, push } = useRouter();
  const removeValue = useCallback(() => {
    const path = new URL(window.location.href);
    path.searchParams.delete(paramName);
    const url = path.pathname + path.search;
    push(url, url, { shallow });
  }, [push]);
  const setValue = useCallback(
    newValue => {
      if (isBlank(newValue)) {
        removeValue();
      } else {
        const path = new URL(window.location.href);
        path.searchParams.set(paramName, newValue);
        const url = path.pathname + path.search;
        push(url, url, { shallow });
      }
    },
    [push],
  );
  return [query[paramName] || defaultValue, setValue];
};

export { useRouterState };
