import {
  FormControl,
  FormErrorMessage,
  TextareaProps,
  Box,
} from '@chakra-ui/react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Descendant } from 'slate';

import RichText from '@/components/RichText';

interface EditableTextAreaType
  extends Omit<TextareaProps, 'onBlur'>,
    Partial<Pick<UseFormMethods, 'control' | 'errors'>> {
  defaultValue: string;
  name: string;
  color: string;
  placeholder: string;
  id: string;
  onBlur: (event: Descendant[]) => void;
  allowFormatting?: boolean;
  setTypedCharactersCount?: (value: number) => void;
  characterLimit?: number;
}

const EditableTextArea = ({
  defaultValue,
  name,
  placeholder,
  color,
  id,
  control,
  errors,
  minHeight,
  onBlur,
  allowFormatting = true,
  setTypedCharactersCount,
  characterLimit,
}: EditableTextAreaType) => (
  <FormControl isInvalid={Boolean(errors[name])} mb="3" width="full">
    <Box id={id} minHeight={minHeight}>
      <Controller
        control={control}
        name={name}
        render={() => (
          <RichText
            color={color}
            defaultValue={defaultValue}
            onBlur={onBlur}
            placeholder={placeholder}
            allowFormatting={allowFormatting}
            setTypedCharactersCount={setTypedCharactersCount}
            characterLimit={characterLimit}
          />
        )}
      />
    </Box>
    <FormErrorMessage minH="1.5rem">{errors[name]?.message}</FormErrorMessage>
  </FormControl>
);

export default EditableTextArea;
