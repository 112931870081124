import { Button, ButtonProps, Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IoChatbubbleOutline } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';

const colors = {
  green: 'employee.green',
  yellow: 'employee.yellow',
};

interface IOutlineButtonProps extends ButtonProps {
  Icon?: IconType | 'none';
  children?: ReactNode;
  variant: 'green' | 'yellow' | 'white';
  textVariant?: 'light' | 'dark';
}

export default function OutlineButton({
  Icon = IoChatbubbleOutline,
  children,
  variant = 'yellow',
  textVariant = 'dark',
  ...rest
}: IOutlineButtonProps) {
  return (
    <>
      <Button
        backgroundColor="transparent"
        border="2px solid"
        borderColor={colors[variant]}
        borderRadius="25px"
        color={textVariant === 'dark' ? 'gray.900' : 'gray.50'}
        height="initial"
        p="3px 12px"
        variant="unstyled"
        {...rest}
      >
        <Flex alignItems="center" justifyContent="center">
          {Icon !== 'none' && <Icon size={14} />}
          {children && (
            <Text
              as="span"
              color={textVariant === 'dark' ? 'gray.900' : 'white'}
              fontSize="12px"
              letterSpacing="-0.27px"
              marginInlineStart="5px"
              isTruncated
            >
              {children}
            </Text>
          )}
        </Flex>
      </Button>
    </>
  );
}
