import {
  Link as ChakraLink,
  LinkProps,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { PropsWithChildren } from 'react';

const getVariant = value => {
  switch (value) {
    case 'primary':
      return 'yellow.400';
    case 'secondary':
      return '#fff';
    case 'green':
      return 'green.500';
    default:
      return '#ffca27';
  }
};

interface ILink extends LinkProps {
  variant?: ChakraLinkProps['variant'];
  children: React.ReactNode;
}

export const Link = ({ variant, children, ...props }: ILink) => (
  <ChakraLink color={getVariant(variant)} size="sm" {...props}>
    {children}
  </ChakraLink>
);

export type NextChakraLinkProps = PropsWithChildren<
  NextLinkProps & Omit<ChakraLinkProps, 'as'>
>;

//  Has to be a new component because both chakra and next share the `as` keyword
export const NextChakraLink = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  children,
  ...chakraProps
}: NextChakraLinkProps) => (
  <NextLink
    as={as}
    href={href}
    passHref
    prefetch={prefetch}
    replace={replace}
    scroll={scroll}
    shallow={shallow}
  >
    <Link {...chakraProps}>{children}</Link>
  </NextLink>
);
