import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import Head from 'next/head';
import React from 'react';

import config from '@/config';
import AppProvider from '@/context/AppProvider';
import { FirebaseProvider } from '@/context/FirebaseContext';
import configure from '@/lib/configure/sentry';
import { Provider as ModalProvider } from '@/lib/providers/modalProvider';
import theme from '@/theme';

if (config?.sentry?.dsn) {
  configure(config.sentry.dsn, config.configEnv);
}

export default function App({ Component, pageProps }): JSX.Element {
  return (
    <Sentry.ErrorBoundary>
      <FirebaseProvider>
        <AppProvider>
          <ChakraProvider resetCSS theme={theme}>
            <ModalProvider modals={<>{/* Put global modals here */}</>}>
              <Head>
                <title>{config.title}</title>
                <meta
                  content="width=device-width, initial-scale=1"
                  name="viewport"
                />
                <meta content="ie=edge" httpEquiv="x-ua-compatible" />
              </Head>
              <AnimatePresence mode="wait">
                <Component {...pageProps} />
              </AnimatePresence>
            </ModalProvider>
          </ChakraProvider>
        </AppProvider>
      </FirebaseProvider>
    </Sentry.ErrorBoundary>
  );
}
