import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { Theme } from '@emotion/react';

import components from './components';
import foundations from './foundations';
import styles from './styles';

/**
 * Color mode config
 */
const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const theme = extendTheme({
  ...foundations,
  components,
  styles,
  config,
});

export default theme;
